import { AoilHazardDataType, HazardSolutionTableItemType } from "./aoiHazardType";
import { HazardCodeEnum, MetadataType, SectorTypeEnum } from "./commonTypes";

export class RegionalHazardType {
  public userInput: UserInputType = new UserInputType();
  public regionalHazardData: RegionalHazardDataType = new RegionalHazardDataType();
  public aoiHazardData: AoilHazardDataType = new AoilHazardDataType();
  public metadata: MetadataType = new MetadataType();
  public selectedPlans: any;

  constructor(input?: any, _metadata?: any) {
    if (input) {
      this.userInput = new UserInputType(input.userInput);
      this.regionalHazardData = new RegionalHazardDataType(input.regionalHazardData);
      this.aoiHazardData = new AoilHazardDataType(input.aoiHazardData, input.aoiHazardData && input.aoiHazardData.metadata);
      this.selectedPlans = input.selectedPlans
    }
    if (_metadata) {
      this.metadata = new MetadataType(_metadata);
    }
  }
}

export class RegionalHazardDataType {
  public phenomena: RegionalHazardItemType[] = [];
  constructor(input?: any) {
    if (input) {
      this.phenomena = ((input && input.phenomena) || []).map((obj: any) => new RegionalHazardItemType(obj));
    }
  }
}
export class RegionalHazardItemType {
  public code: HazardCodeEnum | null = null;
  public mainInfo: string = "";
  public subInfo: string = "";
  public additionalInfo: string = "";
  constructor(input?: any) {
    if (input) {
      this.code = input && input.code;
      this.mainInfo = (input && input.mainInfo) || "";
      this.subInfo = (input && input.subInfo) || "";
      this.additionalInfo = (input && input.additionalInfo) || "";
    }
  }
}

export class AreaItemType {
  public id: string = '';
  public name: string = '';
  public searchedLocation: string = '';
  public cap: string = '';
  public sectors: SectorTypeEnum[] = [];
  public privacyChecked: boolean = false;
  public drawArea?: DrawAreaType | null;
  public aoiHazardData: AoilHazardDataType = new AoilHazardDataType();

  constructor(input?: any) {
    if (input) {
      this.id = input.id;
      this.name = input.name;
      this.searchedLocation = input.searchedLocation;
      this.cap = input.cap;
      this.sectors = input.sectors || [];
      this.privacyChecked = !!input.privacyChecked;
      this.drawArea = input.drawArea ? new DrawAreaType(input.drawArea) : null;
      this.aoiHazardData = new AoilHazardDataType(input.aoiHazardData, input.aoiHazardData && input.aoiHazardData.metadata);
    }
  }
}
export class UserInputType extends AreaItemType {
  public currentStep: UserInputSteps = UserInputSteps.SECTORS_STEP;
  public currentSolution: HazardSolutionTableItemType = new HazardSolutionTableItemType();
  public viewport: ViewPortType = new ViewPortType();
  public partialNvertex: number = 0;

  constructor(input?: any) {
    super(input)
    if (input) {
      this.currentStep = input.currentStep || UserInputSteps.SECTORS_STEP;
      this.viewport = new ViewPortType(input.viewport);
      this.partialNvertex = input.partialNvertex || 0;
    }
  }
}

export const enum UserInputSteps {
  SECTORS_STEP = 0,
  LOCATION_STEP,
  DRAW_AREA_STEP,
}

export interface ILocation {
  location: string
  cap?: string
}

export class ViewPortType {
  public width: string = "100%";
  public height: string = "fit";
  public zoom: number = 4.5;
  public latitude: number = 40;
  public longitude: number = 12.5;

  constructor(input?: any) {
    if (input) {
      this.width = (input && input.width) || "100%";
      this.height = (input && input.height) || "fit";
      this.zoom = (input && input.zoom) || 4.5;
      this.latitude = (input && input.latitude) || 40;
      this.longitude = (input && input.longitude) || 12.5;
    }
  }
}

export class DrawAreaType {
  public id: string = "";
  public type: string = "";
  public properties: any = {};
  public geometry?: GeometryType;

  constructor(input?: any) {
    if (input) {
      this.id = input && input.id;
      this.type = input && input.type;
      this.properties = input && input.properties;
      this.geometry = input && input.geometry && new GeometryType(input.geometry);
    }
  }
}

export class GeometryType {
  public coordinates: any = [];
  public type: string = "";

  constructor(input?: any) {
    if (input) {
      this.coordinates = input && input.coordinates;
      this.type = input && input.type;
    }
  }
}

