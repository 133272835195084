import { ReactChild } from "react";
import ReactDOM from "react-dom";

import closeIcon from "../../assets/images/close.png";
import "./ModalSlide.scss";

type ModalSlideProps = {
  title?: string;
  children?: ReactChild;
  type?: "right" | "left";
  modalClass?: string;
  closeModal?: () => void;
  confirmModal?: any;
  confirmModalDisabled?: boolean;
  confirmModalText?: string;
  cancelModal?: any;
  cancelModalText?: string;
  isLoader?: boolean;
  confirmButtonDisabled?: boolean;
  shouldBeStandalone?: boolean;
};

const ModalSlide = (props: ModalSlideProps) => {
  const { confirmModalText, confirmModalDisabled, shouldBeStandalone } = props;
  const closeModalHandler = () => {
    props.closeModal && props.closeModal();
  };
  const confirmModalHandler = () => {
    closeModalHandler();
    props.confirmModal && props.confirmModal();
  };

  const cancelModalHandler = () => {
    closeModalHandler();
    props.cancelModal && props.cancelModal();
  };

  const type = props.type ? ` ${props.type}` : "";
  const modalClass = props.modalClass ? ` ${props.modalClass}` : "";
  const loaderStyle =
    props && props.isLoader ? "modal-content loader" : "modal-content";

  const modal = (
    <>
      <div className="modal-backdrop" onClick={closeModalHandler}></div>
      <div className={`modal-container${type}${modalClass}`}>
        <div className={loaderStyle}>
          {props && !props.isLoader && (
            <div className="modal-header">
              <h3 className="modal-title">{props.title}</h3>
              <button
                className="ada-btn transparent-btn closeButton"
                onClick={closeModalHandler}
              >
                <img
                  className="closeIcon"
                  src={closeIcon}
                  alt="Chiudi modale"
                />
              </button>
            </div>
          )}
          <div className="modal-body">{props.children}</div>
          {props.confirmModal && props.cancelModal && (
            <div className="modal-footer">
              <div className="buttons-container">
                <button
                  className="ada-btn white-btn"
                  onClick={cancelModalHandler}
                >
                  {props.cancelModalText ? props.cancelModalText : "Annulla"}
                </button>
                <button
                  className="ada-btn"
                  onClick={confirmModalHandler}
                  disabled={!!props.confirmButtonDisabled}
                >
                  {props.confirmModalText ? props.confirmModalText : "Conferma"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const portalDiv = document.getElementById("modal-slide")!;

  if (shouldBeStandalone) return modal;

  return <>{ReactDOM.createPortal(modal, portalDiv)}</>;
};

export default ModalSlide;
