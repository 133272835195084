import { faArrowDown, faArrowUp, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LevelEnum } from '../../types/commonTypes'
import './DangerInfo.scss'

interface DangerInfoProps {
    level: LevelEnum
}

interface InfoProps {
    label: string,
    className: string,
    icon: any
}

const DangerInfo = ({ level }: DangerInfoProps) => {
    const getInfo = (): InfoProps => {
        switch (level) {
            case LevelEnum.LOW:
                return { label: "Bassa pericolosità", className: "low", icon: faArrowDown }

            case LevelEnum.MEDIUM:
                return { label: "Media pericolosità", className: "medium", icon: faCircle }

            case LevelEnum.HIGH:
                return { label: "Alta pericolosità", className: "high", icon: faArrowUp }

            default:
                return { label: "", className: "", icon: "" };
        }
    }

    return (
        <div className={`danger-info danger-info--${getInfo().className}`}>
            <FontAwesomeIcon icon={getInfo().icon} /><p className='danger-info__text'>{getInfo().label}</p>
        </div>
    )
}

export default DangerInfo