import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";
import React, { useState } from "react";

interface PasswordInputProps {
    className?: string,
    name?: string
}

const InputPassword = ({ className, name }: PasswordInputProps) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="password-input-container">
      <Field
        className={`password-input-container__input ${className}`}
        name={name}
        type={passwordShown ? "text" : "password"}
      />
      <FontAwesomeIcon
        className="password-input-container__icon"
        onClick={togglePassword}
        icon={passwordShown ? faEyeSlash : faEye}
      />
    </div>
  );
};

export default InputPassword;
