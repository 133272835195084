import { toast } from 'react-toastify';

const RETRY_SENTENCE = 'Si prega di riprovare e contattare l\'assistenza se il problema persiste'
const GENERIC_ERROR = `Sì è presentato un errore nell\'operazione corrente. ${RETRY_SENTENCE}`

const errorMessages: any = {
    EMAIL_ALREADY_REGISTERED: 'L\'email inserita risulta già esistente',
    EMAIL_FORMAT_INVALID: 'Formato dell\'email non valido',
    ACTIVATION_URL_INVALID: 'Il formato del link di attivazione non valido',
    ACTCODE_EXPIRED: 'Il link è scaduto. Richiedi il reinvio della mail di attivazione',
    CREDENTIALS_INVALID: 'Credenziali invalide, attivazione non riuscita',
    RETRIVE_CODE_EXPIRED: 'Codice scaduto, richiedi un nuovo codice',
    NOT_AUTHORIZED: 'La tua email o la tua password sono errati.'
}

export const getErrorMessage = (code?: string) => {
    const retError: any = code && errorMessages[code]
    return retError || GENERIC_ERROR;
}

export const toastDefaultProperties = {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 8500
}

