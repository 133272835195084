import { action } from 'typesafe-actions'
import { PersonalAreaAction } from '../../types/actions/personal-area-action';

export const loadAreasAct = (params?: any) => action(PersonalAreaAction.LOAD_AREAS, params)
export const loadAreasSuccessAct = (resp: any[]) => action(PersonalAreaAction.LOAD_AREAS_SUCCESS, resp)
export const loadAreasErrorAct = (err: string) => action(PersonalAreaAction.LOAD_AREAS_ERROR, err)

export const saveAreaAct = (params?: any) => action(PersonalAreaAction.SAVE_AREA, params)
export const saveAreaSuccessAct = () => action(PersonalAreaAction.SAVE_AREA_SUCCESS)
export const saveAreaErrorAct = (err: string) => action(PersonalAreaAction.SAVE_AREA_ERROR, err)

export const deleteAreaAct = (id?: any) => action(PersonalAreaAction.DELETE_AREA, id)
export const deleteAreaSuccessAct = () => action(PersonalAreaAction.DELETE_AREA_SUCCESS)
export const deleteAreaErrorAct = (err: string) => action(PersonalAreaAction.DELETE_AREA_ERROR, err)

export const loadPlanActionsAct = (params?: any) => action(PersonalAreaAction.LOAD_PLAN_ACTIONS, params)
export const loadPlanActionsSuccessAct = (resp: any[]) => action(PersonalAreaAction.LOAD_PLAN_ACTIONS_SUCCESS, resp)
export const loadPlanActionsErrorAct = (err: string) => action(PersonalAreaAction.LOAD_PLAN_ACTIONS_ERROR, err)

export const addDeleteActionAct = (params?: any) => action(PersonalAreaAction.ADD_DELETE_ACTION, params)
export const addDeleteActionSuccessAct = () => action(PersonalAreaAction.ADD_DELETE_ACTION_SUCCESS)
export const addDeleteActionErrorAct = (err: string) => action(PersonalAreaAction.ADD_DELETE_ACTION_ERROR, err)

export const loadRegionalClimateAct = (params?: any) => action(PersonalAreaAction.LOAD_REGIONAL_CLIMATE, params)
export const loadRegionalClimateSuccessAct = (resp: any[]) => action(PersonalAreaAction.LOAD_REGIONAL_CLIMATE_SUCCESS, resp)
export const loadRegionalClimateErrorAct = (err: string) => action(PersonalAreaAction.LOAD_REGIONAL_CLIMATE_ERROR, err)

export const loadRisksAnalysisAct = (params?: any) => action(PersonalAreaAction.LOAD_RISKS_ANALYSIS, params)
export const loadRisksAnalysisSuccessAct = (resp: any[]) => action(PersonalAreaAction.LOAD_RISKS_ANALYSIS_SUCCESS, resp)
export const loadRisksAnalysisErrorAct = (err: string) => action(PersonalAreaAction.LOAD_RISKS_ANALYSIS_ERROR, err)

export const changePasswordAct = (params: any) => action(PersonalAreaAction.CHANGE_PASSWORD, params)
export const changePasswordSuccessAct= () => action(PersonalAreaAction.CHANGE_PASSWORD_SUCCESS)
export const changePasswordErrorAct= () => action(PersonalAreaAction.CHANGE_PASSWORD_ERROR)

export const modifyOrganizationsAct = (params: any) => action(PersonalAreaAction.MODIFY_ORGANIZATIONS, params)
export const modifyOrganizationsSuccessAct= () => action(PersonalAreaAction.MODIFY_ORGANIZATIONS_SUCCESS)
export const modifyOrganizationsErrorAct= () => action(PersonalAreaAction.MODIFY_ORGANIZATIONS_ERROR)