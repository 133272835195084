import { DrawAreaType } from "../types/regionalHazardType";

export const getMapCoordinates = (drawArea?: DrawAreaType | null): Array<any> => {
  if (!drawArea || !drawArea.geometry || !drawArea.geometry || !drawArea.geometry.coordinates || !drawArea.geometry.coordinates.length) {
    return [];
  }

  const coords = drawArea.geometry.coordinates[0] || [];

  return coords;
};

export const getMapCoordVertex = (drawArea?: DrawAreaType | null) => {
  const coords = getMapCoordinates(drawArea);

  if (!coords || !coords.length) {
    return;
  }

  const coordArr = coords[0];
  return {
    longitude: coordArr[0],
    latitude: coordArr[1],
  };
};

const ispoligonDrawn = (coords: Array<any>) => {
  if (coords && coords.length > 2) {
    const firstPoint = coords[0];
    const lastPoint = coords[coords.length - 1];
    return firstPoint[0] === lastPoint[0] && firstPoint[1] === lastPoint[1];
  }
  return false;
};

export const isAreaDrawn = (drawArea?: DrawAreaType | null) => {
  const coords = getMapCoordinates(drawArea);
  return !!ispoligonDrawn(coords);
};

export const getDrawAreaLabel = (drawArea?: DrawAreaType | null, partialNvertex?: number) => {
  let retLabel = "Fai click sulla mappa.";

  const _isAreaDrawn = isAreaDrawn(drawArea);
  if (_isAreaDrawn) {
    retLabel = "Hai selezionato la tua zona, prosegui per visualizzare i rischi della tua zona o elimina la zona selezionata.";
  } else if (partialNvertex && partialNvertex > 2) {
    retLabel = "Clicca sul primo punto per chiudere l’area.";
  } else if (partialNvertex && partialNvertex > 0) {
    retLabel = "Disegna altri punti.";
  }

  return retLabel;
};

export const isMapReadyForDrawing = () => {
  return window.adaMapObj && window.adaMapObj._loaded && window.adaMapDrawObj;
};

export const drawExistingArea = (area?: DrawAreaType | null) => {
  if (!area) {
    return;
  }
  window.adaMapDrawObj.deleteAll();
  window.adaMapDrawObj.add(area);
  window.adaMapDrawObj.changeMode("simple_select");
};

export const addAreaIfPresent = (area?: DrawAreaType | null) => {
  if (isMapReadyForDrawing() && !!area) {
    drawExistingArea(area);
  }
};

export const deleteMapDrawnArea = () => {
  if (isMapReadyForDrawing()) {
    window.adaMapDrawObj.deleteAll();
    window.adaMapDrawObj.changeMode("draw_polygon");
  }
};

export const getCapFromSuggestion = (context?: any[]) => {
  let postCodeObj;
  if (context?.length) {
    postCodeObj = context.find((c: any) => c.id.indexOf('postcode') >= 0)
  }
  return postCodeObj?.text
}
