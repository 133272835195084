import { Formik, Field, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import { selectAuth } from "../store";
import { loginAct } from "../store/auth/actions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Loader from "../UI/Loader/Loader";
import { toastDefaultProperties, getErrorMessage } from "../utils/errors.utils";
import { toast } from "react-toastify";
import queryString from "query-string";
import "./Auth.scss";
import RetrivePswdModal from "../components/Pswd/RetrivePswdModal";
import InputPassword from "../components/InputPassword/InputPassword";
import { UserTypeEnum } from "../types/commonTypes";

const LoginPage = () => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const { loginData } = authState;
  const [retrivePswdModalOpened, setRetrivePswdModalOpened] = useState(false);
  const [userType, setUserType] = useState<UserTypeEnum>(UserTypeEnum.PRODUCER)

  useEffect(() => {
    // read query string, if code present, call activate api, else show error
    const search = queryString.parse(window.location.search);
    const userType = search && search["userType"];
    if (userType === UserTypeEnum.ORGANIZATION) {
      setUserType(UserTypeEnum.ORGANIZATION)
    }
  }, [])


  const clickBackHandler = () => {
    history.goBack();
  };

  const goToRegistrationPage = () => {
    history.push(`/registrati`);
  };

  const goToOrgRegistrationPage = () => {
    history.push(`/registrati-org`);
  };

  useEffect(() => {
    if (loginData?.metadata.error) {
      toast.error(
        getErrorMessage(loginData?.metadata.errorCode),
        toastDefaultProperties
      );
    }
  }, [loginData?.metadata.error]);

  const toggleRetrivePswdModalOpened = (opened: boolean) => {
    setRetrivePswdModalOpened(opened);
  };

  return (
    <>
      {loginData?.metadata.isLoading && <Loader isGlobal />}
      {retrivePswdModalOpened && (
        <RetrivePswdModal
          toggleRetrivePswdModalOpened={toggleRetrivePswdModalOpened}
          userType={userType}
        />
      )}
      <div className="page-cmp login-page">
        <WhiteHeader goBackCb={clickBackHandler} title={"Accedi"} />

        <Formik
          initialValues={{
            email: loginData?.email,
            pswd: loginData?.pswd,
          }}
          validationSchema={Yup.object({
            pswd: Yup.string().matches(/^\S+$/, 'Gli spazi bianchi non sono ammessi.').required("Campo obbligatorio"),
            email: Yup.string()
              .email("Indirizzo email non valido")
              .required("Campo obbligatorio"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const newValues = {
              ...values,
              userType
            };
            dispatch(loginAct({ loginData: newValues, history }));
            setSubmitting(false);
          }}
        >
          <Form>
            <div className="input-wrapper padding-y-medium">
              <label htmlFor="email">Email</label>
              <Field className="text-input" name="email" type="email" />
              <ErrorMessage name="email" />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="pswd">Password</label>
              <InputPassword name="pswd" className="text-input" />
              <ErrorMessage name="pswd" />
            </div>
            <button className="ada-btn w-100" type="submit">
              {userType === UserTypeEnum.ORGANIZATION ? 'Accedi come organizzazione' : 'Accedi'}
            </button>
          </Form>
        </Formik>

        <div className="forgot-pswd-container padding-y-medium">
          <p>
            {userType !== UserTypeEnum.ORGANIZATION ? "Hai dimenticato la password?" : "Non ricordi le tue credenziali di organizzazione?"}
            <a
              href="javascript:void(0)"
              onClick={() => toggleRetrivePswdModalOpened(true)}
            >
              &nbsp;Recupera password
            </a>
          </p>
        </div>

        {userType !== UserTypeEnum.ORGANIZATION ? <>
          <div className="no-account-container padding-y-xlarge">
            <div className="no-account-container__left-border"></div>
            <p className="no-account-container__label">Non hai un accout?</p>
            <div className="no-account-container__right-border"></div>
          </div>

          <button
            className="ada-btn transparent-btn border-green w-100"
            onClick={goToRegistrationPage}
          >
            Registrati
          </button>
        </> : <>
          <div className="no-account-container padding-y-xlarge">
            <div className="no-account-container__left-border"></div>
            <p className="no-account-container__label">Vuoi registrare la tua organizzazione?</p>
            <div className="no-account-container__right-border"></div>
          </div>

          <button
            className="ada-btn transparent-btn border-green w-100"
            onClick={goToOrgRegistrationPage}
          >
            Compila form di richiesta
          </button>
        </>}

      </div>
    </>
  );
};

export default LoginPage;
