import { selectRegionalHazard } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import './UserInputWizardCmp.scss'
import { UserInputSteps } from "../../types/regionalHazardType";
import LocationStep from "../Locations/LocationStep";
import SectorsStep from "../Sectors/SectorsStep";
import { isCurrentStep } from "../../utils/utils";
import DrawAreaStep from "../DrawArea/DrawAreaStep";

const UserInputWizardCmp = () => {
    const hazardState = useAppSelector(selectRegionalHazard);
    const { userInput } = hazardState;

    const dispatch = useAppDispatch();

    const isSectorsStep = isCurrentStep(userInput.currentStep, UserInputSteps.SECTORS_STEP);
    const isLocationStep = isCurrentStep(userInput.currentStep, UserInputSteps.LOCATION_STEP);
    const isDrawAreaStep = isCurrentStep(userInput.currentStep, UserInputSteps.DRAW_AREA_STEP);

    return (
        <>
            <div className="fake-element"></div>
            <div className="ada-wizard-container">
                <div className="top-border-shadowed"></div>

                {isSectorsStep && <SectorsStep />}
                {isLocationStep && <LocationStep />}
                {isDrawAreaStep && <DrawAreaStep />}

            </div>
        </>
    )
}

export default UserInputWizardCmp;
