import { HazardSolutionTableItemType, InfoTableItemType, ReferencedProjectItemType } from "../../types/aoiHazardType";
import InfoTableIcon from "./InfoTableIcon";
import refProjectIcon from "./../../assets/images/ref_projects_icon.png";
import "./SolutionDetailCmp.scss";
import WhiteHeader from "../WhiteHeader/WhiteHeader";
import { SolutionTableEnum } from "../../types/commonTypes";
import { API_ENDPOINT, getValidTokenPresent } from "../../utils/api";
import axios from "axios";
import queryString from "query-string";
import { useState } from 'react'
import Loader from "../../UI/Loader/Loader";
import StatusCmp from "../Status/StatusCmp";

interface SolutionDetailCmpProps {
  solutionDetail?: HazardSolutionTableItemType | null;
  clickBackHandler: any;
  currentStatus: string
  setCurrentStatus: Function;
  hideStatusSelector?: boolean
  actionExpireDate: Date | string
  setActionExpireDate: Function
}

const SolutionDetailCmp = (props: SolutionDetailCmpProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const statusList = ["AlreadyDone", "ToRenew", "ToSchedule"]
  const { solutionDetail, clickBackHandler, currentStatus, setCurrentStatus, hideStatusSelector,
    actionExpireDate, setActionExpireDate
   } = props;
  const infoTable = solutionDetail?.infoTable;
  const windowWidth = window.screen.width;
  const search = queryString.parse(window.location.search)
  const searchId = search.id;

  const id = searchId ? searchId : solutionDetail?._id;

  const urlOpenNewHandler = (url: string) => {
    window.open(url, "_blank");
  };

  const setStatus = (selectedStatus: string) => {
    setCurrentStatus(selectedStatus)
  }

  const downloadCostsBenefit = async (value: string) => {
    if (!value) return;

    const authInfoToken = getValidTokenPresent();

    try {
      setIsLoading(true)
      const response = (await axios.get(API_ENDPOINT + '/api/report-costi-benefici?azione_id=' + id, {
        headers: { Authorization: 'Bearer ' + authInfoToken }
      })).data
      setIsLoading(false)

      if (!response.opResult) return;

      return urlOpenNewHandler(response.data.url)
    } catch (error) {
      return
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      {solutionDetail && (
        <div className="solution-detail-cmp">
          <WhiteHeader goBackCb={clickBackHandler} title={solutionDetail.title} />

          <div className="solution-detail-body page-detail-body max-1480-container">
            {!hideStatusSelector && <StatusCmp
              currentSolution={solutionDetail}
              status={currentStatus}
              statusList={statusList}
              setStatus={setStatus}
              actionExpireDate={actionExpireDate}
              setActionExpireDate={setActionExpireDate}  />}
            <div className="solutions-info-table-container">
              {infoTable &&
                infoTable
                  .filter((it) => it.code !== "MITIGATION") // per ora nascondiamo mitigazione
                  .map((it: InfoTableItemType, i) => {
                    //calcolo quante colonne in base alla larghezza dello schermo
                    let n = windowWidth > 992 ? 3 : 2;
                    //divido il numero di colonne per il numero totale di elementi per calcolare l'eventuale resto
                    const overCell = infoTable.length % n;
                    let lastItemColSize = 1;
                    //se esiste un numero di elementi non divisibile per il numero di colonne e siamo all'ultimo elemento da renderizzare
                    if (overCell !== 0 && i + 1 === infoTable.length) {
                      //calcolo quanti spazi deve occupare l'ultimo elemento in caso di resto
                      const remaininCellSize = n - (overCell - 1);
                      lastItemColSize = remaininCellSize;
                    }
                    let specialBorderRight = (i + 1) % n === 0 ? "specialBorderRight" : "";
                    return (
                      <div className={`info-table-item ${it.code} ${specialBorderRight}`} key={it.code ? it.code + i : i} style={{ gridColumn: `span ${lastItemColSize}` }}>
                        <p className="label">{it.title}</p>
                        <InfoTableIcon it={it} />
                        {it.code !== SolutionTableEnum.COSTS_BENEFITS && <p className="value">{it.value}</p>}
                        {it.code === SolutionTableEnum.COSTS_BENEFITS && <p onClick={() => downloadCostsBenefit(it.value)} className="value value-costs-benefits">{it.value ? 'Scarica analisi' : 'NON DISPONIBILE'}</p>}
                      </div>
                    );
                  })}
            </div>

            {solutionDetail.referencedProjects &&
              solutionDetail.referencedProjects.map((rp: ReferencedProjectItemType) => (
                <div className="ref-projects-container" key={rp.url}>
                  <p className="ref-projects">Progetto di riferimento</p>
                  <img key={rp.url} alt={rp.label} src={refProjectIcon} onClick={() => urlOpenNewHandler(rp.url)} />
                </div>
              ))}
            <p className="title">Descrizione</p>
            <p className="description">{solutionDetail.description}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SolutionDetailCmp;
