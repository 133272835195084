import { HazardCodeEnum } from "../../types/commonTypes";
import CardGreenFooter from "../../UI/Cards/CardGreenFooter";
import { hazardIcon, hazardTranslator } from "../../utils/utils";
import infoCircle from "../../assets/images/info-circled.png";
import { RisksAnalysisStatsType } from "../../types/personalAreaType";
import RiskReportCard from "../../UI/Cards/RiskReportCard";
import { useMemo } from "react";

interface SpecificRisksCmpProps {
    risksAnalysisData: RisksAnalysisStatsType
    navTo: string;
    linkLabel?: string
}

const SpecificRisksCmp = (props: SpecificRisksCmpProps) => {
    const { risksAnalysisData, linkLabel } = props

    const hazardNoExtremeTNoRain = [
        { name: HazardCodeEnum.WIND, data: risksAnalysisData.wind },
        { name: HazardCodeEnum.HAIL, data: risksAnalysisData.hail },
        { name: HazardCodeEnum.FROST, data: risksAnalysisData.frost },
        { name: HazardCodeEnum.DROUGHT, data: risksAnalysisData.drought },
    ];
    const hazardNoExtremeTNoRainDataPresentGroup = hazardNoExtremeTNoRain.filter((el) => !!el.data);
    const risksLevels = useMemo(() => {
        return hazardNoExtremeTNoRainDataPresentGroup.map(el => ({
            name: el.name,
            data: el.data
        }))
    }, [risksAnalysisData.wind])

    return (
        <>
            <div className="dual-card-box">
                {
                    risksLevels.map(hazard => (
                        <CardGreenFooter
                            key={hazard.name}
                            navTo={""}
                            linkLabel={linkLabel}
                            header={{
                                title: hazardTranslator(hazard.name),
                                icon: { img: infoCircle, altText: "Icona informazioni" },
                            }}
                        >
                            <RiskReportCard
                                risksData={hazard.data}
                                additionalTextClass="font-medium"
                                icon={{ ...hazardIcon(hazard.name) }}
                                additionalDangerLabel={"produttori"}
                            />
                        </CardGreenFooter>
                    ))
                }
            </div>
        </>
    )
}

export default SpecificRisksCmp