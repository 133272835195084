import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import InputPassword from "../components/InputPassword/InputPassword";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import "./ChangePassword.scss";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { changePasswordAct } from "../store/personalArea/actions";
import { useAppSelector } from "../store/hooks";
import { selectAuth, selectPersonalArea } from "../store";
import { isOrganization } from "../utils/utils";
import { UserTypeEnum } from "../types/commonTypes";
import { getLocalStorageAccessToken } from "../utils/utils.store";
import Loader from "../UI/Loader/Loader";

const ChangePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authData = useAppSelector(selectAuth)
  const { userAuthData } = authData
  const isOrg = isOrganization(userAuthData)
  const accessToken = getLocalStorageAccessToken();
  const personalArea = useAppSelector(selectPersonalArea)
  const isLoading = personalArea?.changePasswordMetadata?.isLoading

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      {isLoading && <Loader isGlobal />}
      <div className="page-cmp white-page-layout">
        <WhiteHeader goBackCb={goBack} title="Cambio password" />
        <Formik
          initialValues={{
            previousPswd: "",
            newPswd: "",
            confirmNewPswd: "",
          }}
          validationSchema={Yup.object({
            previousPswd: Yup.string()
              .required("Campo obbligatorio"),
            newPswd: Yup.string()
              .required("Campo obbligatorio")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.,!@#\$%\^&\*])(?=.{8,})/,
                "Deve contenere almeno 8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale"
              )
              .min(8, "Deve contenere almeno 8 caratteri"),
            confirmNewPswd: Yup.string()
              .required("Campo obbligatorio")
              .oneOf([Yup.ref("newPswd"), null], "Le password non sono uguali"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(changePasswordAct({ data: { ...values, accessToken, userType: isOrg ? UserTypeEnum.ORGANIZATION : UserTypeEnum.PRODUCER }, history }))
            setSubmitting(false);
          }}
        >
          <Form>
            <div className="input-wrapper padding-y-medium">
              <label htmlFor="previousPswd">Inserisci password attuale</label>
              <InputPassword name="previousPswd" className="text-input-white" />
              <ErrorMessage name="previousPswd" />
            </div>
            <div className="input-wrapper padding-y-medium">
              <label htmlFor="newPswd">Inserisci nuova password </label>
              <InputPassword name="newPswd" className="text-input-white" />
              <ErrorMessage name="newPswd" />
            </div>
            <div className="input-wrapper padding-y-medium">
              <label htmlFor="confirmNewPswd">Conferma nuova password</label>
              <InputPassword name="confirmNewPswd" className="text-input-white" />
              <ErrorMessage name="confirmNewPswd" />
            </div>
            <p className="paragraph">
              Dopo aver modificato la password, effettua l'accesso con la nuova
              password per utilizzare Ada tool.
            </p>
            <button className="ada-btn w-100" type="submit">
              Conferma e cambia password
            </button>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default ChangePassword;
