import { useEffect, useRef } from "react";
import { selectRegionalHazard } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userInputMapSetViewportAct, userInputSetLocationAct } from "../../store/regionalHazardArea/actions";
import { ViewPortType } from "../../types/regionalHazardType";
import { getCapFromSuggestion } from "../../utils/map.utils";
import useGeocoderInput from "./UseGeocoderInput";

const GeocoderInput = () => {
  const hazardState = useAppSelector(selectRegionalHazard);
  const { userInput } = hazardState;
  const dispatch = useAppDispatch();
  const address = useGeocoderInput("");
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const curr_location = userInput.searchedLocation || "";
    address.setValue(curr_location);
  }, [userInput.searchedLocation]);

  const suggestionClickedHandler = (suggestion: any) => {
    address.setSuggestions([]);

    if (suggestion?.geometry?.coordinates && suggestion?.geometry?.coordinates.length) {
      const latitude = suggestion?.geometry?.coordinates[1];
      const longitude = suggestion?.geometry?.coordinates[0];
      const loc = { latitude, longitude, locationTxt: suggestion.place_name };
      const cap = getCapFromSuggestion(suggestion?.context)
      dispatch(userInputSetLocationAct({ location: loc.locationTxt, cap }));
      const viewport = new ViewPortType(loc);
      viewport.zoom = 16
      dispatch(userInputMapSetViewportAct(viewport));
    }
  };

  const clickOutsideHandler = (ev: any) => {
    if (autocompleteRef && autocompleteRef.current && !(autocompleteRef.current as any).contains(ev.target)) {
      address.setSuggestions([]);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", clickOutsideHandler);
    return () => {
      window.addEventListener("mousedown", clickOutsideHandler);
    };
  }, []);

  const inputChangeHandler = (e: any) => {
    address.onChange(e);
    dispatch(userInputSetLocationAct({ location: e.target.value }));
  };

  return (
    <div className="geocoder-wrapper">
      <input className="ada-input geocoder-input" value={address.value} onChange={inputChangeHandler} />
      {address.suggestions?.length > 0 && (
        <div className="suggestion-wrapper" ref={autocompleteRef}>
          {address.suggestions.map((suggestion: any, index: any) => {
            return (
              <p className="suggestion" key={index} onClick={() => suggestionClickedHandler(suggestion)}>
                {suggestion.place_name}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GeocoderInput;
