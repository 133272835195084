import { selectRegionalHazard } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userInputSetStepAct } from "../../store/regionalHazardArea/actions";
import { UserInputSteps } from "../../types/regionalHazardType";
import GeolocationBtn from "../Sectors/GeolocationBtn";
import GeocoderInput from "./GeocoderInput";
import "./LocationStep.scss";

const LocationStep = () => {
  const hazardState = useAppSelector(selectRegionalHazard);
  const { userInput } = hazardState;
  const dispatch = useAppDispatch();

  const goToDrawAreaHandler = () => {
    dispatch(userInputSetStepAct(UserInputSteps.DRAW_AREA_STEP));
  };
  const confermZoneBtnDisabled = !(userInput.searchedLocation);

  return (
    <>
      <div className="wizard-step location-step">
        <p className="main-msg">Ricerca la tua zona</p>
        <p className="sub-msg">Cerca la tua zona d’interesse o localizzati tramite gps selezionando l’icona.</p>

        <div className="search-area-container">
          <GeolocationBtn />
          <GeocoderInput />
        </div>
        <button className="ada-btn w-100 confirm-zone-btn" disabled={confermZoneBtnDisabled} onClick={goToDrawAreaHandler}>
          Conferma zona
        </button>
      </div>
    </>
  );
};

export default LocationStep;
