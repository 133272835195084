import { selectPersonalArea } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { deleteAreaAct } from "../../store/personalArea/actions";
import "./ZoneCard.scss";

interface DeleteZoneProps {
  id: string
  className?: string,
  setDeleteAreaVisible: Function
}
const DeleteZone = (props: DeleteZoneProps) => {
    const personalArea = useAppSelector(selectPersonalArea)
    const dispatch = useAppDispatch();
  const { id, className, setDeleteAreaVisible } = props

  const deleteIsLoading = personalArea?.deleteAreaMetadata?.isLoading

  return (
    <div className={`delete-zone-overlay ${className}`}>
      <button className="danger-btn" 
      onClick={() => dispatch(deleteAreaAct(id))}>Elimina</button>
      <button className="default-btn" 
      onClick={() => setDeleteAreaVisible(false)}>Annulla</button>
    </div>
  );
};

export default DeleteZone;
