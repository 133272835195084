import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import PersonalAreaHeader from "../components/PersonalAreaHeader/PersonalAreaHeader";
import Submenu from "../components/Submenu/Submenu";
import "./PersonalArea.scss";

import moment from 'moment'
import "moment/locale/it"
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { loadAreasAct, loadPlanActionsAct, loadRegionalClimateAct, loadRisksAnalysisAct } from "../store/personalArea/actions";
import Areas from "../components/Areas/Areas";
import { selectAuth, selectPersonalArea, selectRegionalHazard } from "../store";
import { HazardSolutionTableItemType } from "../types/aoiHazardType";
import Loader from "../UI/Loader/Loader";
import { isOrganization, statusName } from "../utils/utils";
import RegionalClimateSection from "../components/RegionalClimate/RegionalClimateSection";
import RisksAnalysisSection from "../components/RisksAnalysis/RisksAnalysisSection";
import { API_ENDPOINT, getValidTokenPresent } from "../utils/api";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import ExportExcel from "../components/ExportExcel/ExportExcel";
moment().locale("it")

const PersonalAreaPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();
  const dispatch = useAppDispatch();
  const personalArea = useAppSelector(selectPersonalArea)
  const authData = useAppSelector(selectAuth)

  const reagionalHazardState = useAppSelector(selectRegionalHazard);
  const reportIsLoading = reagionalHazardState?.metadata?.isLoading
  const { actions } = personalArea
  const { userAuthData } = authData
  const isOrg = isOrganization(userAuthData)

  useEffect(() => {
    localStorage.removeItem("registrationFormData")
  }, [])

  useEffect(() => {
    // Load bollettino info
    // Load areas list
    dispatch(loadAreasAct())
    // Load plan actions
    dispatch(loadPlanActionsAct())


    if (isOrg) {
      // Load regional climate
      dispatch(loadRegionalClimateAct())
      // Load risks analysis
      dispatch(loadRisksAnalysisAct())
    }
  }, [])

  const goToSettings = () => {
    history.push("/impostazioni");
  };

  const goToHome = () => {
    history.push("/");
  };

  const submenuItems = useMemo(() => {
    return actions.map((act: HazardSolutionTableItemType) => ({
      title: act.title,
      url: `/solution-details?code=${act.code}&id=${act._id}`,
      state: act.state
    }))
  }, [actions?.length])

  const submenuCategories: Record<string, Array<Object>> = useMemo(() => {
    return submenuItems.reduce((acc, el) => {
      acc[el.state || "AlreadyDone"].push(el)
      return acc;
    }, { AlreadyDone: [], ToRenew: [], ToSchedule: [] } as Record<string, Array<Object>>)
  }, [submenuItems])

  const currentDate = useMemo(() => {
    return moment().format('D MMMM YYYY')
  }, [])

  const downloadPdf = async () => {
    const authInfoToken = getValidTokenPresent();

    try {
      setIsLoading(true)
      const response = (await axios.get(API_ENDPOINT + '/api/report-protezione-civile', {
        headers: { Authorization: 'Bearer ' + authInfoToken }
      })).data
      setIsLoading(false)

      if (!response.opResult) return;

      return window.open(response.data.url, '_blank')
    } catch (error) {
      return
    }
  }

  return (
    <div className="page-cmp personal-area-page regional-report-page">
      {reportIsLoading && <Loader isGlobal />}
      {isLoading && <Loader isGlobal />}
      <PersonalAreaHeader settingsClick={goToSettings} goToHome={goToHome} />

      <h1 className="heading-1-2 personal-area__page-title color-dark">Ciao {userAuthData.companyName}</h1>
      <h2 className="heading-1 color-light padding-y-medium">
        Ecco il report delle tue zone d'interesse.
      </h2>
      <h3 className="heading-2 color-dark">BOLLETTINO PROTEZIONE CIVILE</h3>
      <p onClick={downloadPdf} className="personal-area__download-pdf">Scarica il bollettino <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></p>
      <h3 className="heading-2 color-dark padding-y-medium">Le tue zone</h3>
      <Areas />
      <h3 className="heading-2 color-dark padding-y-medium">
        Il tuo piano di adattamento
      </h3>
      <h3 className="heading-2 color-light">
        Consulta le azioni che hai aggiunto o aggiungi una nuova azione
      </h3>

      {
        Object.keys(submenuCategories).map((el: string) => {
          const categoryName = statusName[el];
          if (!submenuCategories[el].length) return;

          return (
            <div className="status" key={el}>
              <div className="status__header">
                <h3 className="status__title heading-2">{categoryName}</h3>
              </div>
              <div className="status__body">
                <Submenu items={(submenuCategories[el] as any)} />
              </div>
            </div>
          )
        })
      }

      {isOrg && <RegionalClimateSection />}

      {isOrg && <RisksAnalysisSection />}


      <div className="export-excel-container">
        <ExportExcel authData={userAuthData} actions={submenuItems} />
      </div>
    </div>
  );
};

export default PersonalAreaPage;
