import { useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { activateUserAct, activateUserErrorAct } from "../store/auth/actions";
import { selectAuth } from "../store";
import Loader from "../UI/Loader/Loader";
import { getErrorMessage } from "../utils/errors.utils";

const ActivateUserPage = () => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const { activateUserData } = authState;

  useEffect(() => {
    // read query string, if code present, call activate api, else show error
    const search = queryString.parse(window.location.search);
    const email = search && search["email"];
    const actCode = search && search["actCode"];

    if (!email || !actCode) {
      dispatch(activateUserErrorAct("ACTIVATION_URL_INVALID"));
    } else {
      const activationData = { email, actCode };
      dispatch(activateUserAct({ activationData, history }));
    }
  }, []);
  return (
    <>
      <div className="home-landing-page">
        {activateUserData?.metadata.isLoading && <Loader isGlobal />}
        {activateUserData?.metadata.error && (
          <p>{getErrorMessage(activateUserData?.metadata.errorCode)}</p>
        )}
      </div>
    </>
  );
};

export default ActivateUserPage;
