import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  API_ENDPOINT,
  getBackendData,
  getBackendErrorCode,
  hasBackendResponseErrors,
  sendPost,
} from "../../utils/api";
import { AxiosResponse } from "axios";
import {
  loadRegionalHazarErrorAct,
  loadRegionalHazarSuccessAct,
} from "./actions";
import { RegionalHazardAction } from "../../types/actions/regional-area-action";
import { RegionalHazardType } from "../../types/regionalHazardType";
import { getUserInputHazardBody } from "../../utils/utils.store";

export const getStateHazard = (state: any) => state.regionalHazard;

export function* LoadRegionalHazardCall(action: any) {
  try {
    let stateHazard: RegionalHazardType = yield select(getStateHazard);
    const { userInput } = stateHazard;
    const { history } =
      action.payload;

    let _body = getUserInputHazardBody(userInput);

    // let _body: any = action.payload;
    let requestURL = `${API_ENDPOINT}/api/regional-hazard`;
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res);
      yield put(loadRegionalHazarErrorAct(errCode));
    } else {
      const userInfoData = getBackendData(res);
      yield put(loadRegionalHazarSuccessAct(userInfoData));
      history.push(`/regional-data-page`);
    }
  } catch (err) {
    yield put(loadRegionalHazarErrorAct());
  }
}

export function* watchRegionalHazardCall() {
  yield takeEvery(
    [RegionalHazardAction.LOAD_REGIONAL_HAZARD],
    LoadRegionalHazardCall
  );
}

export function* regionalHazardSaga() {
  yield all([fork(watchRegionalHazardCall)]);
}

export default regionalHazardSaga;
