import { AoiHazardAction, RegionalHazardAction } from "../../types/actions/regional-area-action";
import { AoilHazardDataType } from "../../types/aoiHazardType";
import { DrawAreaType, RegionalHazardType, UserInputType } from "../../types/regionalHazardType";

export const initialState: RegionalHazardType = new RegionalHazardType();

const reducer = (state = initialState, action: any): RegionalHazardType => {
  let newState = new RegionalHazardType(state);
  let _regionalHazardData;
  let _aoiHazardData;
  let selectedPlans;

  switch (action.type) {
    case RegionalHazardAction.RESET_STATE:
      return newState;
    case RegionalHazardAction.LOAD_REGIONAL_HAZARD:
      newState = new RegionalHazardType(
        {
          ...state,
        },
        {
          isLoading: true,
          errorCode: null,
          error: false,
        }
      );
      return newState;
    case RegionalHazardAction.LOAD_REGIONAL_HAZARD_SUCCESS:
      _regionalHazardData = action.payload;
      newState = new RegionalHazardType(
        {
          ...state,
          regionalHazardData: _regionalHazardData,
        },
        {
          isLoading: false,
          errorCode: null,
          error: false,
        }
      );
      return newState;

    case RegionalHazardAction.LOAD_REGIONAL_HAZARD_ERROR:
      newState = new RegionalHazardType(
        {
          ...state,
        },
        {
          isLoading: false,
          errorCode: action.payload,
          error: true,
        }
      );
      return newState;

    case RegionalHazardAction.USER_INPUT_SET_STEP:
      newState.userInput.currentStep = action.payload;
      return newState;
    case RegionalHazardAction.SET_CURRENT_SOLUTION:
      newState.userInput.currentSolution = action.payload;
      return newState;

    case RegionalHazardAction.USER_INPUT_SET_SECTORS:
      newState.userInput.sectors = action.payload;
      return newState;

    case RegionalHazardAction.PRIVACY_CHECK_ON_OFF:
      newState.userInput.privacyChecked = !!action.payload;
      return newState;

    case RegionalHazardAction.USER_INPUT_SET_GEOLOCATION:
      newState.userInput.searchedLocation = action.payload?.location;
      newState.userInput.cap = action.payload?.cap;
      return newState;

    case RegionalHazardAction.USER_INPUT_MAP_SET_VIEWPORT:
      newState.userInput.viewport.latitude = action.payload.latitude;
      newState.userInput.viewport.longitude = action.payload.longitude;
      newState.userInput.viewport.zoom = Math.min(action.payload.zoom || newState.userInput.viewport.zoom, 16);
      return newState;

    case RegionalHazardAction.USER_INPUT_MAP_ZOOM_OUT:
      newState.userInput.viewport.zoom = newState.userInput.viewport.zoom - 1;
      return newState;

    case RegionalHazardAction.USER_INPUT_MAP_ZOOM_IN:
      let currentZoom = newState.userInput.viewport.zoom;
      newState.userInput.viewport.zoom = currentZoom >= 16 ? currentZoom : currentZoom + 1;
      return newState;

    case RegionalHazardAction.USER_INPUT_SET_DRWA_AREA:
      newState.userInput.drawArea = action.payload ? new DrawAreaType(action.payload) : null;
      return newState;

    case RegionalHazardAction.USER_INPUT_SET_DATA:
      newState.userInput = new UserInputType(action.payload);
      return newState;

    case RegionalHazardAction.USER_INPUT_PARTIAL_POLYGON_N_VERTEX:
      newState.userInput.partialNvertex = action.payload || 0;
      return newState;

    case RegionalHazardAction.USER_INPUT_CLEAR:
      newState.userInput = new UserInputType();
      return newState;

    case AoiHazardAction.LOAD_AOI_HAZARD:
      _aoiHazardData = new AoilHazardDataType();
      newState = new RegionalHazardType(
        {
          ...state,
          aoiHazardData: new AoilHazardDataType(_aoiHazardData, {
            isLoading: true,
            errorCode: null,
            error: false,
          }),
        },
        {
          isLoading: true,
          errorCode: null,
          error: false,
        }
      );
      return newState;
    case AoiHazardAction.LOAD_AOI_HAZARD_SUCCESS:
      _aoiHazardData = action.payload.aoiHazardData;
      selectedPlans = action.payload.selectedPlans;
      newState = new RegionalHazardType(
        {
          ...state,
          selectedPlans,
          aoiHazardData: new AoilHazardDataType(_aoiHazardData, {
            isLoading: false,
            errorCode: null,
            error: false,
          }),
        },
        {
          isLoading: false,
          errorCode: null,
          error: false,
        }
      );
      return newState;

    case AoiHazardAction.LOAD_AOI_HAZARD_ERROR:
      _aoiHazardData = new AoilHazardDataType();
      newState = new RegionalHazardType(
        {
          ...state,
          aoiHazardData: new AoilHazardDataType(_aoiHazardData, {
            isLoading: false,
            errorCode: action.payload,
            error: true,
          }),
        },
        {
          isLoading: false,
          errorCode: action.payload,
          error: true,
        }
      );
      return newState;

    default:
      return state;
  }
};

export { reducer as RegionalHazardReducer };
