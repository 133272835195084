import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';

import type { ControlPosition } from 'react-map-gl';

declare global {
  interface Window {
    adaMapDrawObj: any;
    adaMapObj: any;
  }
}

type ControlTypes =
  | 'point'
  | 'line_string'
  | 'polygon'
  | 'trash'
  | 'combine_features'
  | 'uncombine_features';

type DrawControlProps = {
  keybindings?: boolean;
  touchEnable?: boolean;
  boxSelect?: boolean;
  clickBuffer?: number;
  touchBuffer?: number;
  controls?: Partial<{ [name in ControlTypes]: boolean }>;
  displayControlsDefault?: boolean;
  styles?: any;
  modes?: any;
  defaultMode?: string;
  userProperties?: boolean;

  position?: ControlPosition;

  onCreate?: (evt: { features: object[] }) => void;
  onUpdate?: (evt: { features: object[]; action: string }) => void;
  onDelete?: (evt: { features: object[] }) => void;
  onRender?: (evt: { features: object[] }) => void;
};

export default function DrawControl(props: DrawControlProps) {
  useControl(
    ({ map }: any) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
      map.on('draw.delete', props.onDelete);
      map.on('draw.render', props.onRender);
      const _uc = new MapboxDraw(props);
      window.adaMapDrawObj = _uc;
      return _uc;
    },
    ({ map }: any) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      map.off('draw.delete', props.onDelete);
      map.off('draw.render', props.onRender);
    },
    {
      position: props.position
    }
  );

  return null;
}

DrawControl.defaultProps = {
  onCreate: () => { },
  onUpdate: () => { },
  onDelete: () => { },
  onRender: () => { }
};
