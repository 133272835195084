import { faCaretDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ModalSlide from "../../UI/ModalSlide/ModalSlide";
import StatusSelection from "../StatusSelection/StatusSelectionCmp";
import { isSolutionInPlan, statusName } from "../../utils/utils";
import { HazardSolutionTableItemType } from "../../types/aoiHazardType";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectPersonalArea } from "../../store";
import { addDeleteActionAct } from "../../store/personalArea/actions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

interface StatusCmpProps {
    statusList: string[]
    status: string
    setStatus: Function
    classList?: string
    currentSolution: HazardSolutionTableItemType
    actionExpireDate: Date | string
    setActionExpireDate: Function
}

const StatusCmp = (props: StatusCmpProps) => {
    const { statusList, status, setStatus, currentSolution, actionExpireDate, setActionExpireDate } = props

    const [currentStatus, setCurrentStatus] = useState(status);
    const [statusModalOpened, setStatusModalOpened] = useState(false);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const statusLabel = currentStatus
        ? statusName[status]
        : "Scegli uno stato";

    const personalArea = useAppSelector(selectPersonalArea);
    const isSolutionAlreadyInPlan = isSolutionInPlan(currentSolution, personalArea?.actions)

    const ToggleStatusModal = (e: any, value: boolean) => {
        e && e.preventDefault();
        setStatusModalOpened(value);
    };

    const AddRemoveSolutionMsg = ({ closeToast, toastProps, solTitle, msg, goToPersonalAreaHandler }: any) => (
        <div className="toast-container">
            <p className="toast-msg"><span className="title-msg">{solTitle}</span>&nbsp;{msg}</p>
            <p className="link-msg" onClick={goToPersonalAreaHandler}>Vai all'area personale</p>
            <button className="close-toast-btn" onClick={closeToast}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
    )

    const handleConfirmSelectors = () => {
        if (isSolutionAlreadyInPlan) {
            dispatch(addDeleteActionAct({ currentSolution, isRemove: false, solutionId: currentSolution._id, state: currentStatus, expiredDate: actionExpireDate }))
            const goToPersonalAreaHandler = () => {
                history.push(`/personal-area`);
            }
            // show notification
            const toastMessage = `è stato ${isSolutionAlreadyInPlan ? "rimosso dal" : "aggiunto al"} tuo piano`
            toast.success(<AddRemoveSolutionMsg
                solTitle={currentSolution.title}
                msg={toastMessage}
                goToPersonalAreaHandler={goToPersonalAreaHandler}
            />, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                hideProgressBar: true,
                className: 'ada-toast success-toast'
            })
        }
        setStatus(currentStatus)
        setStatusModalOpened(false);
    };

    const cancelConfirmSelectors = () => {
        setStatusModalOpened(false);
    };

    const updateStatusHandler = (selectedStatus: any) => {
        setCurrentStatus(selectedStatus)
    }

    const statusModal = (
        <ModalSlide
            title="Seleziona lo stato"
            closeModal={() => ToggleStatusModal(null, false)}
            confirmModal={handleConfirmSelectors}
            cancelModal={cancelConfirmSelectors}
            modalClass="modal-organisations-selection"
        >
            <StatusSelection
                status={currentStatus}
                statusList={statusList}
                updateStatus={updateStatusHandler}
                expireDate={actionExpireDate}
                setExpireDate={setActionExpireDate} />
        </ModalSlide>
    );

    return (
        <>
            <button
                type="button"
                className={`ada-input domain-input btn-select with-icon`}
                onClick={(e) => ToggleStatusModal(e, true)}
            >
                {statusLabel}
                <FontAwesomeIcon icon={faCaretDown} />
            </button>
            {statusModalOpened && statusModal}
        </>
    );
};

export default StatusCmp;
