import { NavLink } from "react-router-dom";
import greaterThan from "../../assets/images/greaterThan.png";
import "./Cards.scss";

type MiniNavCardProps = {
    title:string,
    linkTo:string
}

const MiniNavCard = (props: MiniNavCardProps) => {
  return (
    <NavLink to={props.linkTo} className="mini-nav-card">
      <p>{props.title}</p>
      <img src={greaterThan} alt="Vai alla sezione" />
    </NavLink>
  );
};

export default MiniNavCard;
