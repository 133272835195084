import CardGreenFooter, { CardGreenFooterProps } from "../../UI/Cards/CardGreenFooter";
import celsius from "../../assets/images/celsius.png";
import arrowsUpDown from "../../assets/images/arrows-up-down.png";
import { ExtremeTemperatureType } from "../../types/aoiHazardType";
import { infoText } from "../../utils/utils";
import { stagioneDiRiferimentoBlock } from "../../utils/utils-cmp";
import { useState } from "react";
import "../../pages/RegionalReportPage.scss";

interface ExtremeTCardProps extends CardGreenFooterProps {
  extremeT: ExtremeTemperatureType | null
}

const ExtremeTCard = (props: ExtremeTCardProps) => {
  const { extremeT, navTo, additionalClass, header: propsHeader, linkLabel } = props
  const [extremeTempSeason, setExtremeTempSeason] = useState("SUMMER");
  const chosenSeasonExtremeT = extremeT?.info.find((season) => extremeTempSeason === season.season);
  const [extremeTempViewCelsius, setExtremeTempViewCelsius] = useState(true);

  const extremeTempSeasonHandler = (e: any) => {
    setExtremeTempSeason(e?.target?.value);
  };

  const toggleExtremeTempHandler = () => {
    setExtremeTempViewCelsius((prev) => !prev);
  };

  return (
    <>
      {extremeT && (
        <CardGreenFooter
          navTo={navTo}
          additionalClass={additionalClass}
          linkLabel={linkLabel}
          header={{
            title: propsHeader?.title || "",
            navToHaeder: propsHeader?.navToHaeder || "",
            icon: propsHeader?.icon,
          }}
        >
          <p className="info-text">{infoText}</p>
          <div className="double-column">
            {stagioneDiRiferimentoBlock(extremeTempSeasonHandler, 1)}
            <div>
              <h4>Vista</h4>
              <div className="double-option-container">
                <button className={"ada-btn celsius circle-double-option " + extremeTempViewCelsius} onClick={toggleExtremeTempHandler}>
                  <img src={celsius} alt="Celsius icon" />
                </button>
                <button className={"ada-btn variation circle-double-option " + !extremeTempViewCelsius} onClick={toggleExtremeTempHandler}>
                  <img src={arrowsUpDown} alt="Up and Down Arrows icon" />
                </button>
              </div>
            </div>
            {extremeTempViewCelsius && (
              <>
                <div className="temp-minima storica">
                  <h5>Minima storica</h5>
                  <p>{chosenSeasonExtremeT?.absValues?.minHistorical ? chosenSeasonExtremeT.absValues.minHistorical + "°C" : "--"}</p>
                </div>
                <div className="temp-minima prevista">
                  <h5>Minima prevista</h5>
                  <p>{chosenSeasonExtremeT?.absValues?.minForeseen ? chosenSeasonExtremeT.absValues.minForeseen + "°C" : "--"}</p>
                </div>
                <div className="separator-line-gray"></div>
                <div className="temp-massima">
                  <h5>Massima storica</h5>
                  <p>{chosenSeasonExtremeT?.absValues?.maxHistorical ? chosenSeasonExtremeT.absValues.maxHistorical + "°C" : "--"}</p>
                </div>
                <div className="temp-massima">
                  <h5>Massima prevista</h5>
                  <p>{chosenSeasonExtremeT?.absValues?.maxForeseen ? chosenSeasonExtremeT.absValues.maxForeseen + "°C" : "--"}</p>
                </div>
              </>
            )}
            {!extremeTempViewCelsius && (
              <>
                <div className="temp-minima span12">
                  <h5>Variazione della temperatura minima</h5>
                  <p>
                    {chosenSeasonExtremeT?.deltaValues?.minDelta
                      ? chosenSeasonExtremeT.deltaValues.minDelta > 0
                        ? `+${chosenSeasonExtremeT.deltaValues.minDelta}°C`
                        : `${chosenSeasonExtremeT.deltaValues.minDelta}°C`
                      : "--"}
                  </p>
                </div>
                <div className="separator-line-gray"></div>
                <div className="temp-massima span12">
                  <h5>Variazione della temperatura massima</h5>
                  <p>
                    {chosenSeasonExtremeT?.deltaValues?.maxDelta
                      ? chosenSeasonExtremeT.deltaValues.maxDelta > 0
                        ? `+${chosenSeasonExtremeT.deltaValues.maxDelta}°C`
                        : `${chosenSeasonExtremeT.deltaValues.maxDelta}°C`
                      : "--"}
                  </p>
                </div>
              </>
            )}
          </div>
        </CardGreenFooter>
      )}
    </>
  );
};

export default ExtremeTCard;
