import { HazardCodeEnum } from "../../types/commonTypes";
import frozeImg from "./../../assets/images/nat-events/FROST.jpg";
import droughtImg from "./../../assets/images/nat-events/DROUGHT.jpg";
import windImg from "./../../assets/images/nat-events/WIND.jpg";
import hailImg from "./../../assets/images/nat-events/HAIL.jpg";
import tmaxImg from "./../../assets/images/nat-events/TMAX.jpg";
import rainImg from "./../../assets/images/nat-events/RAIN.jpg";
import excessImg from "./../../assets/images/nat-events/EXCESS.jpg";
import floodImg from "./../../assets/images/nat-events/FLOOD.jpg";
import lossImg from "./../../assets/images/nat-events/LOSS.jpg";
import wedgeImg from "./../../assets/images/nat-events/WEDGE.jpg";
import erosionImg from "./../../assets/images/nat-events/EROSION.jpg";
import damagesImg from "./../../assets/images/nat-events/DAMAGES.jpg";
import frozeImgSmall from "./../../assets/images/nat-events/w800/FROST_w800.jpg";
import droughtImgSmall from "./../../assets/images/nat-events/w800/DROUGHT_w800.jpg";
import windImgSmall from "./../../assets/images/nat-events/w800/WIND_w800.jpg";
import hailImgSmall from "./../../assets/images/nat-events/w800/HAIL_w800.jpg";
import tmaxImgSmall from "./../../assets/images/nat-events/w800/TMAX_w800.jpg";
import rainImgSmall from "./../../assets/images/nat-events/w800/RAIN_w800.jpg";
import excessImgSmall from "./../../assets/images/nat-events/w800/EXCESS_w800.jpg";
import floodImgSmall from "./../../assets/images/nat-events/w800/FLOOD_w800.jpg";
import lossImgSmall from "./../../assets/images/nat-events/w800/LOSS_w800.jpg";
import wedgeImgSmall from "./../../assets/images/nat-events/w800/WEDGE_w800.jpg";
import erosionImgSmall from "./../../assets/images/nat-events/w800/EROSION_w800.jpg";
import damagesImgSmall from "./../../assets/images/nat-events/w800/DAMAGES_w800.jpg";

interface EventImageCmpProps {
  eventCode: HazardCodeEnum | null;
}

const EventImageCmp = (props: EventImageCmpProps) => {
  const { eventCode } = props;

  const getImageFromCode = (evCode: HazardCodeEnum | null) => {
    switch (evCode) {
      case HazardCodeEnum.FROST:
        return <img className="ev-image" src={frozeImg} alt="Brina" />;
      case HazardCodeEnum.DROUGHT:
        return <img className="ev-image" src={droughtImg} alt="Siccità" />;
      case HazardCodeEnum.WIND:
        return <img className="ev-image" src={windImg} alt="Vento" />;
      case HazardCodeEnum.HAIL:
        return <img className="ev-image" src={hailImg} alt="Grandine" />;
      case HazardCodeEnum.TMIN:
        return <img className="ev-image" src={frozeImg} alt="Temperatura minima estrema" />;
      case HazardCodeEnum.TMAX:
        return <img className="ev-image" src={tmaxImg} alt="Temperatura massima estrema" />;
      case HazardCodeEnum.RAIN:
        return <img className="ev-image" src={rainImg} alt="Precipitazioni" />;
      case HazardCodeEnum.EXCESS:
        return <img className="ev-image" src={excessImg} alt="Foglia bagnata" />;
      case HazardCodeEnum.FLOOD:
        return <img className="ev-image" src={floodImg} alt="Inondazione" />;
      case HazardCodeEnum.LOSS:
        return <img className="ev-image" src={lossImg} alt="Landa verde" />;
      case HazardCodeEnum.WEDGE:
        return <img className="ev-image" src={wedgeImg} alt="Cuneo" />;
      case HazardCodeEnum.EROSION:
        return <img className="ev-image" src={erosionImg} alt="Erosione" />;
      case HazardCodeEnum.DAMAGES:
        return <img className="ev-image" src={damagesImg} alt="Danni al terreno" />;
      default:
        return <p>immagine non disponibile</p>;
    }
  };

  return <>{getImageFromCode(eventCode)}</>;
};

export const EventImageSmallSizeCmp = (props: EventImageCmpProps) => {
  const { eventCode } = props;

  const getImageFromCode = (evCode: HazardCodeEnum | null) => {
    switch (evCode) {
      case HazardCodeEnum.FROST:
        return <img className="ev-image" src={frozeImgSmall} alt="Brina" />;
      case HazardCodeEnum.DROUGHT:
        return <img className="ev-image" src={droughtImgSmall} alt="Siccità" />;
      case HazardCodeEnum.WIND:
        return <img className="ev-image" src={windImgSmall} alt="Vento" />;
      case HazardCodeEnum.HAIL:
        return <img className="ev-image" src={hailImgSmall} alt="Grandine" />;
      case HazardCodeEnum.TMIN:
        return <img className="ev-image" src={frozeImgSmall} alt="Temperatura minima estrema" />;
      case HazardCodeEnum.TMAX:
        return <img className="ev-image" src={tmaxImgSmall} alt="Temperatura massima estrema" />;
      case HazardCodeEnum.RAIN:
        return <img className="ev-image" src={rainImgSmall} alt="Precipitazioni" />;
      case HazardCodeEnum.EXCESS:
        return <img className="ev-image" src={excessImgSmall} alt="Foglia bagnata" />;
      case HazardCodeEnum.FLOOD:
        return <img className="ev-image" src={floodImgSmall} alt="Inondazione" />;
      case HazardCodeEnum.LOSS:
        return <img className="ev-image" src={lossImgSmall} alt="Landa verde" />;
      case HazardCodeEnum.WEDGE:
        return <img className="ev-image" src={wedgeImgSmall} alt="Cuneo" />;
      case HazardCodeEnum.EROSION:
        return <img className="ev-image" src={erosionImgSmall} alt="Erosione" />;
      case HazardCodeEnum.DAMAGES:
        return <img className="ev-image" src={damagesImgSmall} alt="Danni al terreno" />;
      default:
        return <p>immagine non disponibile</p>;
    }
  };

  return <>{getImageFromCode(eventCode)}</>;
};

export default EventImageCmp;
