import { useMemo, useState } from "react";
import { AreaChart, XAxis, YAxis, ResponsiveContainer, Tooltip, Area, ReferenceLine } from "recharts";
import { selectE3ci } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getMmYhFromDate, getE3ciSubData, getYhFromDateStr } from "../../utils/utils";
import { setReferencePeriodDateaAct } from "../../store/e3ci/actions";
import ModalSlide from "../../UI/ModalSlide/ModalSlide";

export default function E3CiChart() {
  const e3ciState = useAppSelector(selectE3ci);
  const dispatch = useAppDispatch();
  const { currentE3ci, referencePeriodDate } = e3ciState;
  const [currentGraphData, setCurrentGraphData] = useState([]);
  const [blockGraphDataRecomputation, setBlockGraphDataRecomputation] = useState(false);
  const [howToReadModal, setHowToReadModal] = useState(false);

  const graphData = useMemo(() => {
    if (blockGraphDataRecomputation) {
      setBlockGraphDataRecomputation(false);
      return currentGraphData;
    }

    const data = getE3ciSubData(currentE3ci, referencePeriodDate);
    setCurrentGraphData(data);
    return data;
  }, [referencePeriodDate, currentE3ci?.data, currentE3ci?.data.length]);

  const currentMmYy = useMemo(() => {
    return getMmYhFromDate(referencePeriodDate);
  }, [referencePeriodDate]);

  const currentValue = useMemo(() => {
    if (!currentE3ci || !referencePeriodDate) {
      return;
    }
    const currEl = currentE3ci.data && currentE3ci.data.find((d) => d.x === currentMmYy);
    return currEl && currEl.y && currEl.y.toFixed(3);
  }, [currentE3ci?.data, currentMmYy]);

  let dataMax = useMemo(() => {
    const data = graphData || [];
    return Math.max(...data.map((i) => i.y));
  }, [graphData]);

  let dataMin = useMemo(() => {
    const data = graphData || [];
    return Math.min(...data.map((i) => i.y));
  }, [graphData]);

  const gradientOffset = () => {
    const THRESHOLD = 1;
    const retVal = (dataMax - THRESHOLD) / (dataMax - dataMin);

    if (dataMax <= THRESHOLD) {
      return 0;
    }
    if (dataMin >= THRESHOLD) {
      return 1;
    }

    return retVal;
  };

  let off = gradientOffset();

  const CustomizedDot = (props) => {
    const { cx, cy, payload } = props;
    if (currentMmYy === payload.x) {
      return <circle r="7" type="monotone" stroke="#AACB81" strokeWidth="4" fill="#fff" cx={cx} cy={cy} className="recharts-dot recharts-line-dot"></circle>;
    }

    return null;
  };

  const areaOnclick = (e) => {
    let closestElement;
    if (graphData && graphData.length > 0 && e.activeTooltipIndex >= 0 && e.activeTooltipIndex < graphData.length) {
      closestElement = graphData[e.activeTooltipIndex];
    }

    if (!closestElement || !closestElement.x) {
      return;
    }

    const monthAndYearArray = closestElement.x.split("-");
    const newDate = new Date(+monthAndYearArray[1], +monthAndYearArray[0] - 1, 1);

    setBlockGraphDataRecomputation(true);
    dispatch(setReferencePeriodDateaAct(newDate));
  };

  const howToReadModalContent = (
    <ModalSlide
      key={"ee3InfoModal"}
      title="Come leggo questo grafico?"
      modalClass="regional-report-modal"
      closeModal={() => {
        setHowToReadModal(false);
      }}
    >
      <>
        <p className="long-text-modal">
          I valori dell'indice e delle sue componenti sono <span>adimensionali</span> e forniscono un'informazione <span>quantitativa delle anomalie climatiche</span>. In
          particolare, quando il valore è riportato nel grafico in{" "}
          <span>
            <span className="green-text">verde</span> non risulta essere indicativo
          </span>{" "}
          di una variazione significativa rispetto al periodo climatico di riferimento (1981-2010). Al contrario, quando il valore è riportato in{" "}
          <span>
            <span className="red-text">rosso</span> è rappresentativo di una variazione climatica particolarmente severa.
          </span>
        </p>
        <div className="custom-modal-footer">
          <button
            className="ada-btn white-btn"
            onClick={() => {
              setHowToReadModal(false);
            }}
          >
            Chiudi
          </button>
        </div>
      </>
    </ModalSlide>
  );

  return (
    <>
      {howToReadModal && howToReadModalContent}
      <div className="e3ci-chart-section">
        <div className="e3ci-chart-container">
          {currentE3ci && currentE3ci.data && (
            <>
              <ResponsiveContainer width="100%" height={350}>
                <AreaChart baseValue={1} onClick={areaOnclick} data={graphData}>
                  <ReferenceLine x={currentMmYy} stroke="#C7DBB3" strokeWidth={2} />
                  <ReferenceLine y={1} stroke="#667085" strokeWidth={2} strokeDasharray={"4 6"} />
                  <XAxis dataKey="x" axisLine={false} tickLine={false} tickFormatter={(x) => getYhFromDateStr(x)} minTickGap={12} padding={{ left: 5, right: 5 }} />
                  <YAxis type="number" axisLine={false} tickLine={false} padding={{ top: 5, bottom: 5 }} />

                  <Tooltip
                    labelFormatter={(value) => {
                      return `mese-anno: ${value}`;
                    }}
                    formatter={(value, name, props) => [value, "valore"]}
                  />
                  <Tooltip />
                  <defs>
                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                      <stop offset={0} stopColor="#F04438" stopOpacity={1} />
                      <stop offset={off} stopColor="#F04438" stopOpacity={0} />
                      <stop offset={off} stopColor="#AACB81" stopOpacity={0} />
                      <stop offset={1} stopColor="#AACB81" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset={off} stopColor="#F04438" />
                      <stop offset={off} stopColor="#AACB81" />
                    </linearGradient>
                  </defs>
                  <Area fillOpacity={1} type="monotone" dataKey="y" stroke="url(#colorUv)" strokeWidth={3} fill="url(#splitColor)" dot={<CustomizedDot />} />
                </AreaChart>
              </ResponsiveContainer>
            </>
          )}
        </div>

        <div className="selected-period-value-container">
          <label>{currentE3ci && currentE3ci.label ? `Valore ${currentE3ci.label.toLowerCase()}:` : "Valore"}</label>
          <span className="value">{currentValue || "--"}</span>
        </div>
        <div className="how-to-read-button-container">
          <button
            className="ada-btn info-btn"
            onClick={() => {
              setHowToReadModal(true);
            }}
          >
            Come leggo questo grafico?
          </button>
        </div>
      </div>
    </>
  );
}
