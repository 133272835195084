import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import "./Auth.scss";

const OrgRegistrationMailSent = () => {
    let history = useHistory();

    const clickBackHandler = () => {
        history.push(`/`);
    };

    return (
        <div className="page-cmp mail-sent-page">
            <WhiteHeader
                goBackCb={clickBackHandler}
                title={"Registrazione organizzazione"}
                closeBtn={true}
            />

            <div className="body-container">
                <div className="mail-sent-page__icon-wrapper">
                    <FontAwesomeIcon icon={faClockRotateLeft} />
                </div>
                <h1 className="mail-sent-page__title">
                    Richiesta inviata!
                </h1>
                <p className="mail-sent-page__text">
                    Verificheremo la tua richiesta, entro 48 ore ti contatteremo per creare la tua utenza.
                </p>
            </div>
        </div>
    );
};

export default OrgRegistrationMailSent;
