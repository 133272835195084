import { FeedbackStateEnum, MetadataType, SectorTypeEnum, UserTypeEnum } from "./commonTypes";

export class AuthType {
  public activateUserData: ActivateUserType = new ActivateUserType();
  public registrationData: RegistrationType | undefined;
  public registerOrgData: RegisterOrgType | undefined;
  public organizationsData: OrganizationsDataType | undefined;
  public userAuthData: UserDataType = new UserDataType();
  public loginData: LoginType = new LoginType();
  constructor(input?: any) {
    if (input) {
      this.registrationData = new RegistrationType(input.registrationData);
      this.registerOrgData = new RegisterOrgType(input.registerOrgData);
      this.organizationsData = new OrganizationsDataType(input.organizationsData);
      this.activateUserData = new ActivateUserType(input.activateUserData && input.activateUserData.metadata);
      this.userAuthData = new UserDataType(input.userAuthData);
      this.loginData = new LoginType(input.loginData);
    }
  }
}

export class OrganizationsDataType {
  public organizations: OrganizationType[] = [];
  public metadata: MetadataType = new MetadataType();
  constructor(input?: any, _metadata?: any) {
    if (input) {
      this.organizations = ((input && input.organizations) || []).map((obj: any) => new OrganizationType(obj));
    }
    if (_metadata) {
      this.metadata = new MetadataType(_metadata);
    }
  }
}

export class OrganizationType {
  public id: string = '';
  public name: string = '';
  constructor(input?: any) {
    if (input) {
      this.id = input.id;
      this.name = input.name;
    }
  }
}

export class RegistrationType {
  public companyName: string = '';
  public email: string = '';
  public pswd: string = '';
  public confirmPswd: string = '';
  public sectors: SectorTypeEnum[] = [];
  public organizations: string[] = [];
  public conditionsChecked: boolean = false;
  public metadata: MetadataType = new MetadataType();
  public resendEmailMetadata : MetadataType = new MetadataType();
  constructor(input?: any, _metadata?: any, _resendEmailMetadata?: any) {
    if (input) {
      this.companyName = input.companyName;
      this.email = input.email;
      this.pswd = input.pswd;
      this.confirmPswd = input.confirmPswd;
      this.sectors = input.sectors || [];
      this.organizations = input.organizations || [];
      this.conditionsChecked = !!input.conditionsChecked;
    }
    if (_metadata) {
      this.metadata = new MetadataType(_metadata);
    }
    if (_resendEmailMetadata) {
      this.resendEmailMetadata = new MetadataType(_resendEmailMetadata);
    }
  }
}

export class ActivateUserType {
  public metadata: MetadataType = new MetadataType();
  constructor(_metadata?: any) {
    if (_metadata) {
      this.metadata = new MetadataType(_metadata);
    }
  }
}

export class LoginType {
  public email: string = '';
  public pswd: string = '';
  public userType: UserTypeEnum = UserTypeEnum.PRODUCER;
  public metadata: MetadataType = new MetadataType();
  constructor(input?: any, _metadata?: any) {
    if (input) {
      this.email = input.email;
      this.pswd = input.pswd;
      this.userType = input.userType;
    }
    if (_metadata) {
      this.metadata = new MetadataType(_metadata);
    }
  }
}

export class UserDataType {
  public companyName: string = '';
  public email: string = '';
  public token: string = '';
  public accessToken: string = '';
  public userType: UserTypeEnum = UserTypeEnum.PRODUCER;
  public sectors: SectorTypeEnum[] = [];
  public organizations: string[] = [];
  public feedbackState?: FeedbackStateEnum;
  constructor(input?: any, _metadata?: any, _resendEmailMetadata?: any) {
    if (input) {
      this.companyName = input.companyName;
      this.email = input.email;
      this.token = input.token;
      this.accessToken = input.accessToken;
      this.userType = input.userType;
      this.sectors = input.sectors || [];
      this.organizations = input.organizations || [];
      this.feedbackState = input.feedbackState;
    }
  }
}

export class RegisterOrgType {
  public metadata: MetadataType = new MetadataType();

  constructor(_metadata?: any) {
    if (_metadata) {
      this.metadata = new MetadataType(_metadata);
    }
  }

}