import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { selectRegionalHazard } from "../store";
import { useAppSelector } from "../store/hooks";
import SolutionDetailCmp from "../components/RiskSolution/SolutionDetailCmp";

import "./AoiHazardSolutionPage.scss";
import useScrollToTop from "../hooks/useCrollToTop";
import AddRemoveSolutionToPlanCmp from "../components/Plan/AddRemoveSolutionToPlanCmp";

interface AoiHazardSolutionPageParams {
  readOnly?: string
}

const AoiHazardSolutionPage = () => {
  const params: AoiHazardSolutionPageParams = useParams();
  const readOnly = params?.readOnly;
  let history = useHistory();
  const hazardState = useAppSelector(selectRegionalHazard);
  const { userInput } = hazardState;
  const { currentSolution } = userInput;
  const [currentStatus, setCurrentStatus] = useState("ToSchedule")
  const [actionExpireDate, setActionExpireDate] = useState(currentSolution?.expiredDate ? new Date(currentSolution.expiredDate) : '');

  useScrollToTop();

  useEffect(() => {
    if (currentSolution.title === "") {
      clickBackHandler();
    }
  }, [currentSolution, history]);

  const clickBackHandler = () => {
    if (readOnly) return history.replace('/regional-solutions?userType=ORGANIZATION')
    return history.goBack();
  };

  return (
    <>
      <div className="page-cmp page-with-no-header page-with-detail-header aoi-solution-detail-page">
        <SolutionDetailCmp
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
          solutionDetail={currentSolution}
          clickBackHandler={clickBackHandler}
          actionExpireDate={actionExpireDate}
          setActionExpireDate={setActionExpireDate}
        />
        {!readOnly && currentSolution && <AddRemoveSolutionToPlanCmp
          currentSolution={{ ...currentSolution, state: currentStatus, expiredDate: actionExpireDate }} />}
      </div>
    </>
  );
};

export default AoiHazardSolutionPage;
