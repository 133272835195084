import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  API_ENDPOINT,
  getBackendData,
  getBackendErrorCode,
  hasBackendResponseErrors,
  sendPost,
} from "../../utils/api";
import { AxiosResponse } from "axios";
import { RegionalHazardType } from "../../types/regionalHazardType";
import { getUserInputHazardBody } from "../../utils/utils.store";
import { loadAoiHazarErrorAct, loadAoiHazarSuccessAct } from "./actions";
import { AoiHazardAction } from "../../types/actions/regional-area-action";
import { ErrorCodeEnum } from "../../types/commonTypes";

export const getStateHazard = (state: any) => state.regionalHazard;

export function* LoadAoiHazardCall(action: any) {
  try {
    let stateHazard: RegionalHazardType = yield select(getStateHazard);
    const { userInput } = stateHazard;
    const { history, goToAoiSolutions, notEditable, selectedPlans } =
      action.payload;

    let _body = getUserInputHazardBody(userInput);

    // let _body: any = action.payload;
    let requestURL = `${API_ENDPOINT}/api/aoi-hazard`;
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res);
      yield put(loadAoiHazarErrorAct(errCode));

      if (errCode != ErrorCodeEnum.AOI_DATA_MISSING) {
        history.push("/area-input-page");
      }
    } else {
      const userInfoData = getBackendData(res);
      yield put(
        loadAoiHazarSuccessAct({ aoiHazardData: userInfoData, selectedPlans })
      );
      if (goToAoiSolutions)
        return history.push(
          `/regional-solutions${notEditable ? "?userType=ORGANIZATION" : ""}`
        );
    }
  } catch (err) {
    yield put(loadAoiHazarErrorAct());
  }
}

export function* watchAoiHazardCall() {
  yield takeEvery([AoiHazardAction.LOAD_AOI_HAZARD], LoadAoiHazardCall);
}

export function* aoiHazardSaga() {
  yield all([fork(watchAoiHazardCall)]);
}

export default aoiHazardSaga;
