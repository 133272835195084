import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import OrganizationsCmp from "../components/Organizations/OrganizationsCmp";
import SectorsCmp from "../components/Sectors/SectorsCmp";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import { selectAuth } from "../store";
import { registerUserAct } from "../store/auth/actions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { SectorTypeEnum } from "../types/commonTypes";
import Loader from "../UI/Loader/Loader";
import { toastDefaultProperties, getErrorMessage } from "../utils/errors.utils";
import { toast } from "react-toastify";
import "./Auth.scss";
import PrivacyCheckCmp from "../components/Sectors/PrivacyCheckCmp";
import InputPassword from "../components/InputPassword/InputPassword";

const saveFormDataToLocalStorage = (data: any) => {
  localStorage.setItem("registrationFormData", JSON.stringify(data));
};

const loadFormDataFromLocalStorage = () => {
  const data = localStorage.getItem("registrationFormData");
  return data ? JSON.parse(data) : null;
};

const SaveDataOnExit = () => {
  const formik: any = useFormikContext();
  const history = useHistory();

  useEffect(() => {
    saveFormDataToLocalStorage({
      companyName: formik.values.companyName,
      email: formik.values.email,
      pswd: formik.values.pswd,
      confirmPswd: formik.values.confirmPswd,
      conditionsChecked: formik.values.conditionsChecked,
    });
  }, [formik, history]);

  return null;
};

const RegistrationPage = () => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const { registrationData } = authState;

  /* const [localRegistrationSectors, setLocalRegistrationSectors] = useState<
    SectorTypeEnum[]
  >(registrationData?.sectors || []); */
  const [localOrganizations, setLocalOrganizations] = useState<string[]>(
    registrationData?.organizations || []
  );

  const [conditionsChecked, setConditionsChecked] = useState<boolean>(
    registrationData?.conditionsChecked || false
  );

  const clickBackHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    if (registrationData?.metadata.error) {
      toast.error(
        getErrorMessage(registrationData?.metadata.errorCode),
        toastDefaultProperties
      );
    }
  }, [registrationData?.metadata.error]);

  const conditionsClickHandler = (e: any) => {
    e.preventDefault();

    setConditionsChecked(!conditionsChecked);
  };

  return (
    <>
      {registrationData?.metadata.isLoading && <Loader isGlobal />}
      <div className="page-cmp register-page">
        <WhiteHeader goBackCb={clickBackHandler} title={"Registrazione"} />

        <Formik
          initialValues={loadFormDataFromLocalStorage() || {
            companyName: "",
            email: "",
            pswd: "",
            confirmPswd: "",
            conditionsChecked: false,
            sau_horticulture: "",
            sau_viticulture: "",
            sau_fruitfarming: "",
            sau_extra: "",
            sau_forestry: "",
          }}
          validationSchema={Yup.object({
            companyName: Yup.string()
              .max(15, "Deve contenere massimo 15 caratteri")
              .required("Campo obbligatorio"),
            email: Yup.string()
              .email("Indirizzo email non valido")
              .required("Campo obbligatorio"),
            pswd: Yup.string()
              .required("Campo obbligatorio")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.,!@#\$%\^&\*])(?=.{8,})/,
                "Deve contenere almeno 8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale"
              )
              .min(8, "Deve contenere almeno 8 caratteri"),
            confirmPswd: Yup.string()
              .required("Campo obbligatorio")
              .oneOf([Yup.ref("pswd"), null], "Le password non sono uguali"),
            sau_horticulture: Yup.number().typeError("Deve essere un numero"),
            sau_viticulture: Yup.number().typeError("Deve essere un numero"),
            sau_fruitfarming: Yup.number().typeError("Deve essere un numero"),
            sau_extra: Yup.number().typeError("Deve essere un numero"),
            sau_forestry: Yup.number().typeError("Deve essere un numero"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const { sau_horticulture, sau_viticulture, sau_fruitfarming, sau_extra, sau_forestry } = values;
            const isAtLeastOneFilled = !!sau_horticulture || !!sau_viticulture || !!sau_fruitfarming || !!sau_extra || !!sau_forestry;
            if (!isAtLeastOneFilled) {
              toast.error("Devi valorizzare almeno uno dei campi SAU", toastDefaultProperties);
              setSubmitting(false);
              return;
            }

            let sauValues = {
              sau_horticulture: 0,
              sau_viticulture: 0,
              sau_fruitfarming: 0,
              sau_extra: 0,
              sau_forestry: 0
            }

            if (sau_horticulture) sauValues.sau_horticulture = parseFloat(sau_horticulture)
            if (sau_viticulture) sauValues.sau_viticulture = parseFloat(sau_viticulture)
            if (sau_fruitfarming) sauValues.sau_fruitfarming = parseFloat(sau_fruitfarming)
            if (sau_extra) sauValues.sau_extra = parseFloat(sau_extra)
            if (sau_forestry) sauValues.sau_forestry = parseFloat(sau_forestry)

            const newValues = {
              ...values,
              /* sectors: localRegistrationSectors, */
              organizations: localOrganizations,
              ...sauValues
            };
            dispatch(registerUserAct({ registrationData: newValues, history }));
            setSubmitting(false);
          }}
        >
          <Form>
            <SaveDataOnExit />
            <div className="input-wrapper padding-y-medium">
              <label htmlFor="companyName">Nome azienda</label>
              <Field className="text-input" name="companyName" type="text" />
              <ErrorMessage name="companyName" />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="email">Email</label>
              <Field className="text-input" name="email" type="email" />
              <ErrorMessage name="email" />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="pswd">Scegli una password</label>
              <InputPassword name="pswd" className="text-input" />
              <ErrorMessage name="pswd" />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="confirmPswd">Conferma password</label>
              <InputPassword name="confirmPswd" className="text-input" />
              <ErrorMessage name="confirmPswd" />
            </div>

            {/* <div className="input-wrapper padding-y-medium">
              <label>A quale filiera appartieni?</label>
              <SectorsCmp
                classList="text-input"
                sectors={localRegistrationSectors}
                setSectors={setLocalRegistrationSectors}
              />
            </div> */}

            <div className="input-wrapper padding-y-medium">
              <label>Fai parte di organizzazioni di produttori?</label>
              <OrganizationsCmp
                classList="text-input"
                organizations={localOrganizations}
                setOrganizations={setLocalOrganizations}
              />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="sau_horticulture">Ettari SAU dedicati all'orticolo</label>
              <Field className="text-input" name="sau_horticulture" type="text" />
              <ErrorMessage name="sau_horticulture" />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="sau_viticulture">Ettari SAU dedicati al vitivinicolo</label>
              <Field className="text-input" name="sau_viticulture" type="text" />
              <ErrorMessage name="sau_viticulture" />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="sau_fruitfarming">Ettari SAU dedicati al frutticolo</label>
              <Field className="text-input" name="sau_fruitfarming" type="text" />
              <ErrorMessage name="sau_fruitfarming" />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="sau_extra">Ettari SAU dedicati a colture extra filiera ADA</label>
              <Field className="text-input" name="sau_extra" type="text" />
              <ErrorMessage name="sau_extra" />
            </div>

            <div className="input-wrapper padding-y-medium">
              <label htmlFor="sau_forestry">Ettari SAU boschiva</label>
              <Field className="text-input" name="sau_forestry" type="text" />
              <ErrorMessage name="sau_forestry" />
            </div>

            <PrivacyCheckCmp
              privacyChecked={conditionsChecked}
              setPrivacyCheckedHandler={(e) => conditionsClickHandler(e)}
              labelTitle="Accetta condizioni"
              className="registration-conditions"
            />

            <ErrorMessage name="conditionsChecked" />

            <button
              className="ada-btn w-100"
              type="submit"
              disabled={!conditionsChecked}
            >
              Registrati
            </button>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default RegistrationPage;
