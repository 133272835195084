import { useHistory } from "react-router-dom";
import "./PersonalAreaDrawer.scss";

const PersonalAreaDrawer = () => {
  let history = useHistory();

  const goToLoginPage = (isOrganization?: boolean) => {
    let path = `/accedi`
    if (isOrganization) {
      path = `${path}?userType=ORGANIZATION`
    }
    history.push(path);
  }

  const goToRegistrationPage = () => {
    history.push(`/registrati`);
  }

  return (
    <div className="personal-area-drawer-container">
      <p className="welcome-text">
        Benvenuto nell’area personale, qui tramite la registrazione potrai
        visualizzare le zone salvate e il tuo piano d’adattamento.{" "}
      </p>
      <p className="text-bold text-center center-q">Sei un produttore?</p>
      <button className="ada-btn transparent-btn border-green w-100"
        onClick={() => goToLoginPage()}>Accedi</button>
      <button className="ada-btn w-100"
        onClick={goToRegistrationPage}>Registrati</button>
      
      <p className="text-center bottom-q link-like"
        onClick={() => goToLoginPage(true)}>Sei un'organizzazione?</p>

    </div>
  );
};

export default PersonalAreaDrawer;
