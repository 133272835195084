import { FeedbackInfo, FeedbackStateEnum } from "../types/commonTypes";
import { getFeedbackInfo_inLO, updateFeedbackInfo_inLO } from "./utils.store";

const feedback_after_minutes: number = +(process.env.REACT_APP_CHECK_FEEDBACK_TIMEOUT_AFTER_MIN || 5)
const CHECK_FEEDBACK_TIMEOUT_AFTER = feedback_after_minutes * 1000 * 60
const MIN_PAGES_VIEWED_TO_SHOW_FEEDBACK = 5

const executeCheckFeedbackCheck = (): boolean => {
    const _feedbackInfo = getFeedbackInfo_inLO()
    /* 
    Mostra feedback modal se:
        •	stato feedback non presente o NOT_EDITED
        •	L’utente è connesso alla webapp da più di CHECK_FEEDBACK_TIMEOUT_AFTER minuti
        •	Utente ha visitato almeno MIN_PAGES_VIEWED_TO_SHOW_FEEDBACK schermate
    */
    if ((!_feedbackInfo?.state || _feedbackInfo.state === FeedbackStateEnum.NOT_EDITED) &&
        _feedbackInfo.numberPageViewed >= 5
    ) {
        return true
    }
    return false
}


export const startFeedbackMechanism = (showFeedbackHandler: Function) => {
    try {
        // if user gave or refused feedback, then exit
        const feedbackInfo = getFeedbackInfo_inLO()
        if (feedbackInfo?.state === FeedbackStateEnum.SUBMITTED) {
            return;
        }

        // save landing time
        const dateNow = Math.floor(Date.now() / 1000);
        feedbackInfo.numberPageViewed = 0
        updateFeedbackInfo_inLO(feedbackInfo)

        setTimeout(() => {
            const showFeedback = executeCheckFeedbackCheck()
            if (showFeedback) {
                // handler to show feedback here
                showFeedbackHandler(true)
            }
        }, CHECK_FEEDBACK_TIMEOUT_AFTER);
    } catch (err) {
        console.log(`Error while starting feedback execution`, JSON.stringify(err))
    }
}


