import CardGreenFooter, { CardGreenFooterProps } from "../../UI/Cards/CardGreenFooter";
import mm from "../../assets/images/mm.png";
import percent from "../../assets/images/percent.png";
import { RainType } from "../../types/aoiHazardType";
import { infoText } from "../../utils/utils";
import { stagioneDiRiferimentoBlock } from "../../utils/utils-cmp";
import { useState } from "react";
import "../../pages/RegionalReportPage.scss";

interface RainCardProps extends CardGreenFooterProps {
  rain: RainType | null
}

const RainCard = (props: RainCardProps) => {
  const { rain, navTo, additionalClass, header: propsHeader, linkLabel } = props

  const [rainfallSeason, setRainfallSeason] = useState("SUMMER");
  const [rainfallpViewMm, setRainfallViewMm] = useState(true);
  const chosenSeasonRain = rain?.info.find((season) => rainfallSeason === season.season);

  const rainfallSeasonHandler = (e: any) => {
    setRainfallSeason(e?.target?.value);
  };

  const toggleRainfallHandler = () => {
    setRainfallViewMm((prev) => !prev);
  };


  return (
    <>
      {rain && (
        <CardGreenFooter
          navTo={navTo}
          linkLabel={linkLabel}
          additionalClass={additionalClass}
          header={{
            title: propsHeader?.title || "",
            navToHaeder: propsHeader?.navToHaeder || "",
            icon: propsHeader?.icon,
          }}
        >

          <p className="info-text">{infoText}</p>
          <div className="double-column">
            {stagioneDiRiferimentoBlock(rainfallSeasonHandler, 2)}
            <div>
              <h4>Vista</h4>
              <div className="double-option-container">
                <button className={"ada-btn mm circle-double-option " + rainfallpViewMm} onClick={toggleRainfallHandler}>
                  <img src={mm} alt="Millimeters icon" />
                </button>
                <button className={"ada-btn perc circle-double-option " + !rainfallpViewMm} onClick={toggleRainfallHandler}>
                  <img src={percent} alt="Percent icon" />
                </button>
              </div>
            </div>
            {rainfallpViewMm && (
              <>
                <div className="temp-minima break-432">
                  <h5>Media storica</h5>
                  <p>{chosenSeasonRain?.absValues?.avHistorical ? chosenSeasonRain.absValues.avHistorical + "mm" : "--"}</p>
                </div>
                <div className="temp-minima">
                  <h5>Media predittiva</h5>
                  <p>
                    {chosenSeasonRain?.absValues?.avForeseen
                      ? chosenSeasonRain.absValues.avForeseen > 0
                        ? `+${chosenSeasonRain.absValues.avForeseen}mm`
                        : `${chosenSeasonRain.absValues.avForeseen}mm`
                      : "--"}
                  </p>
                </div>
              </>
            )}
            {!rainfallpViewMm && (
              <>
                <div className="temp-minima span12">
                  <h5>Variazione media in percentuale</h5>
                  <p>
                    {chosenSeasonRain?.deltaValues?.yearDelta
                      ? chosenSeasonRain.deltaValues.yearDelta > 0
                        ? `+${chosenSeasonRain.deltaValues.yearDelta}%`
                        : `${chosenSeasonRain.deltaValues.yearDelta}%`
                      : "--"}
                  </p>
                </div>
              </>
            )}
          </div>
        </CardGreenFooter>
      )}
    </>
  );
};

export default RainCard;
