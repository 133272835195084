import { RegionalHazardItemType } from "../../types/regionalHazardType";
import { EventImageSmallSizeCmp } from "./EventImageCmp";

interface CarouselItemProps {
  regEvItem: RegionalHazardItemType;
}

const EvCarouselItem = (props: CarouselItemProps) => {
  const { regEvItem } = props;

  return (
    <>
      <div className="carousel-item">
        <div className="img-container">
          <EventImageSmallSizeCmp eventCode={regEvItem.code} />
        </div>
        <div className="info-container">
          <p className="main-info">{regEvItem.mainInfo}</p>
          <p className="sub-info">{regEvItem.subInfo}</p>
          <p className="additional-info">{regEvItem.additionalInfo}</p>
        </div>
      </div>
    </>
  );
};

export default EvCarouselItem;
