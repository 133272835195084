import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../store/hooks";

import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import ModalSlide from "../UI/ModalSlide/ModalSlide";
import CardGreenFooter from "../UI/Cards/CardGreenFooter";
import ee3Logo from "../assets/images/ee3-logo.png";
import poweredByArpae from "../assets/images/powered-by-arpae.png";
import infoCircle from "../assets/images/info-circled.png";
import { HazardCodeEnum, ErrorCodeEnum, UserTypeEnum, PersonalAreaItemTypeEnum } from "../types/commonTypes";
import SeasonalReportCard from "../UI/Cards/SeasonalReportCard";
import { hazardTranslator, hazardIcon, hazardUrlTranslator, getSubtext } from "../utils/utils";
import { faLocationDot, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userInputSetStepAct, resetStateAct, userInputSetDrawAreaAct, userInputSetLocationAct } from "../store/regionalHazardArea/actions";
import { UserInputSteps } from "../types/regionalHazardType";
import { toast } from "react-toastify";
import queryString from "query-string";

import logoIfab from "../assets/images/logo_ifab.png";

import "./RegionalReportPage.scss";
import { selectPersonalArea, selectRegionalHazard } from "../store";
import Loader from "../UI/Loader/Loader";
import { saveAreaAct } from "../store/personalArea/actions";
import { isAuthenticated } from "../utils/api";
import ExtremeTCard from "../components/RegionalClimate/ExtremeTCard";
import RainCard from "../components/RegionalClimate/RainCard";
import { addPersPlanItemnLocalStorage } from "../utils/utils.store";

const RegionalReportPage = () => {
  const [openModalAddAreaName, setOpenModalAddAreaName] = useState(false)
  const [currentAreaName, setCurrentAreaName] = useState('')
  const [ee3InfoModal, setEe3InfoModal] = useState(false);
  const [extremeTempInfoModal, setExtremeTempInfoModal] = useState(false);
  const [notEditable, setNotEditable] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const hazardState = useAppSelector(selectRegionalHazard);
  const personalArea = useAppSelector(selectPersonalArea)
  const { userInput } = hazardState;
  const location = userInput.searchedLocation;
  const reagionalHazardState = useAppSelector(selectRegionalHazard);
  const { aoiHazardData } = reagionalHazardState;
  const extremeT = aoiHazardData.extremeT;
  const isLoading = aoiHazardData.metadata.isLoading || personalArea.addAreaMetadata.isLoading;

  useEffect(() => {
    const search = queryString.parse(window.location.search);
    const userType = search && search["userType"];
    if (userType === UserTypeEnum.ORGANIZATION)
      return setNotEditable(true)

    return setNotEditable(false)
  }, [window.location])

  const rain = aoiHazardData.rain;

  const hazardNoExtremeTNoRain = [
    { name: HazardCodeEnum.WIND, data: aoiHazardData.wind },
    { name: HazardCodeEnum.HAIL, data: aoiHazardData.hail },
    { name: HazardCodeEnum.FROST, data: aoiHazardData.frost },
    { name: HazardCodeEnum.DROUGHT, data: aoiHazardData.drought },
  ];

  const hazardNoExtremeTNoRainDataPresent = hazardNoExtremeTNoRain.find((hazard) => hazard.data !== null);

  useEffect(() => {
    if (reagionalHazardState && reagionalHazardState.metadata && reagionalHazardState.metadata.errorCode === ErrorCodeEnum.AOI_DATA_MISSING) {
      return;
    }

    if (!isLoading && !extremeT && !rain && !hazardNoExtremeTNoRainDataPresent) {
      dispatch(resetStateAct());
      history.push(`/`);
    }
  }, [isLoading, extremeT, rain, hazardNoExtremeTNoRainDataPresent, dispatch, history]);

  const hazardNoExtremeTNoRainDataPresentGroup = hazardNoExtremeTNoRain.filter((el) => el.data && el.data.info.length > 0);
  const fourHazardBlock = hazardNoExtremeTNoRainDataPresentGroup
    .filter((hazard) => !!hazard.data?.info && hazard.data?.info[0].value !== "null")
    .map((hazard, i) => (
      <CardGreenFooter
        navTo={`/regional-solutions/${hazardUrlTranslator(hazard.name)}-expanded`}
        linkLabel={hazard.data?.solutions && hazard.data?.solutions.length > 0 ? "Vedi azioni" : ""}
        additionalClass={hazard.name}
        key={hazard.name}
        header={{
          title: hazardTranslator(hazard.name),
          navToHaeder: `/info-${hazardUrlTranslator(hazard.name)}`,
          icon: { img: infoCircle, altText: "Icona informazioni" },
        }}
      >
        <SeasonalReportCard
          key={hazard.name}
          seasons={hazard.data?.info}
          text="Analisi storica stagionale"
          additionalTextClass="font-medium"
          subText={getSubtext(hazard.name)}
          icon={{ ...hazardIcon(hazard.name) }}
        />
      </CardGreenFooter>
    ));

  const goToDrawAreaHandler = () => {
    history.goBack();
  };

  const ee3InfoModalContent = (
    <ModalSlide
      key={"ee3InfoModal"}
      title="Come calcoliamo questo indice?"
      modalClass="regional-report-modal"
      closeModal={() => {
        setEe3InfoModal(false);
      }}
    >
      <>
        <p className="long-text-modal">
          Lo European Extreme Events Climate Index (E³CI) è un indicatore utilizzato per descrivere gli andamenti del clima e degli eventi estremi in Europa e nel mondo. Nel tool
          ADA vengono mostrati i dati riferiti all’Italia. L’indice ha diverse componenti, una per ogni fenomeno estremo. Ogni componente ha valori mensili: ad esempio, il valore
          E³CI per il mese di gennaio 2022 quantifica le anomalie climatiche rispetto ai mesi di gennaio del periodo 1981-2010. Un valore superiore a 1 denota un’anomalia rilevante
          per il mese di osservazione. Il calcolo di E³CI si avvale della quinta generazione dei dati della rianalisi climatologica, prodotti dal Centro Europeo per le previsioni
          di medio termine (ECMWF) e distribuiti dal programma Copernicus della Commissione Europea. E³CI è un prodotto della International Foundation Big Data and Artificial
          Intellingence for Human Development (iFAB), realizzato dal Centro Euromediterraneo sui Cambiamenti Climatici (CMCC) e da Leithà. Maggiori dettagli su {` `}
          <a href="https://www.ifabfoundation.org/e3ci/" target="_blank" rel="noreferrer" className="link-like green">
            https://www.ifabfoundation.org/e3ci/
          </a>
        </p>
        <img src={logoIfab} alt="Logo Ifab" />
        <div className="custom-modal-footer">
          <button
            className="ada-btn white-btn"
            onClick={() => {
              setEe3InfoModal(false);
            }}
          >
            Chiudi
          </button>
        </div>
      </>
    </ModalSlide>
  );

  const extremeTempInfoModalContent = (
    <ModalSlide
      key={"extremeTempInfoModal"}
      title="Come calcoliamo questi indici?"
      modalClass="regional-report-modal"
      closeModal={() => {
        setExtremeTempInfoModal(false);
      }}
    >
      <>
        <p>
          Grazie al confronto e al analisi di dati storici e predittivi della tua zona riusciamo a indicare gli indici di pericolosità della tua zona, in particolare prendiamo come
          riferimento i fenomeni atmosferici dal 1970 al 2013 e l’analisi predittive del 2021/2050.
        </p>
        <img
          src={poweredByArpae}
          alt="Logo Arpae"
          style={{
            margin: "15px auto",
            display: "block",
          }}
        />
        <div className="custom-modal-footer">
          <button
            className="ada-btn white-btn"
            onClick={() => {
              setExtremeTempInfoModal(false);
            }}
          >
            Chiudi
          </button>
        </div>
      </>
    </ModalSlide>
  );

  const handleModificaZona = () => {
    dispatch(userInputSetStepAct(UserInputSteps.LOCATION_STEP));
    dispatch(userInputSetDrawAreaAct(null));

    dispatch(userInputSetLocationAct({ location: "", cap: "" }));

    history.push("/area-input-page");
  };

  const goToE3ciHandler = () => {
    history.push(`/e3ci`);
  };

  const goToActionsPageHandler = () => {
    history.push(`/regional-solutions${notEditable ? '?userType=ORGANIZATION' : ''}`);
  };

  const SavedAreaMsg = ({ closeToast, solTitle, goToPersonalAreaHandler }: any) => (
    <div className="toast-container">
      <p className="toast-msg"><span className="title-msg">{solTitle}</span></p>
      <p className="link-msg" onClick={goToPersonalAreaHandler}>Vai all'area personale</p>
      <button className="close-toast-btn" onClick={closeToast}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  )

  const goToPersonalAreaHandler = () => {
    history.push(`/personal-area`);
  }

  const saveAreaCb = () => {
    toast.success(<SavedAreaMsg
      solTitle={`La zona '${currentAreaName}' è stata salvata`}
      goToPersonalAreaHandler={goToPersonalAreaHandler}
    />, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      hideProgressBar: true,
      className: 'ada-toast success-toast'
    })
  }

  const handleRequestSaveZone = () => {
    setOpenModalAddAreaName(true)
  }

  const handleSalvaZona = () => {
    const areaData = {
      ...userInput,
      name: currentAreaName
    }
    const areaToSave = { ...areaData, aoiHazardData }

    if (!isAuthenticated()) {
      addPersPlanItemnLocalStorage(PersonalAreaItemTypeEnum.AREA, areaToSave)
      history.push(`/accedi`);
      return
    }
    dispatch(saveAreaAct({ area: areaToSave, successCb: saveAreaCb }))
  };

  const cancelModalHandler = () => {
    setCurrentAreaName('')
    setOpenModalAddAreaName(false)
  }

  const addAreaNameModal = (
    <ModalSlide
      modalClass="add-area-name-modal"
      title="Inserisci un nome per la tua zona"
      confirmModal={handleSalvaZona}
      closeModal={() => setOpenModalAddAreaName(false)}
      cancelModal={cancelModalHandler}
      confirmModalText="Salva zona"
      confirmButtonDisabled={!currentAreaName}
    >
      <>
        <p className="sub-title">Per distinguere meglio la tua zona inserisci un nome:</p>

        <div className="form-container">
          <input
            type="text"
            name='companyName'
            className='company-name'
            onChange={(e) => setCurrentAreaName(e?.target?.value)} />
        </div>
      </>
    </ModalSlide>
  );


  const aoiDataMissingError = !!(reagionalHazardState && reagionalHazardState.metadata && reagionalHazardState.metadata.errorCode === ErrorCodeEnum.AOI_DATA_MISSING);
  return (
    <>
      {isLoading && <Loader isGlobal />}
      {openModalAddAreaName && addAreaNameModal}
      <WhiteHeader title="Il report della tua zona" goBackCb={goToDrawAreaHandler} />
      <div className="page-cmp light-gray-bg">
        <div className="regional-report-page max-width-container">
          {aoiDataMissingError ? (
            <div className="green-box">
              <h3>Sembra non ci siano dati sufficienti per generare il report della tua zona, riprova con un'altra zona o consulta direttamente la sezione rischi</h3>
              <div className="inline-block">
                <button className="ada-btn modifica-zona-btn" onClick={handleModificaZona}>
                  Modifica zona
                </button>
              </div>
            </div>
          ) : (
            <>
              <h3>Ecco l’indice E³CI, il servizio innovativo per la valutazione e gestione del rischio connesso agli eventi meteorologici</h3>
              <button
                className="ada-btn info-btn"
                onClick={() => {
                  setEe3InfoModal(true);
                }}
              >
                Come calcoliamo questo indice?
              </button>
              {ee3InfoModal && ee3InfoModalContent}
              <div className="dual-card-box link-card to-e3ci" onClick={goToE3ciHandler}>
                <CardGreenFooter navTo="/e3ci" linkLabel="Vedi le variazioni per componente">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={ee3Logo} alt="Logo EE3" />
                  </div>
                </CardGreenFooter>
              </div>
              <h3>Ecco l’analisi delle temperature estreme e delle precipitazioni nella tua zona</h3>
              <button
                className="ada-btn info-btn"
                onClick={() => {
                  setExtremeTempInfoModal(true);
                }}
              >
                Come calcoliamo questi indici?
              </button>
              {extremeTempInfoModal && extremeTempInfoModalContent}
              <div className="dual-card-box">
                {
                  <ExtremeTCard
                    extremeT={extremeT}
                    navTo="/regional-solutions/temperature-estreme-expanded"
                    linkLabel={aoiHazardData.extremeT?.solutions && aoiHazardData.extremeT.solutions.length > 0 ? "Vedi azioni" : ""}
                    additionalClass="extreme-temp"
                    header={{
                      title: "Temperature stagionali",
                      navToHaeder: `/info-temperature-estreme`,
                      icon: { img: infoCircle, altText: "Icona informazioni" },
                    }} />
                }
                {
                  <RainCard
                    rain={rain}
                    navTo="/regional-solutions/precipitazioni-expanded"
                    linkLabel={aoiHazardData.rain?.solutions && aoiHazardData.rain?.solutions.length > 0 ? "Vedi azioni" : ""}
                    additionalClass="rain-cumulative"
                    header={{
                      title: "Precipitazioni cumulate",
                      navToHaeder: `/info-precipitazioni`,
                      icon: { img: infoCircle, altText: "Icona informazioni" },
                    }} />
                }
              </div>
              <h3>Ecco gli indici di pericolosità della tua zona</h3>
              <button
                className="ada-btn info-btn"
                onClick={() => {
                  setExtremeTempInfoModal(true);
                }}
              >
                Come calcoliamo questi indici?
              </button>
              <div className="dual-card-box">{fourHazardBlock}</div>
              <div className="green-box cambio-zona super-mb">
                <h3>Gli indici sono calcolati in base alla tua zona</h3>
                <div className="inline-block">
                  <div className="icon-text">
                    <div className="circle-icon">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </div>
                    <div className="text-text">
                      <p>La tua zona selezionata:</p>
                      <span>{location}</span>
                    </div>
                  </div>
                  <button className="ada-btn modifica-zona-btn" onClick={handleModificaZona}>
                    Modifica zona
                  </button>
                </div>
              </div>
              <div className="bottom-box">
                <div>
                  <p>Guarda le azioni che abbiamo preparato per te o salva la tua mappa per consultare in seguito i rischi e le azioni per la tua zona.</p>
                  <div className="buttons-box">
                    <button className="ada-btn actions" onClick={goToActionsPageHandler}>
                      Vedi azioni
                    </button>
                    {!notEditable && <button className="ada-btn save-zona" onClick={handleRequestSaveZone}>
                      Salva zona
                    </button>}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RegionalReportPage;
