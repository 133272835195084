import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getBackendData,
  hasBackendResponseErrors,
  API_ENDPOINT,
  sendDelete,
  sendPost,
  sendGet,
  MOCK_API_ENDPOINT,
} from "../../utils/api";
import {
  changePasswordErrorAct,
  changePasswordSuccessAct,
  deleteAreaErrorAct,
  deleteAreaSuccessAct,
  loadAreasAct,
  loadAreasErrorAct,
  loadAreasSuccessAct,
  loadPlanActionsAct,
  loadPlanActionsErrorAct,
  loadPlanActionsSuccessAct,
  loadRegionalClimateErrorAct,
  loadRegionalClimateSuccessAct,
  loadRisksAnalysisErrorAct,
  loadRisksAnalysisSuccessAct,
  modifyOrganizationsErrorAct,
  modifyOrganizationsSuccessAct,
  saveAreaErrorAct,
  saveAreaSuccessAct,
} from "./actions";
import { AxiosResponse } from "axios";
import { PersonalAreaAction } from "../../types/actions/personal-area-action";
import { store } from "../../configureStore";
import { toast } from "react-toastify";
import { isOrganization } from "../../utils/utils";
import { logoutAct } from "../auth/actions";
import { cleanPersPlanItemnLocalStorage } from "../../utils/utils.store";
import { PersonalAreaItemTypeEnum } from "../../types/commonTypes";

const LOAD_REGIONAL_CLIMATE_ERROR =
  "Errore nell'operazione dei dati climatici regionali";
const LOAD_RISKS_ANALYSIS_ERROR =
  "Errore nell'operazione dei dati sull'analisi dei rischi";
const LOAD_PERSONAL_AREA_ERROR = "Errore nell'operazione di raccolta aree";
const LOAD_PLAN_ACTIONS_ERROR =
  "Errore nell'operazione di raccolta azioni del piano d'adattamento";
const SAVE_AREA_ERROR = "Errore nell'operazione di salvataggio area di ricerca";
const DELETE_AREA_ERROR = "Errore nell'operazione di rimozione area";
const CHANGE_PASSWORD_ERROR = "Errore nell'operazione di cambio password";
const MODIFY_ORGANIZATIONS_ERROR = "Errore nell'operazione di modifica organizzazioni";

export function* LoadPersonalAreaCall(action: any) {
  try {
    let requestURL = `${API_ENDPOINT}/api/areas`;
    let res: AxiosResponse<any> = yield call(sendGet, requestURL);

    if (hasBackendResponseErrors(res)) {
      throw new Error(LOAD_PERSONAL_AREA_ERROR);
    }
    const areasInfo = getBackendData(res);

    yield put(loadAreasSuccessAct(areasInfo));
  } catch (err) {
    yield put(loadAreasErrorAct(LOAD_PERSONAL_AREA_ERROR));
  }
}

export function* LoadRisksAnalysisCall(action: any) {
  try {
    const _body = { _region: [] };
    let requestURL = `${API_ENDPOINT}/api/risks-analysis`;
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      throw new Error(LOAD_RISKS_ANALYSIS_ERROR);
    }
    const risksAnalysisInfo = getBackendData(res);

    yield put(loadRisksAnalysisSuccessAct(risksAnalysisInfo));
  } catch (err) {
    yield put(loadRisksAnalysisErrorAct(LOAD_RISKS_ANALYSIS_ERROR));
  }
}

export function* ChangePasswordCall(action: any) {
  try {
    const { data, history } = action.payload;

    let requestURL = `${API_ENDPOINT}/api/modify-pswd`;
    let res: AxiosResponse<any> = yield call(
      sendPost,
      requestURL,
      data
    );

    if (hasBackendResponseErrors(res)) {
      throw new Error(CHANGE_PASSWORD_ERROR);
    }

    toast.success("Password cambiata con successo!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
    yield put(changePasswordSuccessAct());
    yield put(logoutAct());
    history.replace("/");
  } catch (err) {
    toast.error("Errore nel cambio password! Controlla i dati inseriti.", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
    yield put(changePasswordErrorAct());
  }
}

export function* ModifyOrganizationsCall(action: any) {
  try {
    const { data, history } = action.payload;

    let requestURL = `${API_ENDPOINT}/api/modify-organizations`;
    let res: AxiosResponse<any> = yield call(
      sendPost,
      requestURL,
      data
    );

    if (hasBackendResponseErrors(res)) {
      throw new Error(MODIFY_ORGANIZATIONS_ERROR);
    }

    toast.success("Organizzazioni modificate con successo!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
    yield put(modifyOrganizationsSuccessAct());
    yield put(logoutAct());
    history.replace("/");
  } catch (err) {
    toast.error("Errore nella modifica organizzazioni!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
    yield put(modifyOrganizationsErrorAct());
  }
}

export function* LoadRegionalClimateCall(action: any) {
  try {
    let requestURL = `${API_ENDPOINT}/api/regional-climate`;
    const _body = { _region: [] };
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      throw new Error(LOAD_REGIONAL_CLIMATE_ERROR);
    }
    const areasInfo = getBackendData(res);

    yield put(loadRegionalClimateSuccessAct(areasInfo));
  } catch (err) {
    yield put(loadRegionalClimateErrorAct(LOAD_REGIONAL_CLIMATE_ERROR));
  }
}

export function* SaveAreaCall(action: any) {
  try {
    let _body: any = action.payload?.area;
    let requestURL = `${API_ENDPOINT}/api/add-area`;
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      throw new Error(SAVE_AREA_ERROR);
    }

    cleanPersPlanItemnLocalStorage(PersonalAreaItemTypeEnum.AREA)
    const { dispatch } = store;
    yield put(dispatch(loadAreasAct()));

    if (action.payload?.successCb) {
      action.payload?.successCb();
    }
    yield put(saveAreaSuccessAct());
  } catch (err) {
    yield put(saveAreaErrorAct(SAVE_AREA_ERROR));
  }
}

export function* DeleteAreaCall(action: any) {
  try {
    let requestURL = `${API_ENDPOINT}/api/delete-area?area=${action.payload}`;
    let res: AxiosResponse<any> = yield call(sendDelete, requestURL);

    if (hasBackendResponseErrors(res)) {
      throw new Error(DELETE_AREA_ERROR);
    }

    yield put(deleteAreaSuccessAct());

    const { dispatch } = store;
    yield put(dispatch(loadAreasAct()));
  } catch (err) {
    yield put(deleteAreaErrorAct(DELETE_AREA_ERROR));
  }
}

export function* LoadPlanActionsCall(action: any) {
  try {
    let requestURL = `${API_ENDPOINT}/api/plan-actions`;
    let res: AxiosResponse<any> = yield call(sendGet, requestURL);

    if (hasBackendResponseErrors(res)) {
      throw new Error(LOAD_PLAN_ACTIONS_ERROR);
    }
    const planActionsInfo = getBackendData(res);

    yield put(loadPlanActionsSuccessAct(planActionsInfo));
  } catch (err) {
    yield put(loadPlanActionsErrorAct(LOAD_PLAN_ACTIONS_ERROR));
  }
}

export function* AddDeleteActionCall(action: any) {
  try {
    let _body: any = { 
      solutionId: action.payload?.solutionId, 
      state: action.payload?.state,
      expiredDate: action.payload?.expiredDate,
    };
    let apiUrl = `add-plan-action`;
    let httpFunction = sendPost;
    if (action.payload?.isRemove) {
      httpFunction = sendDelete;
      apiUrl = `delete-plan-action?solutionId=${action.payload?.solutionId}`;
    }
    let requestURL = `${API_ENDPOINT}/api/${apiUrl}`;
    let res: AxiosResponse<any> = yield call(httpFunction, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      throw new Error(SAVE_AREA_ERROR);
    } else {
      const { dispatch } = store;
      cleanPersPlanItemnLocalStorage(PersonalAreaItemTypeEnum.ACTION)
      yield put(dispatch(loadPlanActionsAct()));
    }

    yield put(saveAreaSuccessAct());
  } catch (err) {
    yield put(saveAreaErrorAct(SAVE_AREA_ERROR));
  }
}

export function* watchLoadPersonalAreaCall() {
  yield takeEvery([PersonalAreaAction.LOAD_AREAS], LoadPersonalAreaCall);
}

export function* watchLoadRegionalClimateCall() {
  yield takeEvery(
    [PersonalAreaAction.LOAD_REGIONAL_CLIMATE],
    LoadRegionalClimateCall
  );
}

export function* watchLoadRisksAnalysisCall() {
  yield takeEvery(
    [PersonalAreaAction.LOAD_RISKS_ANALYSIS],
    LoadRisksAnalysisCall
  );
}

export function* watchChangePasswordCall() {
  yield takeEvery([PersonalAreaAction.CHANGE_PASSWORD], ChangePasswordCall);
}

export function* watchModifyOrganizationsCall() {
  yield takeEvery([PersonalAreaAction.MODIFY_ORGANIZATIONS], ModifyOrganizationsCall);
}

export function* watchLoadPlanActionsCall() {
  yield takeEvery([PersonalAreaAction.LOAD_PLAN_ACTIONS], LoadPlanActionsCall);
}

export function* watchSaveAreaCall() {
  yield takeEvery([PersonalAreaAction.SAVE_AREA], SaveAreaCall);
}

export function* watchDeleteAreaCall() {
  yield takeEvery([PersonalAreaAction.DELETE_AREA], DeleteAreaCall);
}

export function* watchAddDeleteActionCall() {
  yield takeEvery([PersonalAreaAction.ADD_DELETE_ACTION], AddDeleteActionCall);
}

export function* personalDataSaga() {
  yield all([
    fork(watchLoadPersonalAreaCall),
    fork(watchSaveAreaCall),
    fork(watchLoadPlanActionsCall),
    fork(watchDeleteAreaCall),
    fork(watchAddDeleteActionCall),
    fork(watchLoadRegionalClimateCall),
    fork(watchLoadRisksAnalysisCall),
    fork(watchChangePasswordCall),
    fork(watchModifyOrganizationsCall),
  ]);
}

export default personalDataSaga;
