import { faMap } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { loadAoiHazardAct, loadRegionalHazardAct, userInputSetDataAct } from "../../store/regionalHazardArea/actions";
import { AreaItemType } from "../../types/regionalHazardType";
import mapPlaceholder from "./../../assets/images/map_placeholder.png";
import mapPlaceholder_2 from "./../../assets/images/map_placeholder_2.png";
import DeleteZone from "./DeleteZone";
import "./ZoneCard.scss";

interface ZoneCardProps {
  area: AreaItemType
  index: number;
}
const ZoneCard = (props: ZoneCardProps) => {
  const history = useHistory()
  const dispatch = useAppDispatch();
  const { area, index } = props
  const mapPlaceholderImgSrc = (index % 2) === 0 ? mapPlaceholder : mapPlaceholder_2
  const [deleteAreaVisible, setDeleteAreaVisible] = useState(false)
  const deleteVisibleClass = deleteAreaVisible ? 'show' : ''

  const loadHazardHandler = () => {
    dispatch(userInputSetDataAct(area))
    dispatch(loadRegionalHazardAct({ history }))
    dispatch(loadAoiHazardAct({ history }))
  }

  return (
    <>
      <div className="zone-card">
        <DeleteZone
          id={area.id}
          className={deleteVisibleClass}
          setDeleteAreaVisible={setDeleteAreaVisible} />
        <div className="zone-card__header">
          <h3 className="zone-card__title">{area.name}</h3>
          <div className="zone-card__close"
            onClick={() => setDeleteAreaVisible(true)}>
            <FontAwesomeIcon icon={faClose} />
          </div>
        </div>
        <div
          onClick={loadHazardHandler}>
          <div className="zone-card__content">
            <div className="zone-card__content__map_placeholder">
              <img src={mapPlaceholderImgSrc} alt="map placeholder" />
              <div className="map-placeholder-icon">
                <FontAwesomeIcon icon={faMap} />
              </div>
            </div>
          </div>
          <div className="zone-card__footer">
            <p className="zone-card__footer-text">
              Vedi il report</p>
            <div className="zone-card__footer-icon">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZoneCard;
