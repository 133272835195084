import React, { useEffect, useMemo, useState } from 'react'
import { ProducerType, RisksAnalysisDataType } from '../../types/personalAreaType'
import AdaAccordion from '../AdaAccordion/AdaAccordion'

interface SpecificsDataProps {
    data: RisksAnalysisDataType
}

const SpecificsData = ({ data }: SpecificsDataProps) => {
    const [accordionStatus, setAccordionStatus] = useState<Array<boolean>>([])

    useMemo(() => {
        let currentAccordionStatus: Array<boolean> = [];
        data.producers.forEach(() => currentAccordionStatus.push(false))

        setAccordionStatus(currentAccordionStatus)
    }, [data])

    const openAccordion = (index: number) => {
        let closedAccordionsArray = closeAllAccordionsArray();

        if (!accordionStatus[index])
            closedAccordionsArray[index] = true;

        setAccordionStatus(closedAccordionsArray)
    }

    const closeAllAccordionsArray = () => {
        return accordionStatus.map(() => false)
    }

    return (
        <>
            {data.producers.map((el: ProducerType, key: number) => <AdaAccordion isOpen={accordionStatus[key]} key={key} title={el.companyName} data={el} index={key} openAccordion={openAccordion}></AdaAccordion>)}
        </>
    )
}

export default SpecificsData