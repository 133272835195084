import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { selectAllRisks } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { HazardSolutionTableItemType } from "../types/aoiHazardType";
import queryString from "query-string";
import "./RiskItemPage.scss";
import { RiskItemType } from "../types/allRisksType";
import EventImageCmp from "../components/RegionalCarousel/EventImageCmp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import filterIcon from "../assets/images/filter-list.png";
import { setCurrentSolutionDetailsAct, setCurrentFiltersAct } from "../store/allRisks/actions";
import { SolutionScopeEnum, SectorTypeEnum } from "../types/commonTypes";
import { sectorEnumToLabel, actionAreaToString } from "../utils/utils";
import ModalSlide from "../UI/ModalSlide/ModalSlide";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const RiskItemPage = () => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  const allRisksState = useAppSelector(selectAllRisks);
  const { currentFilters } = allRisksState;
  const [risk, setRisk] = useState<RiskItemType | undefined>();
  const { risks } = allRisksState;

  const [openModal, setOpenModal] = useState(false);

  const toggleFilterModal = (openState: boolean) => {
    setOpenModal(openState);
  };

  const defaultScopesFilters = currentFilters.filter((filter) => filter.isScope);
  const defaultSectorsFilters = currentFilters.filter((filter) => !filter.isScope);

  const [activeScopesFilters, setActiveScopesFilters] = useState(defaultScopesFilters);
  const [activeSectorsFilters, setActiveSectorsFilters] = useState(defaultSectorsFilters);

  const handleScopesFilters = (sector: string | SolutionScopeEnum) => {
    const _activeScopesFilters = activeScopesFilters.map((scp) => {
      if (scp.name === sector) {
        scp.active = !scp.active;
      }
      return scp;
    });
    setActiveScopesFilters(_activeScopesFilters);
  };

  const scopesCheckBoxes = activeScopesFilters.map((scope) => {
    let selectedClass = scope.active ? "is-selected" : "";
    return (
      <div className="checkbox-container" key={scope.name}>
        <button className={`ada-ck ${scope.name} ${selectedClass}`} onClick={() => handleScopesFilters(scope.name)}>
          <FontAwesomeIcon icon={faCheck} />
        </button>
        <p>{scope.name}</p>
      </div>
    );
  });

  const handleSectorsFilters = (sector: SectorTypeEnum | string) => {
    const _activeSectorsFilters = activeSectorsFilters.map((sct) => {
      if (sct.name === sector) {
        sct.active = !sct.active;
      }
      return sct;
    });
    setActiveSectorsFilters(_activeSectorsFilters);
  };

  const sectorsCheckBoxes = activeSectorsFilters.map((sector) => {
    let selectedClass = sector.active ? "is-selected" : "";
    return (
      <div className="checkbox-container" key={sector.name}>
        <button className={`ada-ck ${sector.name} ${selectedClass}`} onClick={() => handleSectorsFilters(sector.name)}>
          <FontAwesomeIcon icon={faCheck} />
        </button>
        <p>{sectorEnumToLabel(sector.name)}</p>
      </div>
    );
  });

  const resetAllFilters = () => {
    const _defaultFilters = currentFilters.map((filter) => {
      filter.active = false;
      return filter;
    });
    dispatch(setCurrentFiltersAct(_defaultFilters));
  };

  const filtersModal = (
    <ModalSlide title="Filtra le azioni di adattamento" closeModal={() => toggleFilterModal(false)} confirmModal={() => updateFilters()} cancelModal={resetAllFilters}>
      <div className="filter-modal-body">
        <h3>
          <span>Ambito</span>
        </h3>
        {scopesCheckBoxes}
        <h3>
          <span>Filiera</span>
        </h3>
        {sectorsCheckBoxes}
      </div>
    </ModalSlide>
  );

  const goToAllRisks = () => {
    history.push("/all-risks");
  };

  useEffect(() => {
    const search = queryString.parse(window.location.search);
    const { code } = search;
    const risk = risks && risks.find((risk) => risk.code === code);
    if (!risk || !risk.solutions || !risk.solutions.length) {
      goToAllRisks();
    } else {
      setRisk(risk);
    }
  }, []);

  const riskDetail = risk && risk.solutions && risk.solutions.length > 0 && risk.solutions[0];

  const goToSolutionDetail = (solution: HazardSolutionTableItemType) => {
    dispatch(setCurrentSolutionDetailsAct(solution));
    history.push(`/solution-details?code=${riskDetail && riskDetail.code}&id=${solution._id}&viewOnly=true`);
  };

  const allFilters = useMemo(() => [...activeScopesFilters, ...activeSectorsFilters], []);

  const updateFilters = () => {
    dispatch(setCurrentFiltersAct([...activeScopesFilters, ...activeSectorsFilters]));
    setOpenModal(false);
  };

  const activeFiltersList = allFilters
    .filter((filter) => filter.active === true)
    .map((fil) => {
      // @ts-ignore
      const name = fil.isScope ? fil.name : sectorEnumToLabel(fil.name);
      // @ts-ignore
      const clickHandle = fil.isScope ? () => handleScopesFilters(fil.name) : () => handleSectorsFilters(fil.name);
      return (
        <button className="active-filter" key={fil.name} onClick={clickHandle}>
          <div>
            <p>{name} </p>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </button>
      );
    });

  const hasSector = (rd: HazardSolutionTableItemType) => {
    const sectorsInfo = rd.infoTable.filter((el) => el.code === "SECTORS");
    const elementSectors = sectorsInfo && sectorsInfo[0].additionalInfo;
    const containsAll = activeSectorsFilters
      .filter((el) => el.active)
      .map((el) => el.name)
      ?.every((element) => {
        return elementSectors?.includes(element);
      });
    return containsAll;
  };

  const riskItemsFilteredBySector = riskDetail && riskDetail.items && riskDetail.items.filter(hasSector);

  const hasScope = (rd: HazardSolutionTableItemType) => {
    const rdAreaToString = rd.actionArea.map((el) => actionAreaToString(el));
    const containsAll = activeScopesFilters
      .filter((el) => el.active)
      .map((el) => el.name)
      ?.every((element) => {
        // @ts-ignore
        return rdAreaToString?.includes(element);
      });
    return containsAll;
  };

  const riskItemsFilteredBySectorAndScope = riskItemsFilteredBySector && riskItemsFilteredBySector.filter(hasScope);
  const solLabelTitle = risk && risk.label ? `in caso di ${risk.label.toLowerCase()}` : "";

  return (
    <>
      {openModal && filtersModal}
      {risk && riskDetail && (
        <div className="page-cmp page-with-no-header risk-item-page">
          <div className="risk-item-header">
            <EventImageCmp eventCode={risk.code} />
            <div className="header-content">
              <button className="ada-btn transparent-btn back-btn" onClick={goToAllRisks}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <p className="risk-label width-1480-element">{risk.label}</p>
            </div>
          </div>
          <div className="risk-item-container max-1480-container">
            <div className="risk-item-body">
              <p className="description">{riskDetail.description}</p>
            </div>

            <div className="solutions-list-container">
              <p className="title">Le azioni di adattamento {solLabelTitle}</p>
              <button className="ada-btn white-btn filter" onClick={() => setOpenModal(true)}>
                <img src={filterIcon} alt="Filtra" />
                <span>Filtra</span>
              </button>
              {activeFiltersList}
              <ul className="solutions-list">
                {riskItemsFilteredBySectorAndScope &&
                  riskItemsFilteredBySectorAndScope.map((rd: HazardSolutionTableItemType) => (
                    <li key={rd._id} onClick={() => goToSolutionDetail(rd)} className="solutions-list-item">
                      <p className="solution-item-title">{rd.action}</p>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RiskItemPage;
