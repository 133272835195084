import { action } from "typesafe-actions";
import { AoiHazardAction, RegionalHazardAction } from "../../types/actions/regional-area-action";
import { AreaItemType, DrawAreaType, ILocation, UserInputSteps, ViewPortType } from "../../types/regionalHazardType";
import { HazardSolutionTableItemType } from "../../types/aoiHazardType";

export const loadRegionalHazardAct = (props: any) => action(RegionalHazardAction.LOAD_REGIONAL_HAZARD, props);
export const loadRegionalHazarSuccessAct = (resp: any[]) => action(RegionalHazardAction.LOAD_REGIONAL_HAZARD_SUCCESS, resp);
export const loadRegionalHazarErrorAct = (err?: string) => action(RegionalHazardAction.LOAD_REGIONAL_HAZARD_ERROR, err);

export const userInputSetStepAct = (step: UserInputSteps) => action(RegionalHazardAction.USER_INPUT_SET_STEP, step);
export const userInputSetSectorsAct = (step: any[]) => action(RegionalHazardAction.USER_INPUT_SET_SECTORS, step);
export const privacyCheckOnOffAct = (checked: boolean) => action(RegionalHazardAction.PRIVACY_CHECK_ON_OFF, checked);
export const userInputSetLocationAct = (location: ILocation) => action(RegionalHazardAction.USER_INPUT_SET_GEOLOCATION, location);
export const userInputMapSetViewportAct = (viwport: ViewPortType) => action(RegionalHazardAction.USER_INPUT_MAP_SET_VIEWPORT, viwport);
export const userInputMapZoomInAct = () => action(RegionalHazardAction.USER_INPUT_MAP_ZOOM_IN);
export const userInputMapZoomOutAct = () => action(RegionalHazardAction.USER_INPUT_MAP_ZOOM_OUT);
export const userInputSetDrawAreaAct = (drawArea: DrawAreaType | null) => action(RegionalHazardAction.USER_INPUT_SET_DRWA_AREA, drawArea);
export const userInputPartialPoligonVertexAct = (nVertexes: number | null) => action(RegionalHazardAction.USER_INPUT_PARTIAL_POLYGON_N_VERTEX, nVertexes);
export const userInputClearAct = () => action(RegionalHazardAction.USER_INPUT_CLEAR);
export const setCurrentSolutionAct = (solutionDetail: HazardSolutionTableItemType | null) => action(RegionalHazardAction.SET_CURRENT_SOLUTION, solutionDetail);
export const resetStateAct = () => action(RegionalHazardAction.RESET_STATE);
export const userInputSetDataAct = (areaItem: AreaItemType) => action(RegionalHazardAction.USER_INPUT_SET_DATA, areaItem);

export const loadAoiHazardAct = (props: any) => action(AoiHazardAction.LOAD_AOI_HAZARD, props);
export const loadAoiHazarSuccessAct = (resp: any) => action(AoiHazardAction.LOAD_AOI_HAZARD_SUCCESS, resp);
export const loadAoiHazarErrorAct = (err?: string) => action(AoiHazardAction.LOAD_AOI_HAZARD_ERROR, err);
