import { HazardCodeEnum, LevelEnum, MetadataType, SeasonEnum, SolutionTableEnum, ValueLabel } from "./commonTypes";

export class AoilHazardDataType {
  public extremeT: ExtremeTemperatureType | null = null;
  public rain: RainType | null = null;
  public wind: WindType | null = null;
  public hail: HailType | null = null;
  public frost: FrostType | null = null;
  public drought: DroughtType | null = null;
  public metadata: MetadataType = new MetadataType();
  constructor(input?: any, _metadata?: any) {
    if (input) {
      this.extremeT = input.extremeT ? new ExtremeTemperatureType(input.extremeT) : null;
      this.rain = input.rain ? new RainType(input.rain) : null;
      this.wind = input.wind ? new WindType(input.wind) : null;
      this.hail = input.hail ? new HailType(input.hail) : null;
      this.frost = input.frost ? new FrostType(input.frost) : null;
      this.drought = input.drought ? new DroughtType(input.drought) : null;
    }
    if (_metadata) {
      this.metadata = new MetadataType(_metadata);
    }
  }
}

export class ExtremeTemperatureType {
  public info: ExtremeTemperatureItemType[] = [];
  public solutions: HazardSolutionItemType[] = [];
  constructor(input?: any) {
    if (input) {
      this.info = ((input && input.info) || []).map((obj: any) => new ExtremeTemperatureItemType(obj));
      this.solutions = ((input && input.solutions) || []).map((obj: any) => new HazardSolutionItemType(obj));
    }
  }
}

export class ExtremeTemperatureItemType {
  public season: SeasonEnum | null = null;
  public absValues: AbsValues | null = null;
  public deltaValues: DeltaValues | null = null;
  constructor(input?: any) {
    if (input) {
      this.season = input && input.season;
      this.absValues = input.absValues ? new AbsValues(input.absValues) : null;
      this.deltaValues = input.deltaValues ? new DeltaValues(input.deltaValues) : null;
    }
  }
}

export class AbsValues {
  public minHistorical: number | null = null;
  public maxHistorical: number | null = null;
  public minForeseen: number | null = null;
  public maxForeseen: number | null = null;
  public avHistorical: number | null = null;
  public avForeseen: number | null = null;
  constructor(input?: any) {
    if (input) {
      this.minHistorical = input.minHistorical || input.minHistorical === 0 ? input.minHistorical : null;
      this.maxHistorical = input.maxHistorical || input.maxHistorical === 0 ? input.maxHistorical : null;
      this.minForeseen = input.minForeseen || input.minForeseen === 0 ? input.minForeseen : null;
      this.maxForeseen = input.maxForeseen || input.maxForeseen === 0 ? input.maxForeseen : null;
      this.avHistorical = input.avHistorical || input.avHistorical === 0 ? input.avHistorical : null;
      this.avForeseen = input.avForeseen || input.avForeseen === 0 ? input.avForeseen : null;
    }
  }
}

export class DeltaValues {
  public minDelta: number | null = null;
  public maxDelta: number | null = null;
  public yearDelta: number | null = null;
  constructor(input?: any) {
    if (input) {
      this.minDelta = input.minDelta || input.minDelta === 0 ? input.minDelta : null;
      this.maxDelta = input.maxDelta || input.maxDelta === 0 ? input.maxDelta : null;
      this.yearDelta = input.yearDelta || input.yearDelta === 0 ? input.yearDelta : null;
    }
  }
}

export class HazardSolutionsType {
  public solutions: HazardSolutionItemType[] = [];
  constructor(input?: any) {
    if (input) {
      this.solutions = ((input && input.solutions) || []).map((obj: any) => new HazardSolutionItemType(obj));
    }
  }
}

export class HazardSolutionItemType {
  public code: HazardCodeEnum | null = null;
  public level: LevelEnum | null = null;
  public description: string = "";
  public items: HazardSolutionTableItemType[] = [];
  constructor(input?: any) {
    if (input) {
      this.code = input && input.code;
      this.level = input && input.level;
      this.description = (input && input.description) || "";
      this.items = ((input && input.items) || []).map((obj: any) => new HazardSolutionTableItemType(obj));
    }
  }
}

export class HazardSolutionTableItemType {
  public _id: string = "";
  public title: string = "";
  public code: HazardCodeEnum | null = null;
  public level: LevelEnum | null = null;
  public infoTable: InfoTableItemType[] = [];
  public description: string = "";
  public referencedProjects: ReferencedProjectItemType[] = [];
  public actionArea: number[] = [];
  public hazard: string = ''
  public action: string = ''
  public state: string = ''
  public expiredDate?: string | Date;
  constructor(input?: any) {
    if (input) {
      this._id = (input && input._id) || "";
      this.title = (input && input.title) || "";
      this.code = input && input.code;
      this.level = input && input.level;
      this.infoTable = ((input && input.infoTable) || []).map((obj: any) => new InfoTableItemType(obj));
      this.description = (input && input.description) || "";
      this.referencedProjects = ((input && input.referencedProjects) || []).map((obj: any) => new ReferencedProjectItemType(obj));
      this.actionArea = input && input.actionArea;
      this.hazard = input.hazard;
      this.action = input.action;
      this.state = input.state;
      this.expiredDate = input.expiredDate;
    }
  }
}

export class InfoTableItemType {
  public code: SolutionTableEnum | null = null;
  public title: string = "";
  public value: string = "";
  public additionalInfo: any[] | null = null;
  constructor(input?: any) {
    if (input) {
      this.code = input && input.code;
      this.title = (input && input.title) || "";
      this.value = (input && input.value) || "";
      this.additionalInfo = input && input.additionalInfo;
    }
  }
}

export class ReferencedProjectItemType {
  public label: string = "";
  public url: string = "";
  constructor(input?: any) {
    if (input) {
      this.label = (input && input.label) || "";
      this.url = (input && input.url) || "";
    }
  }
}

export class RainType {
  public info: RainItemType[] = [];
  public solutions: HazardSolutionItemType[] = [];
  constructor(input?: any) {
    if (input) {
      this.info = ((input && input.info) || []).map((obj: any) => new RainItemType(obj));
      this.solutions = ((input && input.solutions) || []).map((obj: any) => new HazardSolutionItemType(obj));
    }
  }
}

export class RainItemType {
  public season: SeasonEnum | null = null;
  public absValues: AbsValues | null = null;
  public deltaValues: DeltaValues | null = null;
  constructor(input?: any) {
    if (input) {
      this.season = input && input.season;
      this.absValues = input.absValues ? new AbsValues(input.absValues) : null;
      this.deltaValues = input.deltaValues ? new DeltaValues(input.deltaValues) : null;
    }
  }
}

export class BaseHazardype {
  public info: ValueLabel[] = [];
  public solutions: HazardSolutionItemType[] = [];
  constructor(input?: any) {
    if (input) {
      this.info = ((input && input.info) || []).map((obj: any) => new ValueLabel(obj));
      this.solutions = ((input && input.solutions) || []).map((obj: any) => new HazardSolutionItemType(obj));
    }
  }
}

export class WindType extends BaseHazardype {
  constructor(input?: any) {
    super(input);
    if (input) {
    }
  }
}
export class HailType extends BaseHazardype {
  constructor(input?: any) {
    super(input);
    if (input) {
    }
  }
}
export class FrostType extends BaseHazardype {
  constructor(input?: any) {
    super(input);
    if (input) {
    }
  }
}
export class DroughtType extends BaseHazardype {
  constructor(input?: any) {
    super(input);
    if (input) {
    }
  }
}

export class CurrentFilter {
  public name: string = "";
  public active: boolean = false;
  public isScope: boolean = false;
  constructor(input: any) {
    if (input) {
      this.name = input.name;
      this.active = input.active;
      this.isScope = input.isScope;
    }
  }
}

export const getSolutionLabelFromCode = (evCode: string | HazardCodeEnum | null) => {
  switch (evCode) {
    case HazardCodeEnum.FROST:
      return "Gelate"
    case HazardCodeEnum.DROUGHT:
      return "Siccità"
    case HazardCodeEnum.WIND:
      return "Vento"
    case HazardCodeEnum.HAIL:
      return "Grandine"
    case HazardCodeEnum.TMIN:
      return "Gelate"
    case HazardCodeEnum.TMAX:
      return "Danni da temperature estreme massime"
    case HazardCodeEnum.RAIN:
      return "Precipitazioni intense"
    case HazardCodeEnum.EXCESS:
      return "Eccesso idrico"
    case HazardCodeEnum.FLOOD:
      return "Eventi alluvionali"
    case HazardCodeEnum.LOSS:
      return "Perdita di vocazionalità territoriale"
    case HazardCodeEnum.WEDGE:
      return "Cuneo salino"
    case HazardCodeEnum.EROSION:
      return "Erosione"
    case HazardCodeEnum.DAMAGES:
      return "Danni fitosanitari"
    default:
      return '';
  }
};
