export enum RegionalHazardAction {
  LOAD_REGIONAL_HAZARD = "@@regional-hazard/LOAD_REGIONAL_HAZARD",
  LOAD_REGIONAL_HAZARD_SUCCESS = "@@regional-hazard/LOAD_REGIONAL_HAZARD_SUCCESS",
  LOAD_REGIONAL_HAZARD_ERROR = "@@regional-hazard/LOAD_REGIONAL_HAZARD_ERROR",
  USER_INPUT_SET_STEP = "@@regional-hazard/USER_INPUT_SET_STEP",
  USER_INPUT_SET_SECTORS = "@@regional-hazard/USER_INPUT_SET_SECTORS",
  PRIVACY_CHECK_ON_OFF = "@@regional-hazard/PRIVACY_CHECK_ON_OFF",
  USER_INPUT_SET_GEOLOCATION = "@@regional-hazard/USER_INPUT_SET_GEOLOCATION",
  USER_INPUT_MAP_SET_VIEWPORT = "@@regional-hazard/USER_INPUT_MAP_SET_VIEWPORT",
  USER_INPUT_MAP_ZOOM_OUT = "@@regional-hazard/USER_INPUT_MAP_ZOOM_OUT",
  USER_INPUT_MAP_ZOOM_IN = "@@regional-hazard/USER_INPUT_MAP_ZOOM_IN",
  USER_INPUT_SET_DRWA_AREA = "@@regional-hazard/USER_INPUT_SET_DRWA_AREA",
  USER_INPUT_SET_DATA   = "@@regional-hazard/USER_INPUT_SET_DATA",
  USER_INPUT_PARTIAL_POLYGON_N_VERTEX = "@@regional-hazard/USER_INPUT_PARTIAL_POLYGON_N_VERTEX",
  USER_INPUT_CLEAR = "@@regional-hazard/USER_INPUT_CLEAR",
  SET_CURRENT_SOLUTION = "@@regional-hazard/SET_CURRENT_SOLUTION",
  RESET_STATE = "@@regional-hazard/RESET_STATE",
}

export enum AoiHazardAction {
  LOAD_AOI_HAZARD = "@@regional-hazard/LOAD_AOI_HAZARD",
  LOAD_AOI_HAZARD_SUCCESS = "@@regional-hazard/LOAD_AOI_HAZARD_SUCCESS",
  LOAD_AOI_HAZARD_ERROR = "@@regional-hazard/LOAD_AOI_HAZARD_ERROR",
}
