import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "../../store/hooks";
import { userInputSetStepAct } from "../../store/regionalHazardArea/actions";
import { UserInputSteps } from "../../types/regionalHazardType";
import helpIcon from "../../assets/images/help.png";
import "./SearchAreaHeader.scss";

const SearchAreaHeader = () => {
  const dispatch = useAppDispatch();

  const goToSectorsHandler = () => {
    dispatch(userInputSetStepAct(UserInputSteps.SECTORS_STEP));
  };

  const helpHandler = () => {
    //TODO aggiungere contatta assistenza
    console.log("help handler");
  };

  return (
    <>
      <div className="header-cmp white-header">
        <button className="ada-btn semi-transparent-btn hd-back-btn" onClick={goToSectorsHandler}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <p className="hd-title">Cerca la tua zona</p>
        <button className="ada-btn help-button" onClick={helpHandler}>
          <img src={helpIcon} alt={"Icona contatta l'assistenza"} />
        </button>
      </div>
    </>
  );
};

export default SearchAreaHeader;
