import { useHistory } from "react-router-dom";
import "./HomeLanding.scss";
import mainImg from "./../assets/images/main-img.png";
import ueCertImg from "./../assets/images/ue-cert.png";
import Header from "../UI/Header/Header";
import { useAppDispatch } from "../store/hooks";
import { userInputClearAct } from "../store/regionalHazardArea/actions";

const HomeLandingPage = () => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const goToAreaInputPage = () => {
    // clear user input area
    dispatch(userInputClearAct())
    history.push(`/area-input-page`);
  };

  return (
    <>
      <Header />
      <div className="page-cmp page-with-header home-landing-page">
        <div className="side left-side">
          <div className="main-img-container">
            <img src={mainImg} alt="Ada landing" />
          </div>
        </div>
        <div className="side right-side">
          <h1 className="main-title">Un piano d’adattamento su misura per te</h1>
          <p className="info">
            La web app ADA Tool è uno strumento semplice ed immediato che dà ai produttori la possibilità di creare il proprio piano di adattamento, inoltre permette di capire:
          </p>
          <ul className="list-info">
            <li>a quali pericoli è esposta la propria azienda agricola (Vento, Siccità, Grandine, Gelate tardive)</li>
            <li>quali possono essere le azioni da applicare nella propria azienda per prevenire i danni.</li>
          </ul>
          <div className="bottom-box">
            <button className="ada-btn w-100 start-app-btn" onClick={goToAreaInputPage}>
              Inizia
            </button>
          </div>
        </div>
        <div className="c-image-container cert">
          <img src={ueCertImg} alt="UE Certification LIFE financial" />
        </div>
      </div>
    </>
  );
};

export default HomeLandingPage;
