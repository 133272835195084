import { useState } from "react";
import ModalSlide from "../../UI/ModalSlide/ModalSlide";
import "../../pages/Auth.scss";
import { API_ENDPOINT, getBackendErrorCode, hasBackendResponseErrors } from "../../utils/api";
import axios from "axios";
import { getErrorMessage } from "../../utils/errors.utils";
import Loader from "../../UI/Loader/Loader";
import StarsFeedback from "./StarsFeedback";
import { updateFeedbackInfo_inLO } from "../../utils/utils.store";
import { FeedbackStateEnum } from "../../types/commonTypes";
import { useAppSelector } from "../../store/hooks";
import { selectAuth } from "../../store";
import FeedbackSuccessMsgCmp from "./FeedbackSuccessMsgCmp";

interface FeedbackModalProps {
  setModalOpen: Function;
  isOpen: boolean;
}

function FeedbackModal(props: FeedbackModalProps) {
  const { isOpen, setModalOpen } = props;
  // const { setCurrestStep, setEmail, closeModal, userType } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showThankYouMsg, setShowThankYouMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState(0);

  const authState = useAppSelector(selectAuth);
  const authData = authState?.userAuthData

  const submitRequest = async () => {
    try {
      setLoading(true);
      setError(false);

      let requestURL = `${API_ENDPOINT}/api/feedback`;
      const body = {
        feedbackValue: value,
        description: description,
        feedbackDate: (new Date()).toISOString(),
        userType: authData?.token ? authData?.userType : null,
        email: authData?.token ? authData?.email : null,
      }
      const res = await axios.post(requestURL, body);
      if (hasBackendResponseErrors(res)) {
        const errCode = getBackendErrorCode(res);
        setErrorMsg(getErrorMessage(errCode));
        setError(true);
      } else {
        setShowThankYouMsg(true)
        updateFeedbackInfo_inLO({ state: FeedbackStateEnum.SUBMITTED })
      }
    } catch (err) {
      setErrorMsg(getErrorMessage());
      setError(true);
    } finally {
      setDescription(description);
      setLoading(false);
    }
  };

  const refuseFeedbackHandler = () => {
    updateFeedbackInfo_inLO({ state: FeedbackStateEnum.REFUSED })
    setModalOpen(false)
  }

  const modalTitle = showThankYouMsg ? '' : 'Il tuo parere conta!'
  
  return (
    <>
      {loading && <Loader />}
      {error && <p>{errorMsg}</p>}

      {isOpen && <ModalSlide
        title={modalTitle}
        modalClass="feedback-modal"
        closeModal={() => setModalOpen(false)}
        cancelModal={() => setModalOpen(false)}
      >
        <>
          {!showThankYouMsg &&
            <>
              <p className="modal-description">
                Dicci quanto sei soddisfatto dell'applicativo e condividi con noi i tuoi suggerimenti per rendere l'app ancora migliore
              </p>
              <StarsFeedback value={value} setValue={setValue} />
              <div className="input-wrapper">
                <textarea
                  className="text-input-modal"
                  name="notes"
                  placeholder={"Scrivi qui i tuoi suggerimenti..."}
                  onChange={(e) => setDescription(e.target.value)} />
              </div>

              <button className="ada-btn w-100 margin-y-medium"
                onClick={submitRequest}>
                Invia Opinione
              </button>

              <p
                className="modal-description cancel-cta"
                onClick={refuseFeedbackHandler}>
                  <span className="txt">Salta questo passaggio</span></p>

              {error && <p className="modal-description modal-error-msg">Si è verificato un errore nell'invio del feedback. Ti chiediamo gentilmente di riprovare.</p>}
            </>
          }

          {showThankYouMsg &&
            <FeedbackSuccessMsgCmp
              closeHandler={() => setModalOpen(false)} />
          }
        </>
      </ModalSlide>
      }
    </>
  );
}

export default FeedbackModal;
