import { useHistory } from "react-router-dom";

import WhiteHeader from "../../components/WhiteHeader/WhiteHeader";
import useScrollToTop from "../../hooks/useCrollToTop";
import { HazardCodeEnum } from "../../types/commonTypes";
import { hazardTranslator } from "../../utils/utils";

import "./Infopage.scss";

const InfoGrandine = () => {
  const history = useHistory();
  const goToDrawAreaHandler = () => {
    history.goBack();
  };
  useScrollToTop();

  return (
    <div className="info-page">
      <WhiteHeader title={`Info ${hazardTranslator(HazardCodeEnum.HAIL)}`} goBackCb={goToDrawAreaHandler} />
      <div className="text-container  max-1480-container">
        <h3>Sorgente dati</h3>
        <p>
          I dati utilizzati per calcolare l’indice di frequenza di eventi grandinigeni sono quelli del data- set LAMPINET di Aereonautica Militare disponibile anche attraverso il
          portale del progetto MISTRAL (
          <a href="https://www.mistralportal.it/catalog/dataset/lampinet-fulminazioni" target="_blank" rel="noreferrer">
            LINK
          </a>
          ). Il dataset contiene i report delle posizioni ed intensità delle scariche elettriche acquisite dalla rete di fulminazione LAMPINET, del Servizio Meteorologico
          dell&#39;Aeronautica, dal 2014 ad oggi su tutto il territorio nazionale.
        </p>
        <p>
          La letteratura indica come possibile proxy del verificarsi di eventi grandinigeni l’aumento improvviso di frequenza del numero di lampi in un&#39;area assegnata (Chronis
          et al, 2015, Farnell et al 2016, Farnell et al 2020).
        </p>
        <p>
          Per ottenere un indice che possa essere considerato un proxy abbastanza fedele del verificarsi di eventi grandinigeni il data-set originale è stato trasformato in una
          griglia regolare con risoluzione 20km in cui è stata riportato il numero di scariche elettriche osservate nel corso di un intervallo di 15 minuti dal 01/01/2015 al
          31/12/2020. Si assume che in un&#39;ora assegnata si sia verificato un evento grandinigeno se il numero di lampi osservati in un punto di griglia è aumentato di almeno 20
          unità, almeno una volta, da un quarto d’ora a quello successivo nell’arco dell’ora.
        </p>
        <h3>Periodo di riferimento</h3>
        <p>L’indice di pericolosità è stato calcolato sul periodo dal 01/01/2015 al 31/12/2020. </p>
        <h3>Indici</h3>
        <p>
          La letteratura indica come possibile proxy del verificarsi di eventi grandinigeni l’aumento improvviso di frequenza del numero di lampi in un&#39;area assegnata (Chronis
          et al, 2015, Farnell et al 2016, Farnell et al 2020).
        </p>
        <p>
          Per ottenere un indice che possa essere considerato un proxy abbastanza fedele del verificarsi di eventi grandinigeni il data-set originale è stato trasformato in una
          griglia regolare con risoluzione 20km in cui è stata riportato il numero di scariche elettriche osservate nel corso di un intervallo di 15 minuti dal 01/01/2015 al
          31/12/2020. Si assume che in un&#39;ora assegnata si sia verificato un evento grandinigeno se il numero di lampi osservati in un punto di griglia è aumentato di almeno 20
          unità, almeno una volta, da un quarto d’ora a quello successivo nell’arco dell’ora.
        </p>
        <p>
          Un confronto con un data-set di osservazioni di eventi grandinigeni che hanno causato danni ottenuto in parte da UNIPOL, in parte da ESSL (
          <a href="https://www.essl.org/cms/" target="_blank" rel="noreferrer">
            LINK
          </a>
          ), ha evidenziato che questo indice sottostima leggermente la frequenza media del verificarsi di tali eventi nei mesi da aprile a settembre con una percentuale di eventi
          mancati tra 10 e 30 %. La percentuale di eventi mancati è maggiore nei mesi rimanenti raggiungendo percentuali di eventi mancati superiori all’80% nei mesi da dicembre a
          marzo. La percentuale di falsi allarmi del proxy è stata individuata confrontando l’indice orario con stime di precipitazioni da radar per l’anno 2019 nei mesi estivi, da
          giugno ad agosto, usando come proxy della grandine intensità orarie stimate . La percentuale di falsi allarmi in questo caso è stata stimata essere pari al 5,8% degli
          eventi considerati. I risultati così ottenuti sono stati considerati soddisfacenti.
        </p>
        <p>A partire da questo indice sono state ottenute mappe di frequenza di grandine stagionali medie sul periodo 2015-2020 per ogni punto di griglia.</p>
        <h3>Classificazione</h3>
        <p>Sono state definite 3 classi di frequenza associate a rischio basso, medio ed alto di danni dovuti a grandine, individuando 2 soglie:</p>
        <ul>
          <li>una soglia tra classe di rischio bassa e media pari a 0,5, equivalente ad un evento grandinigeno ogni 2 anni.</li>
          <li>una soglia tra classe di rischio media e alta pari a 3 eventi grandinigeni per la stagione considerata.</li>
        </ul>
        <h3>Limitazioni</h3>
        <p>
          Il dataset Lampinet presenta limitazioni nella individuazione dell’effettivo numero di lami, soprattutto nei mesi invernali. I risultati per la stagione invernale, quando
          si osserva un numero limitato, ma pur sempre consistente di eventi, soprattutto nelle regioni meridionali. In questi mesi si pensa quindi che il proxy utilizzato sia
          affetto da una netta sottostima del numero di eventi. Per altro la caratterizzazione di questi eventi non è coperta in letteratura e gli studi si concentrano tutti nei 6
          mesi più caldi, caratterizzati da maggiore frequenza di eventi convettivi di tipologia classica.
        </p>
      </div>
    </div>
  );
};

export default InfoGrandine;
