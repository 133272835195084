import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import { ProducerType } from '../../types/personalAreaType'
import { AreaItemType } from '../../types/regionalHazardType'
import ClimateContainer from '../ClimateContainer/ClimateContainer'

import './AdaAccordion.scss'

interface AdaAccordionProps {
    title: string,
    data: ProducerType,
    isOpen: boolean,
    index: number,
    openAccordion: Function
}

const AdaAccordion = ({ title, data, isOpen, index, openAccordion }: AdaAccordionProps) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false)

    useMemo(() => setIsAccordionOpen(isOpen), [isOpen])

    return (
        <div className='ada-accordion'>
            <div className='ada-accordion__header' onClick={() => openAccordion(index)}>
                <p className='ada-accordion__title'>{title}</p>
                <button className='ada-accordion__button'><FontAwesomeIcon className={`ada-accordion__icon ${isAccordionOpen && 'accordion-opened'}`} icon={faPlus} /></button>
            </div>

            {
                isAccordionOpen &&
                <div className="ada-accordion__body">
                    {data.areas.map((el: AreaItemType, i: number) => {
                        return <ClimateContainer key={i} data={el} selectedPlans={data.plans[i]}></ClimateContainer>
                    })}
                </div>
            }
        </div>

    )
}

export default AdaAccordion