import axios from 'axios';
import jwt_decode from "jwt-decode";
import { getLocalStorageToken } from './utils.store';

export interface ServerSuccessResponse {}

export function sendPost(_requestURL: string, _body: any) {
    return axios.post(_requestURL, _body);
}

export function sendGet(_requestURL: string) {
    return axios.get(_requestURL);
}

export function sendDelete(_requestURL: string) {
    return axios.delete(_requestURL);
}

export const getBackendData = (resp: any): any => {
    return resp && resp.data && resp.data.data;
}

export const getBackendFullResponse = (resp: any): any => {
    return resp && resp.data;
}

export const hasBackendResponseErrors = (resp: any) => {
    const respData = resp && resp.data;
    if (!respData || !respData.opResult || resp.error) {
        return true;
    }

    const backendErrors = respData.errors;
    return !!(backendErrors && backendErrors.length);
}

export const getBackendErrorCode = (resp: any) => {
    return resp && resp.data && resp.data.error && resp.data.error.code;
}


export const getValidTokenPresent = () => {
    try {

        const token = getLocalStorageToken();
        if (!token) {
            return;
        }

        let decodedToken = jwt_decode(token);

        let expDate = decodedToken &&  (decodedToken as any).exp;

        if (!expDate) {
            return;
        }

        const dateNow = Math.floor(Date.now() / 1000);

        if (dateNow > expDate) {
            return;
        }

        return token;
    } catch (err) {
        console.error('invalid token found in local storage');
    }
}

export const isAuthenticated = () => {
    return !!getValidTokenPresent()
}

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3006';
export const MOCK_API_ENDPOINT = 'http://localhost:3009';


