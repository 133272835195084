import { Store, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension'
import { createRootReducer, rootSaga } from './store'
import { ApplicationState } from './types/commonTypes'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { getValidTokenPresent } from './utils/api'
import { AuthType } from './types/authType'

export const history = createBrowserHistory()

const reHydrateStore = () => {
  let initialState = {};
  if (getValidTokenPresent()) {
    let appState: any = localStorage.getItem('adaApplicationState');
    let appStateJson: any = JSON.parse(appState) // re-hydrate the store
    const authInfoJson = appStateJson.auth && appStateJson.auth.personalData;

    if (authInfoJson) {
      initialState = { auth: new AuthType({ userAuthData: authInfoJson }) };
    }
  }
  return initialState;
}

export default function configureStore(initialState: ApplicationState): Store<ApplicationState> {
  const composeEnhancers = composeWithDevTools({})
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    createRootReducer(history),
    reHydrateStore(),
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  )

  sagaMiddleware.run(rootSaga)
  return store
}

const initialState = window.INITIAL_REDUX_STATE
export const store = configureStore(initialState)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;