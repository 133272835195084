import { action } from 'typesafe-actions'
import { AuthAction } from '../../types/actions/auth-action'

export const loadOrganizationsAct = (params?: any) => action(AuthAction.LOAD_ORGANIZATIONS, params)
export const loadOrganizationsSuccessAct = (resp: any[]) => action(AuthAction.LOAD_ORGANIZATIONS_SUCCESS, resp)
export const loadOrganizationsErrorAct = (err?: string) => action(AuthAction.LOAD_ORGANIZATIONS_ERROR, err)
export const registerUserAct = (params?: any) => action(AuthAction.REGISTER_USER, params)
export const registerUserSuccessAct = (resp: any[]) => action(AuthAction.REGISTER_USER_SUCCESS, resp)
export const registerUserErrorAct = (err?: string) => action(AuthAction.REGISTER_USER_ERROR, err)
export const resendUserRegisterEmailAct = (params?: any) => action(AuthAction.RESEND_USER_REGISTER_EMAIL, params)
export const resendUserRegisterEmailSuccessAct = (resp: any[]) => action(AuthAction.RESEND_USER_REGISTER_EMAIL_SUCCESS, resp)
export const resendUserRegisterEmailErrorAct = (err?: string) => action(AuthAction.RESEND_USER_REGISTER_EMAIL_ERROR, err)
export const activateUserAct = (params?: any) => action(AuthAction.ACTIVATE_USER, params)
export const activateUserSuccessAct = (resp: any[]) => action(AuthAction.ACTIVATE_USER_SUCCESS, resp)
export const activateUserErrorAct = (err?: string) => action(AuthAction.ACTIVATE_USER_ERROR, err)
export const loginAct = (params?: any) => action(AuthAction.LOGIN, params)
export const loginSuccessAct = (resp: any[]) => action(AuthAction.LOGIN_SUCCESS, resp)
export const loginErrorAct = (err?: string) => action(AuthAction.LOGIN_ERROR, err)
export const registerOrgAct = (params?: any) => action(AuthAction.REGISTER_ORG, params)
export const registerOrgSuccessAct = () => action(AuthAction.REGISTER_ORG_SUCCESS)
export const registerOrgErrorAct = () => action(AuthAction.REGISTER_ORG_ERROR)
export const logoutAct = (params?: any) => action(AuthAction.LOGOUT, params)
