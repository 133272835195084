import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { selectPersonalArea } from '../../store';
import { useAppSelector } from '../../store/hooks';
import { AreaItemType } from '../../types/regionalHazardType';
import Loader from '../../UI/Loader/Loader';
import AddNewZone from '../AddNewZone/AddNewZone';
import ZoneCard from '../ZoneCard/ZoneCard'
import './Areas.scss'

const Areas = () => {
    const personalArea = useAppSelector(selectPersonalArea)
    const { areas } = personalArea;
    const N_SLIDES = ((areas && areas.length) || 0);
    const areasIsLoading = personalArea?.areasMetadata?.isLoading
    const deleteIsLoading = personalArea?.deleteAreaMetadata?.isLoading
    const noZones = !areasIsLoading && !deleteIsLoading && areas?.length === 0
    const noZonesClass = noZones ? 'no-areas' : ''
    const hasSemiTransparentBkg = !!deleteIsLoading

    return (
        <div className={`areas-wrapper ${noZonesClass}`}>
            <CarouselProvider
                naturalSlideWidth={25}
                naturalSlideHeight={100}
                totalSlides={N_SLIDES}
                isIntrinsicHeight={true}
                className="personal-area__slider"
            >
                <Slider>
                    {areas.map((area: AreaItemType, i) => (
                        <Slide index={i} key={area.id} className={"area-slide-item"}>
                            <ZoneCard area={area} index={i} />
                        </Slide>
                    ))}
                    <Slide index={areas.length + 1} className={"area-slide-item"}>
                        <AddNewZone url='/area-input-page' />
                    </Slide>
                </Slider>
            </CarouselProvider>
            {(areasIsLoading || deleteIsLoading) && <Loader hasSemiTransparentBkg={hasSemiTransparentBkg} />}
        </div>
    )
}

export default Areas