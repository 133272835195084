import React, { createContext } from 'react';

const initialAppSettings = {
    minLoadingTime: 800
};

export const AppSettingsContext = createContext({
    initialAppSettings
});

const SettingsContextProvider = (props: any) => {

    return (
        <AppSettingsContext.Provider value={{
            initialAppSettings
        }}>
            {props.children}
        </AppSettingsContext.Provider>)
}

export default SettingsContextProvider;
