import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectRegionalHazard } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userInputSetStepAct } from "../../store/regionalHazardArea/actions";
import { UserInputSteps } from "../../types/regionalHazardType";
import GeolocationBtn from "./GeolocationBtn";
import "./GoToSearchLocation.scss";

const GoToSearchLocation = () => {
  const hazardState = useAppSelector(selectRegionalHazard);
  const { userInput } = hazardState;
  const dispatch = useAppDispatch();

  const sectorsPresent = !!(userInput.sectors && userInput.sectors.length > 0);
  const btnDisabled = !(sectorsPresent && userInput.privacyChecked);

  const goToLocationHandler = () => {
    dispatch(userInputSetStepAct(UserInputSteps.LOCATION_STEP));
  };

  return (
    <>
      <div className="go-to-location-container">
        <GeolocationBtn disabled={btnDisabled} />
        <button className={`ada-btn icon-text-btn find-zone-btn`} disabled={btnDisabled} onClick={goToLocationHandler}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <span>Cerca la tua zona</span>
        </button>
      </div>
    </>
  );
};

export default GoToSearchLocation;
