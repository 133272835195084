import { NavLink } from "react-router-dom";

import arrowRight from "../../assets/images/arrowRight.png";
import "./Cards.scss";

export type CardGreenFooterProps = {
  children?: any;
  linkLabel?: string;
  navTo: string;
  additionalClass?: string;
  header?: {
    title: string;
    navToHaeder?: string;
    additionalClass?: string;
    icon?: {
      img: string;
      altText: string;
    };
  };
};

const CardGreenFooter = (props: CardGreenFooterProps) => {
  const header = props.header ? (
    <div className="card-green-header">
      <p>{props.header.title}</p>
      {props.header.icon && props.header?.navToHaeder && (
        <NavLink to={props.header.navToHaeder} className="header-link">
          <img src={props.header.icon.img} alt={props.header.icon.altText} />
        </NavLink>
      )}
    </div>
  ) : null;

  return (
    <div className={`card-green-footer-container ${props.additionalClass ? props.additionalClass : ""}`}>
      {header}
      <div className="card-green-body">{props.children}</div>
      {props.linkLabel && (
        <NavLink to={props.navTo} className="green-footer">
          <p>{props.linkLabel}</p>
          <img src={arrowRight} alt="Freccia per andare alla sezione" />
        </NavLink>
      )}
    </div>
  );
};

export default CardGreenFooter;
