import { useAppDispatch } from "../../store/hooks";
import { userInputMapSetViewportAct, userInputSetLocationAct, userInputSetStepAct } from "../../store/regionalHazardArea/actions";
import { GeoLocation } from "../../types/commonTypes";
import { ILocation, UserInputSteps, ViewPortType } from "../../types/regionalHazardType";
import { mapboxGeorevertedFirstAddress } from "../../utils/utils";
import locationIcon from "../../assets/images/location.png";

import "./GeolocationBtn.scss";
import { getCapFromSuggestion } from "../../utils/map.utils";
const accessToken = process.env.REACT_APP_MAP_KEY;

interface GeolocationBtnProps {
  disabled?: boolean;
}

const GeolocationBtn = (props: GeolocationBtnProps) => {
  const dispatch = useAppDispatch();

  const btnDisabled = !!props.disabled;

  const goToLocationHandler = () => {
    dispatch(userInputSetStepAct(UserInputSteps.LOCATION_STEP));
  };

  const geoRevert = async (coords: GeoLocation) => {
    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.longitude}%2C%20${coords.latitude}.json?access_token=${accessToken}&language=it`;
      const response = await fetch(endpoint);
      const results = await response.json();
      const cap = getCapFromSuggestion(results?.features)
      const address = mapboxGeorevertedFirstAddress(results);
      return {location: address, cap};
      //   setSuggestions(results?.features);
    } catch (error) {
      console.log("Error fetching data, ", error);
    }
  };

  const positionCB = async (position: any) => {
    const viewport = new ViewPortType(position && position.coords);
    const revertedLocation = await geoRevert(position.coords);

    if (revertedLocation) {
      dispatch(userInputSetLocationAct({ location: revertedLocation.location, cap: revertedLocation.cap }));
      viewport.zoom = 16;
      dispatch(userInputMapSetViewportAct(viewport));
    } else {
      alert("Errore nell'operazione di identificazione della posizione corrente");
    }
    goToLocationHandler();
  };

  const geroLocalizeHandler = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(positionCB, () => alert("Servizio di localizzazione non permesso"));
    } else {
      alert("Servizio di localizzazione non disponibile in questo browser");
    }
  };

  return (
    <>
      <button className={`ada-btn light-btn geoloc-btn`} disabled={btnDisabled} onClick={geroLocalizeHandler}>
        <img src={locationIcon} alt="icona localizzazione" />
      </button>
    </>
  );
};

export default GeolocationBtn;
