import { LevelEnum } from '../../types/commonTypes'
import DangerInfo from '../DangerInfo/DangerInfo'
import './Weather.scss'

interface WeatherProps {
    data: any,
    title: string
}

const Weather = ({ title, data }: WeatherProps) => {
    const levelType = (level: string): LevelEnum => {
        switch (level) {
            case "LOW":
                return LevelEnum.LOW

            case "MEDIUM":
                return LevelEnum.MEDIUM

            case "HIGH":
                return LevelEnum.HIGH

            default: return LevelEnum.LOW
        }
    }

    return (
        <div className="weather">
            <h3 className='weather__title'>{title}</h3>
            {data?.info.map((el: any) => {
                return <div className='weather__row'>
                    <p className='weather__season'>{el.label}</p> <div className='weather__danger-info-container'><DangerInfo level={levelType(el.value)}></DangerInfo></div>
                </div>
            })}
        </div>
    )
}

export default Weather