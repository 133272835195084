import { useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import RegionalCarousel from "../components/RegionalCarousel/RegionalCarousel";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import { selectRegionalHazard } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { resetStateAct, userInputSetDrawAreaAct, userInputSetStepAct } from "../store/regionalHazardArea/actions";
import { UserInputSteps } from "../types/regionalHazardType";
import "./RegionalDataPage.scss";

const RegionalDataPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const reagionalHazardState = useAppSelector(selectRegionalHazard);
  const { regionalHazardData } = reagionalHazardState;
  const { phenomena } = regionalHazardData;

  useEffect(() => {
    if (phenomena.length === 0) {
      dispatch(resetStateAct());
      history.push(`/`);
    }
  }, [phenomena, dispatch, history]);

  const goToDrawAreaHandler = () => {
    dispatch(userInputSetStepAct(UserInputSteps.DRAW_AREA_STEP));
    dispatch(userInputSetDrawAreaAct(null));
    history.goBack();
  };

  return (
    <>
      <WhiteHeader title="I dati della tua regione" goBackCb={goToDrawAreaHandler} />
      <div className="page-cmp page-with-header regional-data-page">
        <p className="page-title">Ecco alcuni dati della tua regione</p>
        <p className="info">Prima di visualizzare i dati della tua zona, eccoti riportati alcuni dati dall’analisi territoriale della tua regione:</p>

        <RegionalCarousel />
        <div className="bottom-box">
          <NavLink to="/regional-report" className="ada-btn w-100 max-350">
            Scopri il report della tua zona
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default RegionalDataPage;
