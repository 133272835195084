export enum AuthAction {
    LOAD_ORGANIZATIONS = '@@personalArea/LOAD_ORGANIZATIONS',
    LOAD_ORGANIZATIONS_SUCCESS = '@@personalArea/LOAD_ORGANIZATIONS_SUCCESS',
    LOAD_ORGANIZATIONS_ERROR = '@@personalArea/LOAD_ORGANIZATIONS_ERROR',
    REGISTER_USER = '@@personalArea/REGISTER_USER',
    REGISTER_USER_SUCCESS = '@@personalArea/REGISTER_USER_SUCCESS',
    REGISTER_USER_ERROR = '@@personalArea/REGISTER_USER_ERROR',
    RESEND_USER_REGISTER_EMAIL = '@@personalArea/RESEND_USER_REGISTER_EMAIL',
    RESEND_USER_REGISTER_EMAIL_SUCCESS = '@@personalArea/RESEND_USER_REGISTER_EMAIL_SUCCESS',
    RESEND_USER_REGISTER_EMAIL_ERROR = '@@personalArea/RESEND_USER_REGISTER_EMAIL_ERROR',
    ACTIVATE_USER = '@@personalArea/ACTIVATE_USER',
    ACTIVATE_USER_SUCCESS = '@@personalArea/ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_ERROR = '@@personalArea/ACTIVATE_USER_ERROR',
    LOGIN = '@@personalArea/LOGIN',
    LOGIN_SUCCESS = '@@personalArea/LOGIN_SUCCESS',
    LOGIN_ERROR = '@@personalArea/LOGIN_ERROR',
    REGISTER_ORG = '@@personalArea/REGISTER_ORG',
    REGISTER_ORG_SUCCESS = '@@personalArea/REGISTER_ORG_SUCCESS',
    REGISTER_ORG_ERROR = '@@personalArea/REGISTER_ORG_ERROR',
    LOGOUT = '@@personalArea/LOGOUT'
}