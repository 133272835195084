import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectAuth } from "../../store";
import { OrganizationType } from "../../types/authType";
import { loadOrganizationsAct } from "../../store/auth/actions";
import Loader from "../../UI/Loader/Loader";
import "./OrganizationsSelection.scss";
import { toast } from "react-toastify";

type OrganizationsSelectionProps = {
  organizations: string[];
  updateLocalOrganizations: (organizations: string[]) => void;
};

const OrganizationsSelection = (props: OrganizationsSelectionProps) => {
  const dispatch = useAppDispatch();
  const { organizations, updateLocalOrganizations } = props;
  const authState = useAppSelector(selectAuth);
  const { organizationsData } = authState;
  const ALL_ORGANIZATIONS = organizationsData?.organizations || [];

  const reloadOrganizationsHandler = () => {
    dispatch(loadOrganizationsAct());
  };

  if (organizationsData?.metadata.error) {
    toast.error("Errore generico", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      onClose: reloadOrganizationsHandler,
    });
  }

  const isItemSelected = (si: string) => organizations.find((ls) => ls === si);

  const toggleSectorHandler = (si: string) => {
    if (isItemSelected(si)) {
      updateLocalOrganizations(organizations.filter((ls) => ls !== si));
    } else {
      updateLocalOrganizations([...organizations, si]);
    }
  };

  return (
    <>
      <div className="organizations-selection-cmp">
        <p className="organizations-info">
          Le tue organizzazioni potranno vedere i rischi delle tue zone salvate.
        </p>
        {ALL_ORGANIZATIONS.map((org: OrganizationType) => {
          const isItemSelectedClass = isItemSelected(org.id)
            ? "is-selected"
            : "";
          return (
            <div
              className={`sector-item ${isItemSelectedClass}`}
              key={org.id}
              onClick={() => toggleSectorHandler(org.id)}
            >
              <button className={`ada-ck ${isItemSelectedClass}`}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
              <div className="item-name">{org.name}</div>
            </div>
          );
        })}
        {organizationsData?.metadata.isLoading && <Loader />}
      </div>
    </>
  );
};

export default OrganizationsSelection;
