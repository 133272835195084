import { action } from 'typesafe-actions'
import { E3ciAction } from '../../types/actions/e3ci-action'
import { E3ciItemType } from '../../types/e3ciType'

export const loadE3ciAct = () => action(E3ciAction.LOAD_E3CI)
export const loadE3ciSuccessAct = (resp: any[]) => action(E3ciAction.LOAD_E3CI_SUCCESS, resp)
export const loadE3ciErrorAct = (err?: string) => action(E3ciAction.LOAD_E3CI_ERROR, err)
export const setCurrentE3ciAct = (selectedItem: E3ciItemType) => action(E3ciAction.SET_CURRENT_E3CI, selectedItem)
export const setReferencePeriodDateaAct = (newDate: Date) => action(E3ciAction.SET_REFERENCE_PERIOD_DATE, newDate)

