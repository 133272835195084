import { toast } from "react-toastify";
import { AuthAction } from "../../types/actions/auth-action";
import {
  AuthType,
  LoginType,
  OrganizationsDataType,
  RegistrationType,
  UserDataType,
} from "../../types/authType";
import { FeedbackStateEnum, MetadataType } from "../../types/commonTypes";
import {
  clearLocalStorageAuthData,
  saveAuthInfoInLocalStorage,
  updateFeedbackInfo_inLO,
} from "../../utils/utils.store";

export const initialState: AuthType = new AuthType();

const reducer = (state = initialState, action: any): AuthType => {
  let newState = new AuthType({ ...state });
  let newRegistrationData;
  let newOrganizationsData;
  let newLoginData;
  let newUserAuthData;

  switch (action.type) {
    case AuthAction.LOAD_ORGANIZATIONS:
      newOrganizationsData = new OrganizationsDataType(
        state?.organizationsData,
        {
          isLoading: true,
          errorCode: null,
          error: false,
        }
      );
      newState.organizationsData = newOrganizationsData;
      return newState;
    case AuthAction.LOAD_ORGANIZATIONS_SUCCESS:
      newOrganizationsData = new OrganizationsDataType(action.payload, {
        isLoading: false,
        errorCode: null,
        error: false,
      });
      newState.organizationsData = newOrganizationsData;
      return newState;

    case AuthAction.LOAD_ORGANIZATIONS_ERROR:
      newOrganizationsData = new OrganizationsDataType(
        state?.organizationsData,
        {
          isLoading: false,
          errorCode: action.payload,
          error: true,
        }
      );
      newState.organizationsData = newOrganizationsData;
      return newState;

    case AuthAction.REGISTER_USER:
      const { registrationData } = action.payload;
      newRegistrationData = new RegistrationType(registrationData, {
        isLoading: true,
        errorCode: null,
        error: false,
      });
      newState.registrationData = newRegistrationData;

      return newState;
    case AuthAction.REGISTER_USER_SUCCESS:
      newRegistrationData = new RegistrationType(state?.registrationData, {
        isLoading: false,
        errorCode: null,
        error: false,
      });
      newState.registrationData = newRegistrationData;
      return newState;

    case AuthAction.REGISTER_USER_ERROR:
      newRegistrationData = new RegistrationType(state?.registrationData, {
        isLoading: false,
        errorCode: action.payload,
        error: true,
      });
      newState.registrationData = newRegistrationData;
      return newState;

    case AuthAction.RESEND_USER_REGISTER_EMAIL:
      if (newState.registrationData) {
        newState.registrationData.resendEmailMetadata = new MetadataType({
          isLoading: true,
          errorCode: null,
          error: false,
        });
      }

      return newState;
    case AuthAction.RESEND_USER_REGISTER_EMAIL_SUCCESS:
      if (newState.registrationData) {
        newState.registrationData.resendEmailMetadata = new MetadataType({
          isLoading: false,
          errorCode: null,
          error: false,
        });
      }
      return newState;

    case AuthAction.RESEND_USER_REGISTER_EMAIL_ERROR:
      if (newState.registrationData) {
        newState.registrationData.resendEmailMetadata = new MetadataType({
          isLoading: false,
          errorCode: action.payload,
          error: true,
        });
      }
      return newState;

    case AuthAction.ACTIVATE_USER:
      newState.activateUserData.metadata = new MetadataType({
        isLoading: true,
        errorCode: null,
        error: false,
      });
      return newState;
    case AuthAction.ACTIVATE_USER_SUCCESS:
      newUserAuthData = new UserDataType(action.payload?.user);
      newState.userAuthData = newUserAuthData;

      newState.activateUserData.metadata = new MetadataType({
        isLoading: false,
        errorCode: null,
        error: false,
      });
      return newState;

    case AuthAction.ACTIVATE_USER_ERROR:
      newState.activateUserData.metadata = new MetadataType({
        isLoading: false,
        errorCode: action.payload,
        error: true,
      });
      return newState;

    case AuthAction.LOGOUT:
      newLoginData = new LoginType();
      newUserAuthData = new UserDataType();

      newState.loginData = newLoginData;
      newState.userAuthData = newUserAuthData;
      /* Clear local storage */
      clearLocalStorageAuthData();

      return newState;

    case AuthAction.LOGIN:
      const { loginData } = action.payload;
      newLoginData = new LoginType(loginData, {
        isLoading: true,
        errorCode: null,
        error: false,
      });
      newState.loginData = newLoginData;
      newState.userAuthData = new UserDataType();

      return newState;
    case AuthAction.LOGIN_SUCCESS:
      newLoginData = new LoginType(state?.loginData, {
        isLoading: false,
        errorCode: null,
        error: false,
      });
      newUserAuthData = new UserDataType(action.payload?.user);
      if (newUserAuthData.feedbackState === FeedbackStateEnum.SUBMITTED) {
        updateFeedbackInfo_inLO({ state: newUserAuthData.feedbackState })
      }

      newState.loginData = newLoginData;
      newState.userAuthData = newUserAuthData;

      /* Save to local storage */
      saveAuthInfoInLocalStorage(newUserAuthData);

      return newState;

    case AuthAction.LOGIN_ERROR:
      newLoginData = new LoginType(state?.loginData, {
        isLoading: false,
        errorCode: action.payload,
        error: true,
      });
      newState.loginData = newLoginData;
      return newState;

    case AuthAction.REGISTER_ORG:
      if (newState.registerOrgData) {
        newState.registerOrgData.metadata = new MetadataType({
          isLoading: true,
          errorCode: null,
          error: false,
        });
      }

      return newState;

    case AuthAction.REGISTER_ORG_SUCCESS:
      if (newState.registerOrgData) {
        newState.registerOrgData.metadata = new MetadataType({
          isLoading: false,
          errorCode: null,
          error: false,
        });
      }

      return newState;

    case AuthAction.REGISTER_ORG_ERROR:
      if (newState.registerOrgData) {
        newState.registerOrgData.metadata = new MetadataType({
          isLoading: false,
          errorCode: null,
          error: true,
        });
      }

      return newState;

    default:
      return newState;
  }
};

export { reducer as AuthReducer };
