import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectPersonalArea } from "../../store";
import { useAppSelector } from "../../store/hooks";
import "./RegionalClimateSection.scss";
import infoCircle from "../../assets/images/info-circled.png";
import ExtremeTCard from "./ExtremeTCard";
import RainCard from "./RainCard";

const RegionalClimateSection = () => {
  const personalArea = useAppSelector(selectPersonalArea)
  const { regionalClimateData } = personalArea
  const extremeT = regionalClimateData.extremeT
  const rain = regionalClimateData.rain

  return (
    <div className="regional-climate-section">
      <div className="section-title">
        <h3 className="heading-2 color-dark padding-y-medium">Analisi climatica regionale</h3>
        {/* <button>
          <FontAwesomeIcon icon={faBars} />
          Filtra</button> */}
      </div>
      <div className="section-body">
        <div className="dual-card-box">
          <ExtremeTCard
            extremeT={extremeT}
            navTo="/regional-solutions/temperature-estreme-expanded"
            linkLabel={extremeT?.solutions && extremeT.solutions.length > 0 ? "Vedi azioni" : ""}
            additionalClass="extreme-temp"
            header={{
              title: "Temperature medie stagionali",
              navToHaeder: `/info-temperature-estreme`,
              icon: { img: infoCircle, altText: "Icona informazioni" },
            }} />
        </div>
        <div className="dual-card-box">
          <RainCard
            rain={rain}
            navTo="/regional-solutions/precipitazioni-expanded"
            linkLabel={rain?.solutions && rain?.solutions.length > 0 ? "Vedi azioni" : ""}
            additionalClass="rain-cumulative"
            header={{
              title: "Precipitazioni medie umulate",
              navToHaeder: `/info-precipitazioni`,
              icon: { img: infoCircle, altText: "Icona informazioni" },
            }} />
        </div>
      </div>
    </div>
  );
};

export default RegionalClimateSection;
