import { useState } from "react";

import ModalSlide from "../ModalSlide/ModalSlide";
import PersonalAreaDrawer from "../../components/LeftMenuDrawer/PersonalAreaDrawer.tsx";

import user from "../../assets/images/User.png";
import adaLogo from "../../assets/images/ada-logo.png";
import hamMenu from "../../assets/images/hamburger-menu.png";
import "./Header.scss";
import { useHistory } from "react-router-dom";
import NavigationDrawer from "../../components/RightMenuDrawer/NavigationDrawer";
import { isAuthenticated } from "../../utils/api";

const Header = () => {
  const [userAreaOpen, setUserAreaOpen] = useState(false);
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  let history = useHistory();

  const ToggleUserAreaHandler = (value: boolean) => {
    setUserAreaOpen(value);
  };

  const ToggleMenuHandler = (value: boolean) => {
    setNavMenuOpen(value);
  };

  const modalUserArea = (
    <ModalSlide
      title="Area personale"
      closeModal={() => {
        ToggleUserAreaHandler(false);
      }}
      type="left"
    >
      <PersonalAreaDrawer />
    </ModalSlide>
  );

  const modalNavMenu = (
    <ModalSlide
      title="Menu"
      closeModal={() => {
        ToggleMenuHandler(false);
      }}
      type="right"
    >
      <NavigationDrawer />
    </ModalSlide>
  );

  const goToHomeHandler = () => {
    history.push(`/`);
  };

  const hideUserAuthClass = isAuthenticated() ? 'hide-element' : ''

  return (
    <header className="header-cmp app-header">
      <div className={hideUserAuthClass} style={{ width: "58px " }}>
        <button
          className="ada-btn transparent-btn user-area-btn"
          onClick={() => {
            ToggleUserAreaHandler(true);
          }}
        >
          <img className="userIcon" src={user} alt="User icon" />
        </button>
      </div>

      <button
        className="ada-btn transparent-btn home-btn"
        onClick={() => {
          goToHomeHandler();
        }}
      >
        <svg width="80" height="48" viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1770_2754)">
            <path
              d="M50.2693 36.3375C39.2885 45.6779 22.2856 45.4319 11.6182 35.5532L14.6665 38.3767C28.6845 51.3749 51.3215 51.2858 65.2275 38.1842L70.5556 33.1646L70.5183 33.079C67.7655 35.3185 64.4293 36.8037 60.864 37.3768C57.2986 37.9499 53.6374 37.5895 50.2693 36.3339"
              fill="#80B85A"
            />
            <path
              d="M6.83484 31.1253C9.89296 33.9341 13.9751 35.4955 18.217 35.4789C22.4589 35.4622 26.5275 33.8689 29.5614 31.0362L35.4267 24.9756H0.443359L6.83484 31.1253Z"
              fill="#2B844C"
            />
            <path
              d="M62.2911 24.9684H35.4268L43.7062 32.2982L43.7323 32.3196C43.9811 32.543 44.2298 32.7605 44.4786 32.972C44.6726 33.1324 44.8741 33.2893 45.0718 33.4426L45.2397 33.578C45.4822 33.7599 45.7248 33.9345 45.9859 34.1021L46.0382 34.1413C47.3122 35.008 48.6901 35.7261 50.1425 36.2804L50.2805 36.3374C50.6536 36.0344 50.9857 35.7206 51.329 35.3998L62.2911 24.9684Z"
              fill="#5AA758"
            />
            <path
              d="M50.0077 13.6781C50.3809 13.9776 50.7167 14.2877 51.0637 14.6086L59.9737 22.8724L62.2124 24.9687H79.1444L71.3089 17.6389C68.5319 15.0858 65.042 13.3538 61.259 12.6511C57.4761 11.9484 53.5612 12.3051 49.9854 13.6781"
              fill="#89BBE3"
            />
            <path
              d="M29.3561 19.1362C26.3004 16.328 22.2208 14.7662 17.9811 14.7815C13.7414 14.7968 9.67425 16.388 6.64082 19.2182L0.443359 24.9687H35.4267L29.3561 19.1362Z"
              fill="#5EA7DC"
            />
            <path
              d="M29.3559 19.1361L35.4265 24.9686L43.5082 17.7636C45.3685 16.008 47.577 14.625 50.0042 13.6958C38.9488 4.42667 21.9421 4.8117 11.3717 14.7653L6.64062 19.2181C9.67406 16.3879 13.7412 14.7967 17.9809 14.7814C22.2206 14.7661 26.3002 16.3279 29.3559 19.1361Z"
              fill="#7FBCE7"
            />
            <path
              d="M50.0081 13.6781C47.5778 14.6116 45.3678 16.0008 43.5085 17.7637L35.4268 24.9687H62.2277L59.989 22.8724L51.0641 14.6086C50.7171 14.2877 50.3663 13.9776 50.0081 13.6781Z"
              fill="#72A5D8"
            />
            <path d="M79.1597 24.9686L71.3243 17.6388L70.9922 17.3429V17.3714L79.1597 24.9686Z" fill="#A2D2F1" />
            <path d="M6.63672 19.2217L6.64045 19.2181L8.37544 17.5817L6.63672 19.2217Z" fill="#A2D2F1" />
            <path
              d="M61.4888 8.89014C55.2103 4.42712 47.5933 2.01682 39.7678 2.01682C31.9424 2.01682 24.3254 4.42712 18.0469 8.89014L18.4573 9.92044C23.5151 7.49191 29.214 6.5539 34.8307 7.22544C40.4474 7.89699 45.7287 10.1478 50.0043 13.6923C53.1716 12.4831 56.6088 12.0636 59.9926 12.473L61.4627 8.90796L61.4888 8.89014Z"
              fill="#A2D2F1"
            />
            <path
              d="M50.25 36.3377L50.112 36.2807C48.6602 35.7253 47.2824 35.0072 46.0077 34.1417L45.9554 34.1024C45.7055 33.9313 45.4629 33.7459 45.2092 33.5748C45.1532 33.532 45.101 33.4857 45.0413 33.4429C44.8436 33.286 44.6421 33.1292 44.4481 32.9687C44.1993 32.7572 43.9506 32.5398 43.7018 32.3163L43.6794 32.295L35.4261 24.9688L29.5644 31.0294C28.9827 31.5746 28.3591 32.0775 27.6989 32.5338L28.8182 35.2326H21.8521L21.7924 35.0793C19.5778 35.5631 17.282 35.6012 15.0511 35.1912C12.8201 34.7812 10.7029 33.9321 8.83411 32.6978L8.77441 32.8618L11.6176 35.557C22.2812 45.4358 39.2879 45.6818 50.2687 36.3413"
              fill="#319C57"
            />
            <path d="M0.450195 24.9758L3.93883 28.3341L0.450195 24.9758Z" fill="#319C57" />
            <path d="M21.852 35.2324H28.8106L27.6912 32.5336C25.9205 33.7557 23.9086 34.6217 21.7773 35.0791L21.852 35.2324Z" fill="white" />
            <path
              d="M11.5986 24.9687L14.8932 15.9241L18.117 24.9687H11.5986ZM18.4565 9.92053C15.8427 11.1756 13.4507 12.814 11.3673 14.7761L8.38237 17.5997L3.96094 28.2949V28.3341L6.85632 31.1255C7.1772 31.4179 8.79652 32.8617 8.79652 32.8617L8.85622 32.6977L9.82632 30.0667H19.837L21.7922 35.0863C23.9234 34.6289 25.9353 33.7629 27.7061 32.5409L18.4565 9.92053Z"
              fill="white"
            />
            <path
              d="M14.3971 11.9063L8.375 17.5819L11.3599 14.7584C13.4469 12.8019 15.8414 11.1696 18.4566 9.92057L18.0462 8.91879C16.7611 9.84049 15.542 10.8433 14.3971 11.9206"
              fill="white"
            />
            <path
              d="M59.3054 30.0668L57.4025 35.2326H51.4886L51.317 35.393C50.9737 35.7139 50.6267 36.0276 50.2686 36.3306C53.6351 37.5886 57.2953 37.9519 60.8606 37.3819C64.4258 36.812 67.763 35.33 70.5176 33.0935L69.3423 30.0775L59.3054 30.0668Z"
              fill="#BBD66F"
            />
            <path d="M79.159 24.9688H74.1182L75.5323 28.4304L79.159 24.9688Z" fill="#BBD66F" />
            <path
              d="M64.3989 15.924L67.6189 24.9686H61.1005L64.3989 15.924ZM74.1298 24.9686L71.0142 17.3715L70.6859 17.0684C67.6924 14.5344 63.9652 12.9272 59.9961 12.4588L50.5898 35.2324H51.4891L62.3094 24.9615L74.1298 24.9686Z"
              fill="white"
            />
            <path
              d="M75.5329 28.4304L74.13 24.9688H62.3022L51.4893 35.2326H57.4032L59.306 30.0597H69.3429L70.5331 33.1149C70.8838 32.8297 71.2271 32.5302 71.5592 32.2165L75.5329 28.4304Z"
              fill="white"
            />
            <path
              d="M64.9436 11.703C63.855 10.6936 62.701 9.75058 61.4886 8.87952H61.4662L59.9961 12.4446C63.9661 12.9138 67.6942 14.5209 70.6896 17.0542L64.9436 11.703Z"
              fill="white"
            />
            <path d="M71.0148 17.3709V17.3425L70.7051 17.0684L71.0148 17.3709Z" fill="white" />
            <path
              d="M47.0082 22.0408C47.0523 20.7711 46.8079 19.5074 46.2919 18.3367C45.8667 17.4145 45.2122 16.6056 44.3852 15.9802C43.5799 15.3935 42.6584 14.969 41.6764 14.7324C40.6513 14.4894 39.5984 14.3696 38.5422 14.3759H35.4267V29.7912H38.4116C39.5056 29.794 40.5953 29.6622 41.654 29.3991C42.6523 29.1569 43.5873 28.7199 44.4002 28.1157C45.2276 27.4791 45.8818 26.6609 46.3068 25.7306C46.8152 24.5631 47.0544 23.3046 47.0082 22.0408ZM53.5303 22.0408C53.5851 24.0992 53.1528 26.1429 52.2654 28.0194C51.4865 29.6151 50.3473 31.0262 48.9298 32.1513C47.5518 33.2311 45.9644 34.0404 44.2584 34.5328C42.5497 35.0383 40.7712 35.2954 38.9825 35.2957H29.3076V8.93918H38.684C40.5138 8.93885 42.3365 9.15803 44.1091 9.59159C45.831 9.99938 47.4499 10.7328 48.8701 11.7484C50.3121 12.8197 51.4637 14.2062 52.2281 15.7912C53.0813 17.4787 53.5092 19.5631 53.5117 22.0443"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1770_2754">
              <rect width="80" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <button
        className="ada-btn transparent-btn side-menu-btn"
        onClick={() => {
          ToggleMenuHandler(true);
        }}
      >
        <img className="menuIcon" src={hamMenu} alt="Menu icon" />
      </button>
      {userAreaOpen && modalUserArea}
      {navMenuOpen && modalNavMenu}
    </header>
  );
};

export default Header;
