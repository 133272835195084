import { faCircleCheck, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import { selectAuth } from "../store";
import { resendUserRegisterEmailAct } from "../store/auth/actions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Loader from "../UI/Loader/Loader";
import ModalSlide from "../UI/ModalSlide/ModalSlide";
import "./Auth.scss";

const RegistrationEmailSent = () => {
  let history = useHistory();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);
  const { registrationData } = authState;
  const [resendModalOpened, setResendModalOpened] = useState(false);

  useEffect(() => {
    localStorage.removeItem("registrationFormData")
  }, [])

  const clickBackHandler = () => {
    history.push(`/`);
  };

  const ToggleOrganizzazioneModal = (e: any, value: boolean) => {
    e && e.preventDefault();
    setResendModalOpened(value);
  };

  const sendAgainRegistrationEmail = () => {
    dispatch(resendUserRegisterEmailAct());
    ToggleOrganizzazioneModal(null, true);
  };

  const sendIsLoading = registrationData?.resendEmailMetadata.isLoading;
  const sentSuccess =
    !registrationData?.resendEmailMetadata.error &&
    !registrationData?.resendEmailMetadata.isLoading;
  const sentError = registrationData?.resendEmailMetadata.error;
  const resendRegistrationEmailModal = (
    <ModalSlide
      closeModal={() => ToggleOrganizzazioneModal(null, false)}
      confirmModal={false}
      cancelModal={() => ToggleOrganizzazioneModal(null, false)}
    >
      <>
        {sentSuccess && (
          <div className="password-step-final">
            <div className="password-step-final__icon">
              <FontAwesomeIcon icon={faCircleCheck} />
            </div>
            <h3 className="password-step-final__title">Email inviata</h3>
            <p className="password-step-final__description margin-y-base">
              Controlla nella tua mail personale.
            </p>
            <button
              style={{ color: "#667085" }}
              className="ada-btn transparent-btn w-100"
              onClick={() => ToggleOrganizzazioneModal(null, false)}
            >
              Chiudi
            </button>
          </div>
        )}
        {sentError && <p>Errore nel reinvio della email. Chiudi e riprova.</p>}
      </>
    </ModalSlide>
  );

  return (
    <>
    {sendIsLoading && <Loader />}

      <div className="page-cmp mail-sent-page">
        <WhiteHeader
          goBackCb={clickBackHandler}
          title={"Registrati"}
          closeBtn={true}
        />

        <div className="body-container">
          <div className="mail-sent-page__icon-wrapper">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
          <h1 className="mail-sent-page__title">
            Abbiamo inviato una mail di verifica
          </h1>
          <p className="mail-sent-page__text">
            Conferma tramite il link che ti abbiamo mandato e torna in app per
            iniziare ad utilizzare ADA tool.
          </p>
          <a
            className="mail-sent-page__send-mail"
            href="javascript:void(0)"
            onClick={sendAgainRegistrationEmail}
          >
            Non hai ricevuto la mail? Invia di nuovo
          </a>
        </div>
        {resendModalOpened && !sendIsLoading && resendRegistrationEmailModal}
      </div>
    </>
  );
};

export default RegistrationEmailSent;
