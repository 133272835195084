import { MetadataType, ValueLabel } from "./commonTypes";
import { AreaItemType } from "./regionalHazardType";
import { AoilHazardDataType, HazardSolutionTableItemType } from "./aoiHazardType";

export const convertLevelsToArray = (levelsObj: any):ValueLabel[] => {
    const retArr: ValueLabel[] = []
    for (const key in levelsObj) {
        retArr.push({label: key, value: levelsObj[key]})
    }
    return retArr
}

export enum LowCaseLevelsEnum {
    low= 'low',
    medium= 'medium',
    high= 'high'
}

export class RisksStatsItem {
    public totalProducers: string = '';
    public elements: RisksStatsItemObj[] = [];
    constructor(input?: any) {
        if (input) {
            this.totalProducers = input.totalProducers;
            this.elements = ((input && input['elements']) || []).map((object: any) => new RisksStatsItemObj(object));
        }
    }
}
export class RisksStatsItemObj {
    public label: string = '';
    public values: LevelsType = new LevelsType();
    constructor(input?: any) {
        if (input) {
            this.label = input.label;
            this.values = new LevelsType(input.values);
        }
    }
}


export class LevelsType {
    public LOW: string = '';
    public MEDIUM: string = '';
    public HIGH: string = '';
    constructor(input?: any) {
        if (input) {
            this.LOW = input.LOW;
            this.MEDIUM = input.MEDIUM;
            this.HIGH = input.HIGH;
        }
    }
}

export class RisksAnalysisStatsType {
    public extremeT: RisksStatsItem | null = null;
    public rain: RisksStatsItem | null = null;
    public wind: RisksStatsItem | null = null;
    public hail: RisksStatsItem | null = null;
    public frost: RisksStatsItem | null = null;
    public drought: RisksStatsItem | null = null;
    constructor(input?: any) {
        if (input) {
            this.extremeT = input.extremeT ? new RisksStatsItem(input.extremeT) : null;
            this.rain = input.rain ? new RisksStatsItem(input.rain) : null;
            this.wind = input.wind ? new RisksStatsItem(input.wind) : null;
            this.hail = input.hail ? new RisksStatsItem(input.hail) : null;
            this.frost = input.frost ? new RisksStatsItem(input.frost) : null;
            this.drought = input.drought ? new RisksStatsItem(input.drought) : null;
        }
    }
}

export class ProducerType {
    public companyName: string = '';
    public areas: AreaItemType[] = [];
    public plans: Array<String> = [];
    constructor(input?: any) {
        if (input) {
            this.plans = input.plans;
            this.companyName = input.companyName;
            this.areas = ((input && input['areas']) || []).map((object: any) => new AreaItemType(object));
        }
    }
}

export class RisksAnalysisDataType {
    public stats: RisksAnalysisStatsType = new RisksAnalysisStatsType();
    public producers: ProducerType[] = [];
    public metadata: MetadataType = new MetadataType();
    constructor(input?: any, _metadata?: any) {
        if (input) {
            this.stats = new RisksAnalysisStatsType(input.stats);
            this.producers = ((input && input['producers']) || []).map((object: any) => new ProducerType(object));
        }
        if (_metadata) {
            this.metadata = new MetadataType(_metadata);
        }
    }
}

export class PersonalAreaType {
    // Result Data
    public areas: AreaItemType[] = [];
    public actions: HazardSolutionTableItemType[] = [];
    public regionalClimateData: AoilHazardDataType = new AoilHazardDataType();
    public risksAnalysisData: RisksAnalysisDataType = new RisksAnalysisDataType();
    public areasMetadata: MetadataType = new MetadataType()
    public addAreaMetadata: MetadataType = new MetadataType()
    public actionsMetadata: MetadataType = new MetadataType()
    public deleteAreaMetadata: MetadataType = new MetadataType()
    public addDeleteActionMetadata: MetadataType = new MetadataType()
    public regionalClimateMetadata: MetadataType = new MetadataType()
    public changePasswordMetadata: MetadataType = new MetadataType()
    public modifyOrganizationsMetadata: MetadataType = new MetadataType()
    public risksAnalysisMetadata: MetadataType = new MetadataType()

    constructor(input?: any) {
        if (input) {
            this.areas = ((input && input['areas']) || []).map((object: any) => new AreaItemType(object));
            this.actions = ((input && input['actions']) || []).map((object: any) => new HazardSolutionTableItemType(object));
            this.regionalClimateData = new AoilHazardDataType(input.regionalClimateData, input.regionalClimateData && input.regionalClimateData.metadata);
            this.risksAnalysisData = new RisksAnalysisDataType(input.risksAnalysisData, input.risksAnalysisData && input.risksAnalysisData.metadata);
            this.areasMetadata = new MetadataType(input.areasMetadata)
            this.addAreaMetadata = new MetadataType(input.addAreaMetadata)
            this.actionsMetadata = new MetadataType(input.actionsMetadata)
            this.deleteAreaMetadata = new MetadataType(input.deleteAreaMetadata)
            this.addDeleteActionMetadata = new MetadataType(input.addDeleteActionMetadata)
            this.changePasswordMetadata = new MetadataType(input.changePasswordMetadata)
            this.modifyOrganizationsMetadata = new MetadataType(input.modifyOrganizationsMetadata)
            this.risksAnalysisMetadata = new MetadataType(input.risksAnalysisMetadata)
        }
    }
}


export interface AddRemoveSolutionItemType {
    solution: HazardSolutionTableItemType;
    isRemove?: boolean;
}

export class PlanType {
    public name: string = '';
    public solutions: HazardSolutionTableItemType[] = [];

    constructor(
        input?: any
    ) {
        if (input) {
            this.name = input.name;
            // this.solutions = ((input && input.solutions) || []).map((obj: any) => new HazardSolutionTableItemType(obj));
            this.solutions = (input && input.solutions) || [];
        }
    }
}