import React, { useState } from 'react'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarFilled } from '@fortawesome/free-solid-svg-icons';

interface StarsFeedbackProps {
    value: number;
    setValue: Function
}

function StarsFeedback(props: StarsFeedbackProps) {
    const { value, setValue } = props
    const [hoveredValue, setHoveredValue] = useState(0)
    const userIsHovering = hoveredValue > 0

    const StarItem = (index: number) => {
        const isHovered = userIsHovering && index <= hoveredValue
        const isHoveredClass = isHovered ? 'is-hovered' : ''
        const isSelected = !userIsHovering && index <= value
        const isSelectedClass = isSelected ? 'is-selected' : ''
        const filledStar = isSelected || isHovered

        return <div className={`star-item ${isHoveredClass} ${isSelectedClass}`}
            key={index}
            onClick={() => setValue(index)}
            onMouseOver={() => setHoveredValue(index)}
            onMouseLeave={() => setHoveredValue(0)}>
            {
                filledStar ?
                    <FontAwesomeIcon icon={faStarFilled} /> :
                    <FontAwesomeIcon icon={faStarEmpty} />
            }
        </div>
    }

    return (
        <div className='stars-container'>{
            [1, 2, 3, 4, 5].map((i) => StarItem(i))
        }
        </div>
    )
}

export default StarsFeedback