import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SolutionDetailCmp from "../components/RiskSolution/SolutionDetailCmp";
import { selectAllRisks, selectPersonalArea } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import queryString from "query-string";
import "./SolutionDetailsPage.scss";
import useScrollToTop from "../hooks/useCrollToTop";
import AddRemoveSolutionToPlanCmp from "../components/Plan/AddRemoveSolutionToPlanCmp";
import { loadAllRisksAct, setCurrentSolutionDetailsAct } from "../store/allRisks/actions";
import { getCurrSolutionFromRisks } from "../utils/utils";

const SolutionDetailsPage = () => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const allRisksState: any = useAppSelector(selectAllRisks);
  const { currentSolutionDetail, risks } = allRisksState;
  const personalArea = useAppSelector(selectPersonalArea);
  const selectedCurrentSolutionDetail = personalArea?.actions.filter(el => el._id === currentSolutionDetail?._id)[0]
  const [currentStatus, setCurrentStatus] = useState(selectedCurrentSolutionDetail?.state || "ToSchedule")
  const [actionExpireDate, setActionExpireDate] = useState<Date | string>('');

  useEffect(() => {
    const newExpDate = selectedCurrentSolutionDetail?.expiredDate ? new Date(selectedCurrentSolutionDetail.expiredDate) : ''
    setActionExpireDate(newExpDate)
  }, [selectedCurrentSolutionDetail?.expiredDate])

  useScrollToTop();

  const search = queryString.parse(window.location.search);
  const { viewOnly } = search;

  if (selectedCurrentSolutionDetail && currentStatus !== selectedCurrentSolutionDetail?.state) {
    setCurrentStatus(selectedCurrentSolutionDetail?.state)
  }

  useEffect(() => {
    const search = queryString.parse(window.location.search);
    const { code } = search;

    /* If query parameter id is empty, then go to home */
    if (!search.id) {
      history.push('/');
    }

    if (currentSolutionDetail?._id === search.id) {
      return
    }
    /* If current solution is null, then load risk list */
    dispatch(loadAllRisksAct());

  }, []);

  useEffect(() => {
    const search: any = queryString.parse(window.location.search);
    if (!risks?.length || currentSolutionDetail?._id === search.id) {
      return
    }
    // Set current solution if any, otherwise go back to home
    const currSol = getCurrSolutionFromRisks(risks, search?.code, search?.id)
    dispatch(setCurrentSolutionDetailsAct(currSol))
  }, [risks?.length])


  const clickBackHandler = () => {
    history.goBack()
  };

  if (!selectedCurrentSolutionDetail && !viewOnly) return <></>

  return (
    <>
      <div className="page-cmp page-with-no-header page-with-detail-header solution-detail-page">
        <SolutionDetailCmp hideStatusSelector={viewOnly as any}
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
          solutionDetail={currentSolutionDetail}
          clickBackHandler={clickBackHandler} 
          actionExpireDate={actionExpireDate}
          setActionExpireDate={setActionExpireDate} />
        {currentSolutionDetail && !viewOnly && <AddRemoveSolutionToPlanCmp
          currentSolution={{ ...currentSolutionDetail, state: currentStatus, expiredDate: actionExpireDate }} />}
      </div>
    </>
  );
};

export default SolutionDetailsPage;
