import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { loginSuccessAct } from "../../store/auth/actions";
import { useAppDispatch } from "../../store/hooks";
import { UserTypeEnum } from "../../types/commonTypes";
import Loader from "../../UI/Loader/Loader";
import {
  getBackendData,
  getBackendErrorCode,
  hasBackendResponseErrors,
  API_ENDPOINT,
} from "../../utils/api";
import { getErrorMessage } from "../../utils/errors.utils";
import { RetrivePswdStep } from "../../utils/utils";
import InputPassword from "../InputPassword/InputPassword";

interface PswdStepProps {
  retrivePswdCode: string;
  setIsOtpInvalid: Function;
  email: string;
  setCurrentStep: (step: RetrivePswdStep) => void;
  userType: UserTypeEnum
}

function PswdStepInsertCode(props: PswdStepProps) {
  const dispatch = useAppDispatch();
  const { email, retrivePswdCode, setCurrentStep, setIsOtpInvalid, userType } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const submitRequest = async (pswd: string, confirmPswd: string) => {
    try {
      setLoading(true);
      setError(false);
      const _body = {
        email,
        retrivePswdCode,
        pswd,
        confirmPswd,
        userType
      };
      let requestURL = `${API_ENDPOINT}/api/change-pswd`;
      const res = await axios.post(requestURL, _body);
      if (hasBackendResponseErrors(res)) {
        setCurrentStep(RetrivePswdStep.INSERT_CODE);
        setIsOtpInvalid(true);
      } else {
        const loginRes = getBackendData(res);
        dispatch(loginSuccessAct(loginRes));
        setCurrentStep(RetrivePswdStep.FINAL_STEP);
      }
    } catch (err) {
      toast.error("Errore generico", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <>
        <Formik
          initialValues={{
            pswd: "",
            confirmPswd: "",
          }}
          validationSchema={Yup.object({
            pswd: Yup.string()
              .required("Campo obbligatorio")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.,!@#\$%\^&\*])(?=.{8,})/,
                "Deve contenere almeno 8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale"
              ),
            confirmPswd: Yup.string()
              .required("Campo obbligatorio")
              .oneOf([Yup.ref("pswd"), null], "Le password non sono uguali"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            submitRequest(values.pswd, values.confirmPswd);
          }}
        >
          <Form>
            <div className="input-wrapper padding-y-base">
              <label htmlFor="pswd">Scegli una password</label>
              <InputPassword className="text-input-modal" name="pswd" />
              <ErrorMessage name="pswd" />
            </div>

            <div className="input-wrapper padding-y-base">
              <label htmlFor="confirmPswd">Conferma password</label>
              <InputPassword className="text-input-modal" name="confirmPswd" />
              <ErrorMessage name="confirmPswd" />
            </div>

            <button className="ada-btn w-100 margin-y-medium" type="submit">
              Conferma
            </button>
          </Form>
        </Formik>
      </>
    </>
  );
}

export default PswdStepInsertCode;
