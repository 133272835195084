import { E3ciAction } from "../../types/actions/e3ci-action";
import { E3ciCodeEnum } from "../../types/commonTypes";
import { E3ciItemType, E3ciType } from "../../types/e3ciType";

export const initialState: E3ciType = new E3ciType();

const reducer = (state = initialState, action: any): E3ciType => {
    let newState = new E3ciType(state);

    switch (action.type) {
        case E3ciAction.LOAD_E3CI:
            newState = new E3ciType({
                ...state
            }, {
                isLoading: true,
                errorCode: null,
                error: false
            });
            return newState;
        case E3ciAction.LOAD_E3CI_SUCCESS:
            let serverResponse = action.payload;
            if (serverResponse && serverResponse.graphData && serverResponse.graphData.length) {
                serverResponse.currentE3ci = serverResponse.graphData.find((dg: E3ciItemType) => dg.code === E3ciCodeEnum.E3CI)
                if (!serverResponse.currentE3ci) {
                    serverResponse.currentE3ci = serverResponse.graphData[0]
                }
            }
            newState = new E3ciType({
                ...state,
                ...serverResponse
            }, {
                isLoading: false,
                errorCode: null,
                error: false
            });
            return newState;

        case E3ciAction.LOAD_E3CI_ERROR:
            newState = new E3ciType({
                ...state
            }, {
                isLoading: false,
                errorCode: action.payload,
                error: true
            });
            return newState;

        case E3ciAction.SET_CURRENT_E3CI:
            newState.currentE3ci = action.payload
            return newState;

        case E3ciAction.SET_REFERENCE_PERIOD_DATE:
            newState.referencePeriodDate = action.payload
            return newState;

        default:
            return state
    }
}

export { reducer as E3ciReducer };