import { selectPersonalArea } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addDeleteActionAct } from "../../store/personalArea/actions";
import { HazardSolutionTableItemType } from "../../types/aoiHazardType";
import { isSolutionInPlan } from "../../utils/utils";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../utils/api";
import './AddRemoveSolutionToPlanCmp.scss'
import { addPersPlanItemnLocalStorage } from "../../utils/utils.store";
import { PersonalAreaItemTypeEnum } from "../../types/commonTypes";

interface AddRemoveSolutionToPlanCmpProps {
  currentSolution?: HazardSolutionTableItemType;
}

const AddRemoveSolutionMsg = ({ closeToast, toastProps, solTitle, msg, goToPersonalAreaHandler }: any) => (
  <div className="toast-container">
    <p className="toast-msg"><span className="title-msg">{solTitle}</span>&nbsp;{msg}</p>
    <p className="link-msg" onClick={goToPersonalAreaHandler}>Vai all'area personale</p>
    <button className="close-toast-btn" onClick={closeToast}>
      <FontAwesomeIcon icon={faXmark} />
    </button>
  </div>
)

const AddRemoveSolutionToPlanCmp = (props: AddRemoveSolutionToPlanCmpProps) => {
  const { currentSolution } = props;
  const history = useHistory()
  const personalArea = useAppSelector(selectPersonalArea);
  const isSolutionAlreadyInPlan = isSolutionInPlan(currentSolution, personalArea?.actions)
  const addRemoveLabel = isSolutionAlreadyInPlan ? 'Rimuovi dal' : 'Aggiungi al';
  const dispatch = useAppDispatch();

  const handleAddRemoveToPlan = (solution: HazardSolutionTableItemType) => {
    if (!isAuthenticated()) {
      addPersPlanItemnLocalStorage(PersonalAreaItemTypeEnum.ACTION, solution._id)
      history.push(`/accedi`);
      return
    }
    // addOrRemovePlanIteminLocalStorage(solution)
    dispatch(addDeleteActionAct({ solution, isRemove: isSolutionAlreadyInPlan, solutionId: solution._id, state: solution.state, expiredDate: solution.expiredDate }))

    const goToPersonalAreaHandler = () => {
      history.push(`/personal-area`);
    }
    // show notification
    const toastMessage = `è stato ${isSolutionAlreadyInPlan ? "rimosso dal" : "aggiunto al"} tuo piano`
    toast.success(<AddRemoveSolutionMsg
      solTitle={solution.title}
      msg={toastMessage}
      goToPersonalAreaHandler={goToPersonalAreaHandler}
    />, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      hideProgressBar: true,
      className: 'ada-toast success-toast'
    })
  };

  return currentSolution ? (<div className="bottom-box">
    <div>
      <button className="ada-btn" onClick={() => handleAddRemoveToPlan(currentSolution)}>
        {`${addRemoveLabel} piano d’adattamento`}
      </button>
    </div>
  </div>) : null;
};

export default AddRemoveSolutionToPlanCmp;
