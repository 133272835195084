import { faClose, faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PersonalAreaHeader.scss";

const PersonalAreaHeader = ({ settingsClick, goToHome }: any) => {
  return (
    <div className="header-cmp personal-area-header">
      <p className="hd-title personal-area-header__title">Area Personale</p>
      <div className="personal-area-header__button-wrapper">
        <button
          onClick={settingsClick}
          className="personal-area-header__button"
        >
          <FontAwesomeIcon icon={faGear} />
        </button>
        <button className="personal-area-header__button"
          onClick={goToHome}>
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
    </div>
  );
};

export default PersonalAreaHeader;
