import { useState } from "react";
import { SectorTypeEnum } from "../../types/commonTypes";
import ModalSlide from "../../UI/ModalSlide/ModalSlide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import OrganizationsSelection from "./OrganizationsSelection";
import { useAppSelector } from "../../store/hooks";
import { selectAuth } from "../../store";
import { organizationsIdToLabel } from "../../utils/utils";

interface OrganizationsCmpProps {
  organizations: string[]
  setOrganizations: Function
  classList?: string
}

const OrganizationsCmp = (props: OrganizationsCmpProps) => {
  const { organizations, setOrganizations, classList } = props
  const authState = useAppSelector(selectAuth);
  const { organizationsData } = authState;
  const ALL_ORGANIZATIONS = organizationsData?.organizations || [];

  const [localOrganizations, setLocalOrganizations] = useState(organizations);
  const [organizationsModalOpened, setOrganisationModalOpened] = useState(false);

  const organizationsPresent = !!(organizations && organizations.length > 0);
  const organizationsPresentClass = organizationsPresent ? "" : "has-placeholder";
  const organizationsLabel = organizationsPresent
    ? organizations.map(org => organizationsIdToLabel(org, ALL_ORGANIZATIONS)).join(", ")
    : "Scegli un'organizzazione";

  // const stepOneValid =
  const ToggleOrganizzazioneModal = (e: any, value: boolean) => {
    e && e.preventDefault();
    setOrganisationModalOpened(value);
  };

  const localOrganizationsHandler = (localOrganizations: string[]) => {
    setLocalOrganizations(localOrganizations);
  };

  const handleConfirmSelectors = () => {
    // dispatch(userInputSetOrganisationAct(localOrganizations));
    setOrganizations(localOrganizations)
    setOrganisationModalOpened(false);
  };

  const cancelConfirmSelectors = () => {
    setLocalOrganizations(organizations)
    setOrganisationModalOpened(false);
  };

  const organizationsLoaded = !organizationsData?.metadata.error && !organizationsData?.metadata.isLoading

  const organizationsModal = (
    <ModalSlide
      title="Seleziona le tue organizzazioni"
      closeModal={() => ToggleOrganizzazioneModal(null, false)}
      confirmModal={organizationsLoaded && handleConfirmSelectors}
      cancelModal={organizationsLoaded && cancelConfirmSelectors}
      modalClass="modal-organisations-selection"
    >
      <OrganizationsSelection
      organizations={localOrganizations}
      updateLocalOrganizations={localOrganizationsHandler} />
    </ModalSlide>
  );

  return (
    <>
      <button
        type="button"
        className={`ada-input domain-input btn-select with-icon ${organizationsPresentClass}`}
        onClick={(e) => ToggleOrganizzazioneModal(e, true)}
      >
        {organizationsLabel}
        <FontAwesomeIcon icon={faCaretDown} />
      </button>
      {organizationsModalOpened && organizationsModal}
    </>
  );
};

export default OrganizationsCmp;
