import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import "./Submenu.scss";

interface MenuItem {
  title: string,
  url: string
}

interface Submenu {
  items: MenuItem[]
  classList?: string
}

const Submenu = ({ items, classList }: Submenu) => {
  const history = useHistory();

  return (
    <div className={`submenu ${classList || ''}`}>
      {items.map((el: MenuItem) => (
        <div className="submenu__item"
        key={el.url}>
          <div onClick={() => history.push(el.url)} className="submenu__title">
            {el.title}
          </div>
          <div className="submenu__icon-container">
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Submenu;
