import { SeasonEnum } from "../types/commonTypes";
import { seasonTranslator } from "./utils";


export const stagioneDiRiferimentoBlock = (handlerFunc: Function, index: number, 
  title?: string, optionsCmp?: any) => {
    const seasons = Object.keys(SeasonEnum);
    let hazard = index === 1 ? "ExtremeTemp" : "Rain";
    const seasonsOptions = () => {
      return optionsCmp || seasons.map((season) => (
        <option value={season} key={season + index}>
          {seasonTranslator(season)}
        </option>
      ))};
    return (
      <div>
        <h4>{title || 'Stagione di riferimento'}</h4>
        <select
          name={hazard}
          className={`season-select ${hazard}`}
          onChange={(e: any) => {
            handlerFunc(e);
          }}
        >
          {seasonsOptions()}
        </select>
      </div>
    );
  };

