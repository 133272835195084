import { useState } from "react";
import { selectPersonalArea } from "../../store";
import { useAppSelector } from "../../store/hooks";
import SpecificsData from "../SpecificsData/SpecificsData";
import "./RisksAnalysisSection.scss";
import SpecificRisksCmp from "./SpecificRisksCmp";

const RisksAnalysisSection = () => {
  const personalArea = useAppSelector(selectPersonalArea)
  const { risksAnalysisData } = personalArea
  const [isDatiAggregati, setIsDatiAggregati] = useState(true)
  const isAggregatiSelectedClass = isDatiAggregati ? 'is-selected' : ''
  const isSpecificiSelectedClass = isDatiAggregati ? '' : 'is-selected'

  return (
    <div className="risks-analysis-section">
      <div className="section-title">
        <h3 className="heading-2 color-dark padding-y-medium">Analisi dei rischi</h3>
        {/* <button>
          <FontAwesomeIcon icon={faBars} />
          Filtra</button> */}
      </div>
      <div className="sub title">I rischi dei tuoi associati</div>

      <div className="dati-type-selector">
        <div className={`dati-type-selector-item ${isAggregatiSelectedClass}`}
          onClick={() => setIsDatiAggregati(true)}>
          Dati aggregati
        </div>
        <div className={`dati-type-selector-item ${isSpecificiSelectedClass}`}
          onClick={() => setIsDatiAggregati(false)}>
          Dati specifici
        </div>
      </div>

      {isDatiAggregati && <div className="section-body">
        <SpecificRisksCmp
          risksAnalysisData={risksAnalysisData.stats}
          navTo={""} />
      </div>
      }

      {!isDatiAggregati && <div className="section-body">
        <SpecificsData data={risksAnalysisData}></SpecificsData>
      </div>
      }
    </div>
  );
};

export default RisksAnalysisSection;
