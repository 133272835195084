import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { loadAoiHazardAct, loadRegionalHazardAct, userInputSetDataAct } from '../../store/regionalHazardArea/actions';
import { AreaItemType } from '../../types/regionalHazardType';
import Weather from '../Weather/Weather'

import './ClimateContainer.scss'

interface ClimateContainerProps {
    data: AreaItemType,
    selectedPlans: any
}

const ClimateContainer = ({ data, selectedPlans }: ClimateContainerProps) => {
    const history = useHistory()
    const dispatch = useAppDispatch();

    const loadHazardHandler = () => {
        dispatch(userInputSetDataAct(data))
        dispatch(loadAoiHazardAct({ history, goToAoiSolutions: true, notEditable: true, selectedPlans }))
    }

    return (
        <div className='climate-container'>
            <div className="climate-container__header"><h3 className='climate-container__zone-name'>Zona: <span>{data.name}</span></h3></div>
            <div className='climate-container__body'>
                <h3 className="climate-container__title heading-2 color-dark padding-y-medium">Ecco i rischi specifici di questa azienda</h3>
                {data?.aoiHazardData?.wind && <Weather key="1" title="Vento" data={data?.aoiHazardData?.wind}></Weather>}
                {data?.aoiHazardData?.hail && <Weather key="2" title="Grandine" data={data?.aoiHazardData?.hail}></Weather>}
                {data?.aoiHazardData?.drought && <Weather key="3" title="Siccità" data={data?.aoiHazardData?.drought}></Weather>}
                {data?.aoiHazardData?.frost && <Weather key="4" title="Gelate" data={data?.aoiHazardData?.frost}></Weather>}
                <p className='climate-container__description'>Visualizza le azioni ai rischi che sono state proposte ai produttori.</p>
                <button className='climate-container__button ada-btn w-100' onClick={loadHazardHandler}>Vedi azioni proposte</button>
            </div>
        </div>
    )
}

export default ClimateContainer