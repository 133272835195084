import loaderGif from "../../assets/images/Loader_ADA.gif";
import ModalSlide from "../ModalSlide/ModalSlide";
import "./Loader.scss";

type LoaderProps = {
  isGlobal?: boolean;
  iconCmp?: any;
  hasSemiTransparentBkg?: boolean;
};

const Loader = (props: LoaderProps) => {
  const containerClass = props.hasSemiTransparentBkg ? "semi-transparent" : "";
  const iconCmp = props.iconCmp || <img src={loaderGif} alt="loading..." />;
  const loaderContent =
    props && props.isGlobal ? (
      <ModalSlide isLoader={true}>{iconCmp}</ModalSlide>
    ) : (
      <div className={`loader-container ${containerClass}`}>{iconCmp}</div>
    );

  return loaderContent;
};

export default Loader;
