import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ENDPOINT, getBackendData, getBackendErrorCode, hasBackendResponseErrors, sendGet } from '../../utils/api';
import { AxiosResponse } from 'axios';
import { loadE3ciErrorAct, loadE3ciSuccessAct } from './actions';
import { E3ciAction } from '../../types/actions/e3ci-action';

export function* LoadE3ciCall(action: any) {
  try {
    let requestURL = `${API_ENDPOINT}/api/e3ci`;
    let res: AxiosResponse<any> = yield call(sendGet, requestURL);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res)
      yield put(loadE3ciErrorAct(errCode));
    } else {
      const userInfoData = getBackendData(res);
      yield put(loadE3ciSuccessAct(userInfoData));
    }
  } catch (err) {
    yield put(loadE3ciErrorAct());
  }
}

export function* watchE3ciCall() {
  yield takeEvery([E3ciAction.LOAD_E3CI], LoadE3ciCall)
}

export function* e3ciSaga() {
  yield all([fork(watchE3ciCall)])
}

export default e3ciSaga;
