import { useState } from "react";
import { SectorTypeEnum } from "../../types/commonTypes";
import ModalSlide from "../../UI/ModalSlide/ModalSlide";
import SectorsSelection from "./SectorsSelection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { sectorEnumToLabel } from "../../utils/utils";

interface SectorsCmpProps {
  sectors: SectorTypeEnum[];
  setSectors: Function;
  classList?: string;
}

const SectorsCmp = (props: SectorsCmpProps) => {
  const { sectors, setSectors, classList } = props;
  const [localSectorsUp, setLocalSectorsUp] = useState(sectors);
  const [sectorsModalOpened, setSectorsModalOpened] = useState(false);

  const sectorsPresent = !!(sectors && sectors.length > 0);
  const sectorsPresentClass = sectorsPresent ? "" : "has-placeholder";
  const sectorsLabel = sectorsPresent
    ? sectors.map((domain) => sectorEnumToLabel(domain)).join(", ")
    : "Scegli una filiera";

  // const stepOneValid =
  const ToggleFilieraModal = (e: any, value: boolean) => {
    e && e.preventDefault();
    setSectorsModalOpened(value);
  };

  const localSectorsUpHandler = (localSectors: SectorTypeEnum[]) => {
    setLocalSectorsUp(localSectors);
  };

  const handleConfirmSelectors = () => {
    // dispatch(userInputSetSectorsAct(localSectorsUp));
    setSectors(localSectorsUp);
    setSectorsModalOpened(false);
  };

  const cancelConfirmSelectors = () => {
    setLocalSectorsUp(sectors);
    setSectorsModalOpened(false);
  };

  const sectorsModal = (
    <ModalSlide
      title="Seleziona la tua filiera d’appartenenza"
      closeModal={() => ToggleFilieraModal(null, false)}
      confirmModal={handleConfirmSelectors}
      cancelModal={cancelConfirmSelectors}
    >
      <SectorsSelection
        sectors={localSectorsUp}
        updateLocalSectors={localSectorsUpHandler}
      />
    </ModalSlide>
  );

  return (
    <>
      <button
        type="button"
        className={`ada-input domain-input btn-select with-icon ${sectorsPresentClass} ${classList}`}
        onClick={(e) => ToggleFilieraModal(e, true)}
      >
        {sectorsLabel}
        <FontAwesomeIcon icon={faCaretDown} />
      </button>
      {sectorsModalOpened && sectorsModal}
    </>
  );
};

export default SectorsCmp;
