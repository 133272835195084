import { HazardSolutionItemType, HazardSolutionTableItemType, CurrentFilter } from "./aoiHazardType";
import { HazardCodeEnum, MetadataType, DefaultFilters } from "./commonTypes";

export class AllRisksType {
  public risks: RiskItemType[] = [];
  public currentSolutionDetail?: HazardSolutionTableItemType | null = null;
  public currentFilters: CurrentFilter[] = DefaultFilters;
  public metadata: MetadataType = new MetadataType();
  constructor(input?: any, _metadata?: any) {
    if (input) {
      this.risks = ((input && input.risks) || []).map((obj: any) => new RiskItemType(obj));
      this.currentSolutionDetail = input.currentSolutionDetail ? new HazardSolutionTableItemType(input.currentSolutionDetail) : null;
      this.currentFilters = input.currentFilters;
    }
    if (_metadata) {
      this.metadata = new MetadataType(_metadata);
    }
  }
}

export class RiskItemType {
  public code: HazardCodeEnum | null = null;
  public label: string = "";
  public solutions: HazardSolutionItemType[] = [];
  constructor(input?: any) {
    if (input) {
      this.code = input && input.code;
      this.label = (input && input.label) || "";
      this.solutions = ((input && input.solutions) || []).map((obj: any) => new HazardSolutionItemType(obj));
    }
  }
}
