import ReactMapGL from "react-map-gl";
import DrawControl from "./draw-control";
import mapboxgl, { MapboxEvent } from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectRegionalHazard } from "../../store";
import {
  userInputMapSetViewportAct,
  userInputPartialPoligonVertexAct,
  userInputSetDrawAreaAct,
  userInputSetLocationAct,
  userInputMapZoomInAct,
  userInputMapZoomOutAct,
} from "../../store/regionalHazardArea/actions";
import { isCurrentStep, mapboxGeorevertedFirstAddress } from "../../utils/utils";
import { UserInputSteps } from "../../types/regionalHazardType";
import "./InputMapCmp.scss";
import "./mapbox-gl.scss";
import "./mapbox-gl-draw.scss";
import { drawGlStyle } from "./draw-gl-style";
import streetImg from "./../../assets/images/map/street_view_img.png";
import satelliteImg from "./../../assets/images/map/satellite_view_img.png";
import { addAreaIfPresent, getCapFromSuggestion, getMapCoordVertex } from "../../utils/map.utils";
import { isAreaDrawn } from "../../utils/map.utils";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const accessToken = process.env.REACT_APP_MAP_KEY;

const InputMapCmp = () => {
  const [satelliteView, setSatelliteView] = useState(true);
  const hazardState = useAppSelector(selectRegionalHazard);
  const { userInput } = hazardState;
  const { viewport } = userInput;
  const mapStyle = satelliteView ? "mapbox://styles/mapbox/satellite-v9" : "mapbox://styles/mapbox/streets-v11";
  const activeMap = satelliteView ? "satOn" : "streetOn";
  const windowWidth = window.screen.width;

  const dispatch = useAppDispatch();

  const isDrawAreaStep = isCurrentStep(userInput.currentStep, UserInputSteps.DRAW_AREA_STEP);
  const isMapDisabled = !isDrawAreaStep;

  const updateGeoinputText = async (vertexCoord: any) => {
    if (!vertexCoord) {
      return;
    }

    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${vertexCoord.longitude},${vertexCoord.latitude}.json?access_token=${accessToken}`;
      const response = await fetch(endpoint);
      const result = await response.json();
      const georevertedVertexAddress = mapboxGeorevertedFirstAddress(result);
      const cap = getCapFromSuggestion(result?.features)

      // update map centering with respect to vertex
      dispatch(userInputSetLocationAct({ location: georevertedVertexAddress, cap }));
    } catch (error) {
      console.log("Error fetching data, ", error);
    }
  };

  const onUpdate = useCallback(
    (e) => {
      for (const area of e.features) {
        // save geoinput text through reverse geocoding of vertex coordinates
        dispatch(userInputSetDrawAreaAct(area));
        const vertexCoord = getMapCoordVertex(area);
        updateGeoinputText(vertexCoord);
      }
    },
    [dispatch]
  );

  const onDeleteCtrlHandler = useCallback(
    (e) => {
      dispatch(userInputSetDrawAreaAct(null));
    },
    [dispatch]
  );

  const onDrawRenderHandler = (e: any) => {
    const _all = window.adaMapDrawObj && window.adaMapDrawObj.getAll();
    if (_all && _all.features) {
      const { features } = _all;
      if (features && features.length === 0) {
        window.adaMapDrawObj.changeMode('draw_polygon')
        dispatch(userInputSetDrawAreaAct(null));
        return;
      } else {
        const geometry = features[0].geometry;
        if (geometry && geometry.coordinates && geometry.coordinates.length) {
          const coord = geometry.coordinates[0];
          // partial vertex are 2 more than user drawn
          const nVertexes = coord.length - 2;
          dispatch(userInputPartialPoligonVertexAct(nVertexes));
        }
      }
    }
  };

  // const onDeleteManualHandler = useCallback((e) => {
  //     window.adaMapDrawObj && window.adaMapDrawObj.deleteAll &&
  //         window.adaMapDrawObj.deleteAll();
  //     window.adaMapDrawObj.changeMode('draw_polygon')
  // }, []);

  const onMapLoadHandler = (e: MapboxEvent) => {
    window.adaMapObj = e.target;
    addAreaIfPresent();
  };

  const toggleMapStyleHandler = () => {
    setSatelliteView((satelliteView) => !satelliteView);
  };

  const zoomInHandler = () => {
    dispatch(userInputMapZoomInAct());
  };

  const zoomOutHandler = () => {
    dispatch(userInputMapZoomOutAct());
  };

  useEffect(() => {
    return () => {
      window.adaMapDrawObj = null;
      window.adaMapObj = null;
    };
  }, []);

  return (
    <>
      <div className="input-map-container">
        {windowWidth > 650 && isDrawAreaStep && !isAreaDrawn(userInput.drawArea) && (
          <>
            <button className="zoom-in" onClick={zoomInHandler}>
              +
            </button>
            <button className="zoom-out" onClick={zoomOutHandler}>
              -
            </button>
          </>
        )}
        <div className={`street-satellite-view-container satellitare ${activeMap}`} onClick={toggleMapStyleHandler}>
          <img src={streetImg} alt="street satellite view" />
        </div>
        <div className={`street-satellite-view-container stradale ${activeMap}`} onClick={toggleMapStyleHandler}>
          <img src={satelliteImg} alt="street satellite view" />
        </div>
        <ReactMapGL
          mapStyle={mapStyle}
          mapboxAccessToken={accessToken}
          {...viewport}
          // onMove={evt => setViewState(evt.viewState)}
          onMove={(evt: any) => {
            dispatch(userInputMapSetViewportAct(evt.viewState));
          }}
          onLoad={onMapLoadHandler}
        >
          {/* <DeleteAreaMarker onDeleteArea={onDeleteManualHandler} /> */}
          {isDrawAreaStep && (
            <DrawControl
              position="top-left"
              displayControlsDefault={false}
              controls={{
                polygon: false,
                trash: false,
              }}
              defaultMode="draw_polygon"
              onCreate={onUpdate}
              onUpdate={onUpdate}
              onDelete={onDeleteCtrlHandler}
              onRender={onDrawRenderHandler}
              styles={drawGlStyle}
            />
          )}
        </ReactMapGL>
        {/* <ControlPanel polygons={Object.values(features)} /> */}

        {isMapDisabled && <div className="overlay-disable-map"></div>}
      </div>
    </>
  );
};

export default InputMapCmp;
