import { NavLink, useHistory } from "react-router-dom";
import "./Cards.scss";

interface NavCardProps {
  linkTo?: string;
  icon: string;
  altText: string;
  title: string;
  bodyText: string;
  reversedStyle?: boolean;
  beforeGoToLinkAct?: any;
}

const NavCard = (props: NavCardProps) => {
  const history = useHistory();
  const { linkTo, beforeGoToLinkAct } = props
  const reversedStyle = props.reversedStyle ? "reversed" : "";

  const goToLink = () => {
    beforeGoToLinkAct && beforeGoToLinkAct()
    history.push(linkTo || '')
  }

  return (
    linkTo ?
      <div onClick={goToLink} className={`nav-card-container ${reversedStyle}`}>
        <img src={props.icon} alt={props.altText} />
        <div className="nav-card-right-side">
          <h3>{props.title}</h3>
          <p>{props.bodyText}</p>
        </div>
      </div> :
      <div className={`nav-card-container no-link ${reversedStyle}`}>
        <img src={props.icon} alt={props.altText} />
        <div className="nav-card-right-side">
          <h3>{props.title}</h3>
          <p>{props.bodyText}</p>
        </div>
      </div>
  );
};

export default NavCard;
