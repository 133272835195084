import { PersonalAreaAction } from "../../types/actions/personal-area-action";
import { PersonalAreaType } from "../../types/personalAreaType";

export const initialState: PersonalAreaType = new PersonalAreaType();

const reducer = (state = initialState, action: any): PersonalAreaType => {
    let newState = new PersonalAreaType();

    switch (action.type) {
        case PersonalAreaAction.LOAD_AREAS:
            newState = new PersonalAreaType({
                ...state,
                areasMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            })
            return newState;
        case PersonalAreaAction.LOAD_AREAS_SUCCESS:
            const _areas = (action.payload && action.payload.areas) || [];
            newState = new PersonalAreaType({
                ...state,
                areas: _areas,
                areasMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case PersonalAreaAction.LOAD_AREAS_ERROR:
            newState = new PersonalAreaType({
                ...state,
                areasMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;


        case PersonalAreaAction.SAVE_AREA:
            newState = new PersonalAreaType({
                ...state,
                addAreaMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            });
            return newState;
        case PersonalAreaAction.SAVE_AREA_SUCCESS:
            newState = new PersonalAreaType({
                ...state,
                addAreaMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case PersonalAreaAction.SAVE_AREA_ERROR:
            newState = new PersonalAreaType({
                ...state,
                addAreaMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;


        case PersonalAreaAction.LOAD_PLAN_ACTIONS:
            newState = new PersonalAreaType({
                ...state,
                actionsMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            });
            return newState;
        case PersonalAreaAction.LOAD_PLAN_ACTIONS_SUCCESS:
            const _planActions = (action.payload && action.payload.plans) || [];
            newState = new PersonalAreaType({
                ...state,
                actions: _planActions,
                actionsMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case PersonalAreaAction.LOAD_PLAN_ACTIONS_ERROR:
            newState = new PersonalAreaType({
                ...state,
                actionsMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;


        case PersonalAreaAction.DELETE_AREA:
            newState = new PersonalAreaType({
                ...state,
                deleteAreaMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            });
            return newState;
        case PersonalAreaAction.DELETE_AREA_SUCCESS:
            newState = new PersonalAreaType({
                ...state,
                deleteAreaMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case PersonalAreaAction.DELETE_AREA_ERROR:
            newState = new PersonalAreaType({
                ...state,
                deleteAreaMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;


        case PersonalAreaAction.ADD_DELETE_ACTION:
            newState = new PersonalAreaType({
                ...state,
                addDeleteActionMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            });
            return newState;
        case PersonalAreaAction.ADD_DELETE_ACTION_SUCCESS:
            newState = new PersonalAreaType({
                ...state,
                addDeleteActionMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case PersonalAreaAction.ADD_DELETE_ACTION_ERROR:
            newState = new PersonalAreaType({
                ...state,
                addDeleteActionMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;

        case PersonalAreaAction.LOAD_RISKS_ANALYSIS:
            newState = new PersonalAreaType({
                ...state,
                risksAnalysisMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            })
            return newState;
        case PersonalAreaAction.LOAD_RISKS_ANALYSIS_SUCCESS:
            const _data = (action.payload && action.payload) || [];
            newState = new PersonalAreaType({
                ...state,
                risksAnalysisData: _data,
                risksAnalysisMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case PersonalAreaAction.LOAD_RISKS_ANALYSIS_ERROR:
            newState = new PersonalAreaType({
                ...state,
                risksAnalysisMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;

        case PersonalAreaAction.LOAD_REGIONAL_CLIMATE:
            newState = new PersonalAreaType({
                ...state,
                regionalClimateMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            })
            return newState;
        case PersonalAreaAction.LOAD_REGIONAL_CLIMATE_SUCCESS:
            const regData = (action.payload && action.payload) || [];
            newState = new PersonalAreaType({
                ...state,
                regionalClimateData: regData,
                regionalClimateMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case PersonalAreaAction.LOAD_REGIONAL_CLIMATE_ERROR:
            newState = new PersonalAreaType({
                ...state,
                regionalClimateMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;

        case PersonalAreaAction.CHANGE_PASSWORD:
            newState = new PersonalAreaType({
                ...state,
                changePasswordMetadata: {
                    isLoading: true,
                    error: false,
                }
            })

            return newState

        case PersonalAreaAction.CHANGE_PASSWORD_SUCCESS:
            newState = new PersonalAreaType({
                ...state,
                changePasswordMetadata: {
                    isLoading: false,
                    error: false,
                }
            })

            return newState

        case PersonalAreaAction.CHANGE_PASSWORD_ERROR:
            newState = new PersonalAreaType({
                ...state,
                changePasswordMetadata: {
                    isLoading: false,
                    error: true,
                }
            })

            return newState

            case PersonalAreaAction.MODIFY_ORGANIZATIONS:
                newState = new PersonalAreaType({
                    ...state,
                    modifyOrganizationsMetadata: {
                        isLoading: true,
                        error: false,
                    }
                })

                return newState

            case PersonalAreaAction.MODIFY_ORGANIZATIONS_SUCCESS:
                newState = new PersonalAreaType({
                    ...state,
                    modifyOrganizationsMetadata: {
                        isLoading: false,
                        error: false,
                    }
                })

                return newState

            case PersonalAreaAction.MODIFY_ORGANIZATIONS_ERROR:
                newState = new PersonalAreaType({
                    ...state,
                    modifyOrganizationsMetadata: {
                        isLoading: false,
                        error: true,
                    }
                })

                return newState

        default:
            return state
    }
}

export { reducer as PersonalAreaReducer };