import InputMapCmp from "../components/Locations/InputMapCmp";
import SearchAreaHeader from "../components/Locations/SearchAreaHeader";
import UserInputWizardCmp from "../components/UserInput/UserInputWizardCmp";
import { selectRegionalHazard } from "../store";
import { useAppSelector } from "../store/hooks";
import { UserInputSteps } from "../types/regionalHazardType";
import Header from "../UI/Header/Header";
import { isCurrentStep } from "../utils/utils";
import Loader from "../UI/Loader/Loader";
import "./AreaInputPage.scss";

const AreaInputPage = () => {
  const reagionalHazardState = useAppSelector(selectRegionalHazard);
  const { userInput } = reagionalHazardState;

  const isSectorsStep = isCurrentStep(userInput.currentStep, UserInputSteps.SECTORS_STEP);
  const isSectorsStepClass = isSectorsStep ? 'is-sectors-step' : '';
  const isLocationStep = isCurrentStep(userInput.currentStep, UserInputSteps.LOCATION_STEP);
  const isLocationStepClass = isLocationStep ? 'is-location-step' : '';
  const isDrawAreaStep = isCurrentStep(userInput.currentStep, UserInputSteps.DRAW_AREA_STEP);
  const isDrawAreaStepClass = isDrawAreaStep ? 'is-draw-area-step' : '';

  return (
    <>
      {reagionalHazardState.metadata.isLoading && <Loader isGlobal />}
      {isSectorsStep && <Header />}
      {(isLocationStep || isDrawAreaStep) && <SearchAreaHeader />}
      <div className={`page-cmp page-with-header full-page area-input-page ${isSectorsStepClass} ${isLocationStepClass} ${isDrawAreaStepClass}`}>
        {/* Map component */}
        <InputMapCmp />

        {/* Wizard user input */}
        <UserInputWizardCmp />
      </div>
    </>
  );
};

export default AreaInputPage;
