// Import Swiper React components
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import EvCarouselItem from "./EvCarouselItem";
import { useAppSelector } from "../../store/hooks";
import { selectRegionalHazard } from "../../store";

import "./RegionalCarousel.scss";

import "pure-react-carousel/dist/react-carousel.es.css";

const RegionalCarousel = () => {
  const reagionalHazardState = useAppSelector(selectRegionalHazard);
  const { regionalHazardData } = reagionalHazardState;
  const { phenomena } = regionalHazardData;
  const N_SLIDES = ((phenomena && phenomena.length) || 0);

  return (
    <>
      <div className="carousel-container">
        <CarouselProvider naturalSlideWidth={25} naturalSlideHeight={100} totalSlides={N_SLIDES} visibleSlides={1} className="reg-data-ev-carousel" isIntrinsicHeight={true}>
          <Slider>
            {phenomena.map((ph: any, i) => (
              <Slide index={i} key={ph.code + ph.subInfo} className={"swiper-slide-item"}>
                <EvCarouselItem regEvItem={ph} />
              </Slide>
            ))}
          </Slider>
          <DotGroup />
        </CarouselProvider>
      </div>
    </>
  );
};

export default RegionalCarousel;
