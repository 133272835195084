import { useMemo, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../store/hooks";

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import ColoredSeasonalIcons from "../components/Utils/ColoredSeasonalIcons";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import { selectPersonalArea, selectRegionalHazard } from "../store";
import { HazardSolutionItemType, HazardSolutionTableItemType, ExtremeTemperatureItemType } from "../types/aoiHazardType";
import { LevelEnum, HazardCodeEnum, ValueLabel, UserTypeEnum } from "../types/commonTypes";
import { hazardTranslator, hazardIcon, hazardUrlTranslatorToCOde, hazardUrlTranslator, isSolutionInPlan } from "../utils/utils";
import { setCurrentSolutionAct, resetStateAct } from "../store/regionalHazardArea/actions";
import queryString from "query-string";
import "./RegionalSolutions.scss";
import addIcon from "../assets/images/add.png";
import arrowRight from "../assets/images/arrowRight.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const RegionalSolutions = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params: { hazardCode: string } = useParams();
  const reagionalHazardState = useAppSelector(selectRegionalHazard);
  const { aoiHazardData, selectedPlans } = reagionalHazardState;
  const personalArea = useAppSelector(selectPersonalArea);
  const [notEditable, setNotEditable] = useState(false);

  //creo gli item dell'accordion
  const hazardSolutions = useMemo(() => {
    const _extremeT = { ...aoiHazardData.extremeT };
    const _frost = { ...aoiHazardData.frost };
    const _hail = { ...aoiHazardData.hail };
    const _rain = { ...aoiHazardData.rain };
    const _wind = { ...aoiHazardData.wind };
    const _drought = { ...aoiHazardData.drought };
    const _solutions = [
      { ..._extremeT, name: HazardCodeEnum.EXTREMET },
      { ..._frost, name: HazardCodeEnum.FROST },
      { ..._hail, name: HazardCodeEnum.HAIL },
      { ..._rain, name: HazardCodeEnum.RAIN },
      { ..._wind, name: HazardCodeEnum.WIND },
      { ..._drought, name: HazardCodeEnum.DROUGHT },
    ];
    return _solutions;
  }, [aoiHazardData]);

  const solutions = hazardSolutions
    .filter((hazData) => hazData.solutions && hazData.solutions.length > 0)
    .map((hazData) => {
      if (hazData.solutions && hazData.solutions.length === 2) {
        let level = LevelEnum.LOW;
        if (hazData.solutions.find((ol) => ol.level === LevelEnum.MEDIUM)) {
          level = LevelEnum.MEDIUM;
        }
        if (hazData.solutions.find((ol) => ol.level === LevelEnum.HIGH)) {
          level = LevelEnum.HIGH;
        }
        let items = hazData.solutions[0].items.concat(hazData.solutions[1].items);
        const uniqueIds = new Set();
        const uniqueItems = items.filter((element) => {
          const isDuplicate = uniqueIds.has(element.title);
          uniqueIds.add(element.title);
          if (!isDuplicate) {
            return true;
          }
          return false;
        });
        let extremeTSolution: HazardSolutionItemType;
        extremeTSolution = {
          code: hazData.name,
          items: uniqueItems,
          level,
          description: "",
        };
        return { actionList: extremeTSolution, info: hazData.info };
      } else {
        if (hazData.info && hazData.info.length === 4) {
          var sortOrder = ["Primavera", "Estate", "Autunno", "Inverno"]; // Declare a array that defines the order of the elements to be sorted.
          hazData.info.sort(function (a, b) {
            // @ts-ignore
            return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
          });
          return hazData.solutions && { actionList: hazData.solutions[0], info: hazData.info };
        }
      }
    });

  const isDesktop = window.innerWidth > 992;
  const [isSolutionExpanded, setIsSolutionExpanded] = useState(hazardSolutions.map((haz) => ({ name: haz.name, isExpanded: isDesktop })));
  const goToRegionalReport = () => {
    if (notEditable) return history.replace('/personal-area')
    return history.goBack();
  };

  const handleAccordionExpansion = (changingSolution?: string) => {
    if (changingSolution) {
      const _isSolutionExpanded = isSolutionExpanded.map((solution) => {
        if (solution.name === changingSolution) {
          solution.isExpanded = !solution.isExpanded;
          window.history.replaceState(null, "", `/regional-solutions/${hazardUrlTranslator(changingSolution)}-expanded${notEditable ? '?userType=ORGANIZATION' : ''}`);
          return solution;
        }
        if (!isDesktop) {
          solution.isExpanded = false;
        }
        return solution;
      });
      setIsSolutionExpanded(_isSolutionExpanded);
    }
  };

  useEffect(() => {
    if (!hazardSolutions || hazardSolutions.length === 0 || hazardSolutions.find((hazard) => !hazard.info || (hazard.info && hazard.info.length < 1))) {
      dispatch(resetStateAct());
      history.push(`/`);
    } else {
      if (params?.hazardCode && !isDesktop) {
        const solutionExpandedByDefault = hazardUrlTranslatorToCOde(params?.hazardCode.replace("-expanded", ""));
        handleAccordionExpansion(solutionExpandedByDefault);
      }
    }
    // eslint-disable-next-line
  }, [params, hazardSolutions]);

  useEffect(() => {
    const search = queryString.parse(window.location.search);
    const userType = search && search["userType"];
    if (userType === UserTypeEnum.ORGANIZATION)
      return setNotEditable(true)

    return setNotEditable(false)
  }, [window.location.search])

  const handleSolutionRedirect = (solution: HazardSolutionTableItemType) => {
    dispatch(setCurrentSolutionAct(solution));
    history.push(`/aoi-hazard-solution/${notEditable ? 'editMode' : ''}`);
  };

  const solutionsAccordions = solutions.map((solution, i) => {
    let lineColor = "#87B54C";
    if (solution?.actionList.level !== LevelEnum.LOW) {
      lineColor = solution?.actionList.level === LevelEnum.MEDIUM ? "#F79009" : "#F04438";
    }
    const codeStrig = solution?.actionList.code?.toString();
    const thisSolution = isSolutionExpanded.find((hazard) => hazard.name === solution?.actionList.code);
    let colouredLine = <div></div>;

    if (solution && solution.info) {
      const createColoredLine = (info: ExtremeTemperatureItemType[] | ValueLabel[]) => {
        if ("label" in info[0]) {
          const fragments = solution?.info?.map((season) => {
            return (
              // @ts-ignore
              <div className={`fragment ${season.value}`} key={season.label}>
                {/* @ts-ignore*/}
                <ColoredSeasonalIcons seasonName={season.label} riskValue={season.value} />
              </div>
            );
          });
          return <div className="fragmented-line">{fragments}</div>;
        }
        return <div className="color-line" style={{ backgroundColor: `${lineColor}` }}></div>;
      };
      // @ts-ignore
      colouredLine = createColoredLine(solution?.info);
    }

    return (
      <AccordionItem key={solution?.actionList.code} dangerouslySetExpanded={thisSolution?.isExpanded} className={`accordion__item ${solution?.actionList.code}`}>
        {colouredLine}
        <div className="accordion-header" onClick={() => handleAccordionExpansion(codeStrig)}>
          <img
            src={solution?.actionList.code ? hazardIcon(solution.actionList.code).img : ""}
            alt={solution?.actionList.code ? hazardIcon(solution.actionList.code).altText : ""}
          />
          <h3>{solution?.actionList.code ? hazardTranslator(solution.actionList.code) : solution?.actionList.code}</h3>
          <AccordionItemHeading>
            <AccordionItemButton>
              <button className={`ada-btn expand-icon-container ${thisSolution?.isExpanded ? "open" : ""}`}>
                <img src={addIcon} alt="Icona espandi" />
              </button>
            </AccordionItemButton>
          </AccordionItemHeading>
        </div>
        <AccordionItemPanel>
          <ul>
            {solution?.actionList.items.map((item) => (
              <li key={item.title}>
                <button className="ada-btn" onClick={() => handleSolutionRedirect(item)}>
                  <span>{item.action}</span>
                  {notEditable ?
                    selectedPlans.includes(item._id) && <div className="added-to-plan"><FontAwesomeIcon icon={faCheck} /><span>Aggiunto al piano</span></div>
                    :
                    isSolutionInPlan(item, personalArea?.actions) && <div className="added-to-plan"><FontAwesomeIcon icon={faCheck} /><span>Aggiunto al piano</span></div>}
                  <img src={arrowRight} alt="Vai alla soluzione" />
                </button>
              </li>
            ))}
          </ul>
        </AccordionItemPanel>
      </AccordionItem>
    );
  });

  return (
    <>
      <WhiteHeader title="Azioni individuate" goBackCb={goToRegionalReport} />
      <div className="page-cmp light-gray-bg">
        <div className=" regional-solution">
          <p className="intro-p">{
            notEditable ?
              'Ecco le soluzioni ai rischi che abbiamo proposto, seleziona un evento per espanderlo:' :
              'Ecco le azioni ai rischi che abbiamo trovato per te, seleziona un evento per espanderlo e aggiungere una azione al tuo piano d’adattamento:'
          }</p>
          <Accordion>{solutionsAccordions}</Accordion>
        </div>
      </div>
    </>
  );
};

export default RegionalSolutions;
