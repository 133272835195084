import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { ValueLabel, LevelEnum } from "../../types/commonTypes";
import { riskColor, seasonTranslator } from "../../utils/utils";
import { ItalianSeasonEnum } from "../../types/commonTypes";

import arrowUpIcon from "../../assets/images/arrow-up.png";
import arrowDownIcon from "../../assets/images/arrow-down.png";
import dotIcon from "../../assets/images/dot.png";
import ColoredSeasonalIcons from "../../components/Utils/ColoredSeasonalIcons";

type SeasonalReportCardProps = {
  seasons?: ValueLabel[];
  text?: string;
  additionalTextClass?: string;
  additionalDangerLabel?: string
  subText?: string;
  icon: {
    img: string;
    altText: string;
  };
};

const SeasonalReportCard = (props: SeasonalReportCardProps) => {
  let _seasons = props.seasons || [];

  if (_seasons[0].label === "Autunno") {
    _seasons = [_seasons[3], _seasons[1], _seasons[0], _seasons[2]];
  }

  const N_SEGMENTS = _seasons?.length;
  const SPACE_BETWEEN = N_SEGMENTS < 6 ? 0.015 : 0.013;
  const SEGMENT_SPACE = 1 / N_SEGMENTS;
  const coloredValue = SEGMENT_SPACE - SPACE_BETWEEN * 2;

  const progressCircle = _seasons?.map((season, index) => {
    let currentSeason = "";
    switch (index) {
      case 0:
        currentSeason = ItalianSeasonEnum.SPRING;
        break;
      case 1:
        currentSeason = ItalianSeasonEnum.SUMMER;
        break;
      case 2:
        currentSeason = ItalianSeasonEnum.AUTUMN;
        break;
      case 3:
        currentSeason = ItalianSeasonEnum.WINTER;
        break;
    }
    return (
      <div className="CircularProgressbar-container" key={season.label + props.icon.altText + index}>
        <CircularProgressbar
          className={"ada-circular-progress"}
          value={coloredValue}
          maxValue={1}
          strokeWidth={6}
          styles={buildStyles({
            rotation: SEGMENT_SPACE * index + SPACE_BETWEEN,
            trailColor: "transparent",
            pathColor: riskColor(season.value),
          })}
        />
        <ColoredSeasonalIcons seasonName={season.label} riskValue={season.value} currentSeason={currentSeason} />
      </div>
    );
  });

  const seasonalDangerLevel = _seasons?.map((season) => {
    const riskIconText = {
      icon: "",
      altText: "",
      text: "",
    };
    switch (season.value) {
      case LevelEnum.HIGH:
        riskIconText.icon = arrowUpIcon;
        riskIconText.altText = "Icona freccia verso l'alto";
        riskIconText.text = "Alta";
        break;
      case LevelEnum.MEDIUM:
        riskIconText.icon = dotIcon;
        riskIconText.altText = "Icona punto arancione";
        riskIconText.text = "Media";
        break;
      case LevelEnum.LOW:
        riskIconText.icon = arrowDownIcon;
        riskIconText.altText = "Icona freccia verso il basso";
        riskIconText.text = "Bassa";
        break;
    }
    return (
      <div className="season-level" key={season.label + props.icon.altText}>
        <h4>{seasonTranslator(season.label)}</h4>
        <div className={"risk-color-box " + riskIconText.text}>
          <img src={riskIconText.icon} alt={riskIconText.altText} />
          <p>{riskIconText.text}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="seasonal-report-card-container">
      <div className="progress-circle-container">
        {progressCircle}
        <img src={props.icon.img} alt={props.icon.altText} />
      </div>
      <h3 className={`${props.additionalTextClass}`}>{props.text || ""}</h3>
      <p>{props.subText || ""}</p>
      <div className="separator-line-gray"></div>
      <div className="upper-footer">
        <h4>{`Pericolosità ${props.additionalDangerLabel || ""}`}</h4>
        <div className="danger-level">{seasonalDangerLevel}</div>
      </div>
    </div>
  );
};

export default SeasonalReportCard;
