import axios from 'axios';
// import { useAppDispatch } from '../store/hooks';
// import { useHistory } from 'react-router-dom';
import { store } from '../configureStore';
import { push } from 'connected-react-router';
import { clearLocalStorageAuthData } from './utils.store';
import { getValidTokenPresent } from './api';
import { logoutAct } from '../store/auth/actions';

const requestHandler = (request: any) => {
  const authInfoToken = getValidTokenPresent();
  if (authInfoToken && authInfoToken !== null) {
    request.headers['Authorization'] = `Bearer ${authInfoToken}`;
  }

  return request;
}

const applyAxiosInterceptor = () => {
  const { dispatch } = store;

  const logout = () => {
    if (!window.location || window.location.pathname !== 'api/login') {
      dispatch(logoutAct());
      dispatch(push(('/')));
    }
  };

  axios.interceptors.request.use(
    request => requestHandler(request)
  )
  // Define Axios interceptors
  axios.interceptors.response.use(response => {
    //console.log("response ---> ", response);
    return response;
  }, error => {
    if (error && error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 302)) {
      // Manage 401 not authorized here
      // check if the url does not ends as one of the Sts url
      logout();
    }
    return error;
  });
};

export default applyAxiosInterceptor;