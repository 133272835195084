import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

const PswdStepFinal = (props: any) => {
  const { closeModal } = props;
  const history = useHistory();

  const closeHandler = () => {
    history.push("/accedi");
    closeModal();
  };

  return (
    <div className="password-step-final">
      <div className="password-step-final__icon">
        <FontAwesomeIcon icon={faCircleCheck} />
      </div>
      <h3 className="password-step-final__title">Password aggiornata</h3>
      <p className="password-step-final__description margin-y-base">
        Accedi con le nuove credenziali.
      </p>
      <button
        style={{ color: "#667085" }}
        className="ada-btn transparent-btn w-100"
        onClick={closeHandler}
      >
        Chiudi
      </button>
    </div>
  );
};

export default PswdStepFinal;
