import mixpanel from 'mixpanel-browser';
import { hasUserAcceptedCookies } from './utils/utils';
mixpanel.init(process.env.REACT_APP_WIXPANEL_TOKEN);

let env_check = true // process.env.NODE_ENV === 'production';

let actions = {
  register: (superSetData: any) => {
    if (env_check) mixpanel.register(superSetData);
  },
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: string, props?: any) => {
    // verify from local storage if the user has accepted the cookie policy
    /* const areCookiesAccepted = hasUserAcceptedCookies() */
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;