import { useHistory } from "react-router-dom";

import WhiteHeader from "../../components/WhiteHeader/WhiteHeader";
import useScrollToTop from "../../hooks/useCrollToTop";
import { HazardCodeEnum } from "../../types/commonTypes";
import { hazardTranslator } from "../../utils/utils";

import "./Infopage.scss";

const InfoGelate = () => {
  const history = useHistory();
  const goToDrawAreaHandler = () => {
    history.goBack();
  };
  useScrollToTop();

  return (
    <div className="info-page">
      <WhiteHeader title={`Info ${hazardTranslator(HazardCodeEnum.FROST)}`} goBackCb={goToDrawAreaHandler} />
      <div className="text-container  max-1480-container">
        <h3>Sorgente dati</h3>
        <p>
          La sorgente dati utilizzata è ERG5, il dataset meteorologico ufficiale del Servizio IdroMeteoClima (Simc) di Arpae (risoluzione circa 5 km). Si tratta di un dataset
          orario e giornaliero comprensivo delle principali variabili agrometeorologiche, in particolare temperatura (minime e massime), realizzato utilizzando tutte le stazioni
          disponibili afferenti alle reti gestita da Arpae-Simc, tramite i metodi descritti in Antolini et al. (2016). Il dataset scelto garantisce una buona accuratezza dal punto
          di vista spaziale, anche se non è adeguato per valutare dinamiche temporali sul lungo periodo, poiché la densità delle stazioni è variabile nel tempo. il periodo
          disponibile per la temperatura è dal 2001. Il dataset è stato scelto per garantire la massima affidabilità relativamente alla variabilità spaziale.
        </p>
        <h3>Periodo di riferimento</h3>
        <p>L’intervallo utilizzato è il ventennio 2001-2020.</p>
        <h3>Indici</h3>
        <p>
          La variabile primaria scelta è la temperatura minima giornaliera. Si sono calcolate per le decadi significative per il rischio gelate le frequenze di accadimento di
          eventi con temperatura minima sotto la soglia di -1 °C. Le decadi considerate sono le tre di marzo e la prima di aprile, in cui si verificano solitamente nel territorio
          dell’Emilia-Romagna gli eventi di raffreddamento più dannosi per la produzione agricola regionale.
        </p>
        <h3>Classificazione</h3>
        <p>
          Si sono utilizzate le seguenti soglie di frequenza per classificare il territorio in tre aree corrispondenti a diversi livelli di pericolo climatico (alto, medio e
          basso):
        </p>
        <ul>
          <li>{"<"} 0.2 (pericolo basso): si verificano di norma meno di 1 evento ogni 5 anni con minima inferiore a -1 °C;</li>
          <li>0.2 ÷ -1 (pericolo medio): si verifica di norma tra 1 e 5 eventi ogni 5 anni con minima inferiore a -1 °C;</li>
          <li>{">"} 1 (pericolo alto): si verifica di norma un evento all’anno con minima inferiore a -1 °C.</li>
        </ul>
        <p>
          Data l’elevata variabilità spaziale del raffreddamento notturno in concomitanza degli eventi di gelata radiativa (la tipologia più frequente nel territorio considerato),
          la mappa risultante dalla classificazione climatica descritta sopra è stata ulteriormente combinata (con coefficienti di variazione stabiliti per classi) con strati
          informativi legati alle caratteristiche topografiche e geografiche.
        </p>
        <h3>Indice topografico</h3>
        <p>
          E’ ampiamente documentato come le zone topograficamente depresse (es. fondovalle) sono particolarmente critiche per l’accumulo di aria fredda durante il raffreddamento
          notturno, nelle situazioni atmosferiche in cui si verificano le gelate radiative. Queste ultime, che rappresentano la maggior parte degli eventi che si verificano in
          Pianura Padana, sono caratterizzate da condizioni di scarsa ventosità, bassa umidità atmosferica, cielo sereno e forte stabilità atmosferica. In tali situazioni, l’aria
          fredda tende ad accumularsi nelle zone che hanno una quota minore rispetto al territorio limitrofo. Va sottolineato che questi accumuli si verificano anche in pianura,
          laddove le differenze di quote sono minime, portando a una variabilità spaziale elevatissima. L’indice topografico proposto da Zinoni et al. (2002) (risoluzione 90 m)
          rappresenta numericamente, con valori tra -1 e 1, la propensione del territorio all’accumulo di aria fredda. La mappa è stata classificata in tre aree:
        </p>
        <ul>
          <li>{"<"} -0.75: forte rischio di accumulo di aria fredda</li>
          <li>-0.75 ÷ 0.75: rischio moderato di accumulo di aria fredda</li>
          <li>{">"} 0.75: rischio basso di accumulo di aria fredda</li>
        </ul>
        <p>
          A queste classi si sono attribuiti dei punteggi rispettivamente di +1, 0, -1 nella sovrapposizione con la mappa di frequenza di gelo (es. la classe ottenuta in base alle
          frequenza di gelo viene aumentata di pericolosità se l’indice topografico risulta minore di - 0.75).
        </p>
        <h3>Frazione urbana</h3>
        <p>
          L’isola di calore urbana è un fenomeno ormai ampiamente documentato, per il quale il territorio urbanizzato risulta più caldo, soprattutto nelle ore notturne, rispetto
          alle aree circostanti. La causa di tale fenomeno risiede nelle caratteristiche termiche e aerodinamiche del tessuto urbano. La frazione urbana per la regione
          Emilia-Romagna (risoluzione 90 m) (anno di riferimento: 2008) è stata calcolata sulla base della mappa di uso reale del suolo, secondo la metodologia descritta in
          Antolini et al. (2016). La mappa di frazione urbana è stata classificata in due aree:
        </p>
        <ul>
          <li>{"<"} 0,8: area non soggetta a isola di calore urbana</li>
          <li>{">"} 0,8: area soggetta a isola di calore urbana</li>
        </ul>
        <p>A queste classi si sono attribuiti dei punteggi rispettivamente di 0 e +1, nella sovrapposizione con la mappa di frequenza di gelo.</p>
        <h3>Limitazioni</h3>
        <ul>
          <li>la mappa finale è la sovrapposizione di informazioni a diverse risoluzioni (5 km per la frequenza di gelo, 90 m per gli altri layer);</li>
          <li>
            i layer relativi alla frazione urbana e all’indice topografico sono utilizzati anche nel processo di generazione del dataset ERG5; questo potrebbe causare in alcune
            zone un sovra-rappresentatività dell’informazione;
          </li>
          <li>
            le caratteristiche microclimatiche del territorio possono non essere adeguatamente rappresentate nel prodotto finale, data anche l’elevata variabilità spaziale degli
            eventi di gelata (es. effetto della vegetazione, effetto dei corpi idrici, dimensione e caratteristiche dei nuclei urbani).
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InfoGelate;
