import { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { selectRegionalHazard } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { loadAoiHazardAct, loadRegionalHazardAct, userInputSetDrawAreaAct } from "../../store/regionalHazardArea/actions";
import { addAreaIfPresent, deleteMapDrawnArea, getDrawAreaLabel, isAreaDrawn } from "../../utils/map.utils";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {  toastDefaultProperties, getErrorMessage } from "../../utils/errors.utils";

const DrawAreaStep = () => {
    const history = useHistory()
    const hazardState = useAppSelector(selectRegionalHazard);
    const { userInput } = hazardState;

    const dispatch = useAppDispatch();
    const loadHazardHandler = () => {
        dispatch(loadRegionalHazardAct({ history }))
        dispatch(loadAoiHazardAct({ history }))
    }

    useEffect(() => {
        if (hazardState.metadata.error) {
            toast.error(getErrorMessage(hazardState.metadata.errorCode), toastDefaultProperties);
        }
    }, [hazardState.metadata.error])


    const isDrawAreaPresent = !!userInput.drawArea;

    const drawAreaTitle = isAreaDrawn(userInput.drawArea) ?
        'Bene!' : 'Disegna la tua zona d’interesse'

    const drawAreaLabel = useMemo(() => {
        return getDrawAreaLabel(userInput.drawArea, userInput.partialNvertex)
    }, [userInput.drawArea, userInput.partialNvertex])

    const btnManualDeleteHandler = (e: any) => {
        dispatch(userInputSetDrawAreaAct(null))
        deleteMapDrawnArea()
    };

    useEffect(() => {
        addAreaIfPresent()
    }, [])

    return (
        <>
            <div className="wizard-step draw-area-step">
                <p className="main-msg">{drawAreaTitle}</p>
                <p className="sub-msg">
                    {drawAreaLabel}
                </p>

                {isDrawAreaPresent &&
                    <><button className="ada-btn w-100 area-drawn-confirm-btn"
                        onClick={loadHazardHandler}>
                        Conferma zona selezionata
                    </button>
                        <button className="ada-btn secondary-btn w-100 btn-delete-area"
                            onClick={btnManualDeleteHandler}>
                            Elimina zona
                        </button>
                    </>
                }
            </div>
        </>
    )
}

export default DrawAreaStep;
