import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './App';
import { store } from './configureStore'

ReactDOM.render(
  <>
    <App store={store} />
  </>,
  document.getElementById('root')
);
