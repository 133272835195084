import { NavLink } from "react-router-dom";

import NavCard from "../../UI/Cards/NavCard";
import mapIcon from "../../assets/images/mapIcon.png";
import alertIcon from "../../assets/images/alertIcon.png";
import userIcon from "../../assets/images/User.png";
import "./NavigationDrawer.scss";
import bkUnionImg from "../../assets/images/bk-union.png";
import { isAuthenticated } from "../../utils/api";
import { useAppDispatch } from "../../store/hooks";
import { userInputClearAct } from "../../store/regionalHazardArea/actions";


const personalAreaNavCard = () => isAuthenticated() ? (
  <NavCard
    linkTo={"/personal-area"}
    icon={userIcon}
    altText="Icona utente"
    title="La tua area"
    bodyText="Qui potrai visualizzare le zone salvate e il tuo piano d’adattamento."
    reversedStyle
  />
) : (
  <NavCard
    icon={userIcon}
    linkTo={"/accedi"}
    altText="Icona utente"
    title="Area personale"
    bodyText="Qui tramite la registrazione potrai visualizzare le zone salvate e il tuo piano d’adattamento."
  />
);

const NavigationDrawer = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="nav-drawer-container">
      <div className="bk-img-container">
        <img src={bkUnionImg} alt="background" />
      </div>
      <NavCard
        beforeGoToLinkAct={() => dispatch(userInputClearAct())}
        linkTo={"/area-input-page"}
        icon={mapIcon}
        altText="Icona mappa"
        title="Le mappe di rischio"
        bodyText="Qui potrai visualizzare le mappe di rischio divise per  eventi meteo-climatici delle zone da te selezionate"
      />
      <NavCard
        linkTo={"/all-risks"}
        icon={alertIcon}
        altText="Icona rischio"
        title="I rischi"
        bodyText="Qui potrai visualizzare l’elenco dei rischi e le soluzioni  per  ogni evento meteo-climatico"
      />
      {personalAreaNavCard()}
      <div className="bottom-link-container">
        <NavLink to={"/terms-conditions"} className="link-like bottom-link">
          Termini e condizioni
        </NavLink>
      </div>
    </div>
  );
};

export default NavigationDrawer;
