import "./E3ciCmp.scss";
import { useAppSelector } from "../../store/hooks";
import { selectE3ci } from "../../store";
import E3CiStresses from "./E3CiStresses";
import WhiteHeader from "../WhiteHeader/WhiteHeader";
import Loader from "../../UI/Loader/Loader";
import E3CiChart from "./E3CiChart";
import E3CiReferencePeriod from "./E3CiReferencePeriod";

interface E3ciCmpProps {
  clickBackHandler: any;
}

const E3ciCmp = (props: E3ciCmpProps) => {
  const { clickBackHandler } = props;
  const e3ciState = useAppSelector(selectE3ci);
  const substringEnd = e3ciState.currentE3ci?.description.indexOf("Maggiori dettagli");
  const currentDescription = e3ciState.currentE3ci?.description.substring(0, substringEnd);

  return (
    <>
      {
        <div className="e3ci-cmp">
          <WhiteHeader goBackCb={clickBackHandler} title="E³CI nella tua zona" />

          {e3ciState.metadata?.isLoading && <Loader />}
          {!e3ciState.metadata?.isLoading && (
            <div className="e3ci-body page-detail-body max-1480-container">
              <E3CiStresses />
              <div className="e3ci-chart-section">
                <E3CiChart />
              </div>
              <E3CiReferencePeriod />
              <div className="e3ci-secription-section">
                <p className="title">Descrizione</p>
                {e3ciState.currentE3ci && (
                  <p className="description">
                    {`${currentDescription} Maggiori dettagli su `}
                    <a href="https://www.ifabfoundation.org/e3ci/" target="_blank" rel="noreferrer" className="link-like green">
                      ifabfoundation.org/e3ci
                    </a>
                    .
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
};

export default E3ciCmp;
