import { useHistory } from "react-router-dom";

import WhiteHeader from "../../components/WhiteHeader/WhiteHeader";
import useScrollToTop from "../../hooks/useCrollToTop";
import { HazardCodeEnum } from "../../types/commonTypes";
import { hazardTranslator } from "../../utils/utils";

import "./Infopage.scss";

const InfoSiccità = () => {
  const history = useHistory();
  const goToDrawAreaHandler = () => {
    history.goBack();
  };
  useScrollToTop();

  return (
    <div className="info-page">
      <WhiteHeader title={`Info ${hazardTranslator(HazardCodeEnum.DROUGHT)}`} goBackCb={goToDrawAreaHandler} />
      <div className="text-container  max-1480-container">
        <h3>SORGENTE DATI</h3>
        <p>
          La sorgente dati utilizzata è ERG5_Eraclito, il dataset climatico ufficiale dell’Osservatorio Clima di Arpae (risoluzione circa 5 km). Si tratta di un dataset giornaliero
          di temperatura (minime e massime) e precipitazioni, realizzato a partire da un set di stazioni a densità costante nel tempo, tramite i metodi descritti in Antolini et al.
          (2016). Il dataset scelto garantisce un’adeguata lunghezza temporale di analisi, pur con una limitata densità di stazioni, in particolare dei termometri. Nel caso
          specifico della siccità, si è ritenuto comunque preferibile prediligere la lunghezza del periodo rispetto al dettaglio spaziale.
        </p>
        <h3>PERIODO DI RIFERIMENTO</h3>
        <p>
          L’intervallo utilizzato è il trentennio recente 1991-2020. La scelta del periodo deriva da un compromesso tra adeguata lunghezza del periodo per studi di siccità, e
          rappresentatività del clima recente.
        </p>
        <h3>INDICI</h3>
        <p>
          La variabile scelta è il bilancio idroclimatico (BIC), cioè la differenza tra precipitazioni ed evapotraspirazione potenziale (calcolata secondo la formula di
          Hargreaves-Samani. I periodi temporali significativi per descrivere il pericolo siccità dal punto di vista meteorologico sul territorio sono i seguenti:
        </p>
        <p>
          - periodo estivo: la maggior parte delle colture sono in pieno campo e in piena attività, la domanda evapotraspirativa raggiunge i massimi annuali e le precipitazioni
          sono limitate; è il periodo dell’anno in cui gli effetti della siccità sono più rilevanti e più frequenti;
        </p>
        <p>
          - periodo primaverile: alcune colture sono nel periodo della germinazione, fase che richiede un’adeguata disponibilità idrica nel terreno; altre colture raggiungono
          gradualmente la maturità (es. frumento), altre ancora sono nella fase di crescita che precede la massima attività (es. vite)
        </p>
        <p> - periodo autunno-vernino: è il periodo in cui la maggior parte delle colture è assente o in dormienza, mentre i suoli e le falde acquifere si ricaricano.</p>
        <h3>CLASSIFICAZIONE</h3>
        <p>Si sono utilizzate le seguenti soglie di BIC per classificare il territorio in tre aree corrispondenti a diversi livelli di pericolo climatico (alto, medio e basso):</p>
        <span>Periodo estivo</span>
        <ul>
          <li>
            {"<"} -350 mm (pericolo alto): la domanda evapotraspirativa è molto elevata e sono di norma richieste numerose irrigazioni per tutte le colture, comprese quello meno
            idro-esigenti;
          </li>
          <li>
            {" "}
            -350 ÷ -250 (pericolo medio): la domanda evapotraspirativa è medio-elevata; di norma sono richieste numerose irrigazioni per le colture idro-esigenti, nelle annate
            siccitose sono richieste irrigazioni anche per le colture meno idro-esigenti;
          </li>
          <li>{">"} -250: non sono di norma richieste irrigazioni </li>
        </ul>
        <span>Primavera</span>
        <ul>
          <li>{"<"} -150: probabile irrigazione per garantire la germinazione delle colture primaverili-estive più tardive;</li>
          <li>-150 ÷ -50: necessità di irrigazione nel caso in cui non sia disponibile riserva idrica nei suoli</li>
          <li>{">"} 50: non sono di norma richieste irrigazioni</li>
        </ul>
        <span>Autunno-inverno</span>
        <ul>
          <li>{"<"} 100: alto pericolo di mancata ricarica delle falde superficiali, rischio siccità in caso di primavera poco piovosa</li>
          <li>100 ÷ 200: moderato pericolo di mancata ricarica delle falde superficiali, rischio siccità in caso di primavera poco piovosa</li>
          <li>{">"} 200: il rischio siccità è basso anche in caso di primavera siccitosa</li>
        </ul>
        <h3>LIMITAZIONI</h3>
        <ul>
          <li>la formula utilizzata per l’evapotraspirazione potenziale è semplificata e non tiene conto delle condizioni locali di umidità atmosferica e di vento</li>
          <li>
            scarsa densità spaziale delle stazioni che hanno contribuito a generare il dataset (in particolare delle stazioni termometriche): alcune condizioni locali possono non
            essere rappresentate adeguatamente;
          </li>
          <li>
            la presente classificazione è puramente climatica, e non viene considerata esplicitamente la componente pedologica. Per una valutazione più approfondita del rischio
            siccità, l’utente deve necessariamente prendere in considerazione le caratteristiche dei suoli che insistono sull’area in esame. Suoli con capacità di ritenzione idrica
            elevata possono mitigare situazione di carenza idrica a livello atmosferico, al contrario suoli con capacità minore possono portare a condizioni di siccità anche se il
            pericolo climatico è relativamente basso.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InfoSiccità;
