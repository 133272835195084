import { faMap } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useHistory } from 'react-router-dom'

import './AddNewZone.scss'
import { useAppDispatch } from '../../store/hooks'
import { userInputClearAct } from '../../store/regionalHazardArea/actions'

interface AddNewZoneProps {
    url: string
}

const AddNewZone = ({ url }: AddNewZoneProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

    const addNewZoneHandler = () => {
        // clear user input area
        dispatch(userInputClearAct())
        history.push(url)
    }

    return (
        <div className='add-new-zone' onClick={addNewZoneHandler}>
            <FontAwesomeIcon className="add-new-zone__icon" icon={faMap} />
            <h3 className='add-new-zone__title'>Cerca una nuova<br />zona</h3>
        </div>
    )
}

export default AddNewZone