import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { selectAllRisks } from "../../store";
import { useAppSelector } from "../../store/hooks";
import { RiskItemType } from "../../types/allRisksType";
import EventImageCmp from "../RegionalCarousel/EventImageCmp";
import "./AllRisksCmp.scss";

const AllRisksCmp = () => {
  let history = useHistory();
  const allRisksState = useAppSelector(selectAllRisks);
  const { risks } = allRisksState;
  const goToSolution = (risk: RiskItemType) => {
    history.push(`/risk-item?code=${risk.code}`);
  };

  return (
    <div className="all-risks-cmp">
      {risks &&
        risks.map((risk: RiskItemType) => (
          <div className="risk-card" onClick={() => goToSolution(risk)} key={risk.code}>
            <EventImageCmp eventCode={risk.code} />
            <div className="ev-card-content">
              <p className="ev-label">{risk.label}</p>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default AllRisksCmp;
