import { AllRisksAction } from "../../types/actions/all-risks-action";
import { AllRisksType } from "../../types/allRisksType";
import { DefaultFilters } from "../../types/commonTypes";

export const initialState: AllRisksType = new AllRisksType();

const reducer = (state = initialState, action: any): AllRisksType => {
  let newState = new AllRisksType(state);
  let _risks;

  switch (action.type) {
    case AllRisksAction.LOAD_ALL_RISKS:
      newState = new AllRisksType(
        {
          ...state,
        },
        {
          isLoading: true,
          errorCode: null,
          error: false,
        }
      );
      return newState;
    case AllRisksAction.LOAD_ALL_RISKS_SUCCESS:
      _risks = action.payload && action.payload.risks;
      newState = new AllRisksType(
        {
          ...state,
          risks: _risks,
        },
        {
          isLoading: false,
          errorCode: null,
          error: false,
        }
      );
      return newState;

    case AllRisksAction.LOAD_ALL_RISKS_ERROR:
      newState = new AllRisksType(
        {
          ...state,
        },
        {
          isLoading: false,
          errorCode: action.payload,
          error: true,
        }
      );
      return newState;

    case AllRisksAction.SET_CURRENT_SOLUTION_DETAILS:
      newState.currentSolutionDetail = action.payload;

      return newState;
    case AllRisksAction.SET_CURRENT_FILTERS:
      newState.currentFilters = action.payload;

      return newState;

    default:
      return state;
  }
};

export { reducer as AllRisksReducer };
