import { useHistory } from "react-router-dom";

import WhiteHeader from "../../components/WhiteHeader/WhiteHeader";
import useScrollToTop from "../../hooks/useCrollToTop";
import { HazardCodeEnum } from "../../types/commonTypes";
import { hazardTranslator } from "../../utils/utils";

import "./Infopage.scss";

const InfoVento = () => {
  const history = useHistory();
  const goToDrawAreaHandler = () => {
    history.goBack()
  };
  useScrollToTop();

  return (
    <div className="info-page">
      <WhiteHeader title={`Info ${hazardTranslator(HazardCodeEnum.WIND)}`} goBackCb={goToDrawAreaHandler} />
      <div className="text-container  max-1480-container">
        <h3>Sorgente dati</h3>
        <p>
          I dati utilizzati per calcolare gli indici di frequenza di raffiche intense sono stati estratti dal data-set di ERA5 di Copernicus (
          <a href="https://cds.climate.copernicus.eu/cdsapp#!/dataset/reanalysis-era5-single-levels?tab=overview" target={"_blank"}>
            Visita
          </a>
          ).
        </p>
        <p>
          Il parametro considerato è l’intensità massima oraria del vento a 10m. Il dato è ottenuto in ERA5 come previsione. Ogni giorno sono disponibili 2 run: uno inizializzato
          alle 06:00 UTC e uno alle 18:00 UTC. I 24 dati orari di ciascun giorno sono stati ottenuti scartando le prime 6 ore di previsione e considerando le successive 12 ore di
          ciascun run previsionale.
        </p>
        <p>
          I dati (originariamente su griglia gaussiana, non descrivibile con file NetCdf) sono stati re- interpolati su una griglia regolare 0.2°x0.2° (circa 20 km) che copre tutto
          il territorio nazionale.
        </p>
        <h3>Periodo di riferimento</h3>
        <p>Sono stati calcolati indici medi per ciascuna stagione meteorologica per il periodo 1991- 2020.</p>
        <h3>Indici</h3>
        <p>Sono presentati 2 indici di pericolosità per questa grandezza:</p>
        <ul>
          <li>
            l’indice di frequenza stagionale di valori massimi giornalieri di raffica superiori a Beaufort 7 (13,9 m/s), indicato nel seguito come WG_B7. Questo indice rappresenta
            eventi che possono causare danni alle colture erbacee, quali l’allettamento.
          </li>
          <li>
            l’indice di frequenza stagionale di 90°percentile giornaliero di raffica superiore a Beaufort 9 (20,8 m/s), indicato nel seguito come WG_B9. Questo indice rappresenta
            eventi che possono causare danni alle colture arboree, come la caduta di rami o l’abbattimento, e danni alle strutture agrarie. In questo caso si è preferito utilizzare
            il 90° percentile giornaliero perché indica che nel corso del giorno in esame le raffiche si sono verificate per più ore, aumentando la probabilità di causare danni.
          </li>
        </ul>
        <p>Per ciascun indice sono stati calcolati i valori medi sul periodo di riferimento.</p>
        <h3>Classificazione</h3>
        <p>
          Per ciascun indice sono state definite 3 classi di frequenza associate a rischio basso, medio ed alto di danni alle colture e alle strutture aziendali causati da queste
          tipologie di eventi.
        </p>
        <span>Indice WG_B7:</span>
        <ul>
          <li>
            una soglia tra classe di rischio bassa e media pari a 5 eventi per stagione. Eventi con intensità superiore a B7 possono causare in alcuni casi danni alle colture
            arboree solo in alcune fasi della vita delle piante e quindi anche una frequenza di 5 eventi nel corso della stagione può portare entità limitate di danni alle colture.
          </li>
          <li>
            una soglia tra classe di rischio media e alta pari a 20 eventi per stagione. La presenza di un grande numero di eventi in ogni stagione può comportare un innalzamento
            della probabilità che alcuni si verifichino nel periodo in cui le piante sono più sensibili e quindi aumentare la probabilità di danni.
          </li>
        </ul>
        <span>Indice WG_B9</span>
        <ul>
          <li>
            una soglia tra classe di rischio bassa e media pari a una frequenza di 0,2 eventi per stagione annui, equivalente in media ad un evento ogni 5 anni. Questi eventi sono
            particolarmente dannosi e il verificarsi di un evento può causare danni significativi alle piante e alle strutture in campo (es. serre).
          </li>
          <li>
            una soglia tra classe di rischio media e alta pari a 1 evento per stagione l’anno. Se nell’area mediamente ogni anno si osservano eventi di questa categoria ne va
            tenuto conto nella pianificazione e nella scelta delle strategie di coltivazione.
          </li>
        </ul>
        <h3>Limitazioni</h3>
        <p>
          I dati di raffica oraria ERA5 sono stati confrontati con i dati osservati presso alcune stazioni agro-meterologiche presenti in Emilia-Romagna su tutto il periodo
          1991-2020. I confronti fra le frequenze medie di eventi appartenenti a diverse classi Beaufort hanno mostrato che in pianura si nota una generale lieve sovrastima degli
          eventi con intensità delle classi Beaufort centrali (tra 4 e 7) e una generale lieve sottostima della frequenza di eventi di classe Beaufort 8 e 9, che per queste aree
          sono comunque piuttosto rari.
        </p>
      </div>
    </div>
  );
};

export default InfoVento;
