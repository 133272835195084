import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API_ENDPOINT, getBackendData, getBackendErrorCode, hasBackendResponseErrors, sendGet } from '../../utils/api';
import { AxiosResponse } from 'axios';
import { loadAllRisksErrorAct, loadAllRisksSuccessAct } from './actions';
import { AllRisksAction } from '../../types/actions/all-risks-action';

export function* LoadAllRisksCall(action: any) {
  try {
    let requestURL = `${API_ENDPOINT}/api/risks`;
    let res: AxiosResponse<any> = yield call(sendGet, requestURL);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res)
      yield put(loadAllRisksErrorAct(errCode));
    } else {
      const userInfoData = getBackendData(res);
      yield put(loadAllRisksSuccessAct(userInfoData));
    }

  } catch (err) {
    yield put(loadAllRisksErrorAct());
  }
}

export function* watchAllRisksCall() {
  yield takeEvery([AllRisksAction.LOAD_ALL_RISKS], LoadAllRisksCall)
}

export function* allRisksSaga() {
  yield all([fork(watchAllRisksCall)])
}

export default allRisksSaga;
