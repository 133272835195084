import { Link } from "react-router-dom";
import { selectRegionalHazard } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  privacyCheckOnOffAct,
  userInputSetSectorsAct,
} from "../../store/regionalHazardArea/actions";
import { SectorTypeEnum } from "../../types/commonTypes";
import GoToSearchLocation from "./GoToSearchLocation";
import PrivacyCheckCmp from "./PrivacyCheckCmp";
import SectorsCmp from "./SectorsCmp";
import { isAuthenticated } from "../../utils/api";

const SectorsStep = () => {
  const hazardState = useAppSelector(selectRegionalHazard);
  const { userInput } = hazardState;
  const { privacyChecked } = userInput;
  const dispatch = useAppDispatch();

  return (
    <>
      <div className="wizard-step sector-step">
        <p className="main-msg">Benvenuto su ADA Tool</p>
        <p className="sub-msg">
          Scopri subito a quali rischi sono esposte le tue zone, e quelle che possono essere le azioni da applicare alla tua filiera per prevenire i danni.
        </p>

        <p className="input-label domain-label">Per quale filiera vuoi effettuare la ricerca?</p>
        <SectorsCmp
          sectors={userInput.sectors}
          setSectors={(localSectorsUp: SectorTypeEnum[]) => dispatch(userInputSetSectorsAct(localSectorsUp))} />

        <PrivacyCheckCmp
          privacyChecked={privacyChecked}
          setPrivacyCheckedHandler={() => dispatch(privacyCheckOnOffAct(!privacyChecked))}
        />
        {!isAuthenticated() && <p className="info-label" style={{ marginBottom: '24px' }}>Hai già salvato la tua zona? <Link to="/accedi">Accedi all’area personale</Link></p>}
        {<GoToSearchLocation />}
      </div>
    </>
  );
};

export default SectorsStep;
