export enum PersonalAreaAction {
    LOAD_AREAS = '@@personalArea/LOAD_AREAS',
    LOAD_AREAS_SUCCESS = '@@personalArea/LOAD_AREAS_SUCCESS',
    LOAD_AREAS_ERROR = '@@personalArea/LOAD_AREAS_ERROR',
    SAVE_AREA = '@@personalArea/SAVE_AREA',
    SAVE_AREA_SUCCESS = '@@personalArea/SAVE_AREA_SUCCESS',
    SAVE_AREA_ERROR = '@@personalArea/SAVE_AREA_ERROR',
    DELETE_AREA = '@@personalArea/DELETE_AREA',
    DELETE_AREA_SUCCESS = '@@personalArea/DELETE_AREA_SUCCESS',
    DELETE_AREA_ERROR = '@@personalArea/DELETE_AREA_ERROR',
    LOAD_PLAN_ACTIONS = '@@personalArea/LOAD_PLAN_ACTIONS',
    LOAD_PLAN_ACTIONS_SUCCESS = '@@personalArea/LOAD_PLAN_ACTIONS_SUCCESS',
    LOAD_PLAN_ACTIONS_ERROR = '@@personalArea/LOAD_PLAN_ACTIONS_ERROR',
    ADD_DELETE_ACTION = '@@personalArea/ADD_DELETE_ACTION',
    ADD_DELETE_ACTION_SUCCESS = '@@personalArea/ADD_DELETE_ACTION_SUCCESS',
    ADD_DELETE_ACTION_ERROR = '@@personalArea/ADD_DELETE_ACTION_ERROR',
    LOAD_REGIONAL_CLIMATE = '@@personalArea/LOAD_REGIONAL_CLIMATE',
    LOAD_REGIONAL_CLIMATE_SUCCESS = '@@personalArea/LOAD_REGIONAL_CLIMATE_SUCCESS',
    LOAD_REGIONAL_CLIMATE_ERROR = '@@personalArea/LOAD_REGIONAL_CLIMATE_ERROR',
    LOAD_RISKS_ANALYSIS = '@@personalArea/LOAD_RISKS_ANALYSIS',
    LOAD_RISKS_ANALYSIS_SUCCESS = '@@personalArea/LOAD_RISKS_ANALYSIS_SUCCESS',
    LOAD_RISKS_ANALYSIS_ERROR = '@@personalArea/LOAD_RISKS_ANALYSIS_ERROR',
    CHANGE_PASSWORD = '@@personalArea/CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS = '@@personalArea/CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_ERROR = '@@personalArea/CHANGE_PASSWORD_ERROR',
    MODIFY_ORGANIZATIONS = '@@personalArea/MODIFY_ORGANIZATIONS',
    MODIFY_ORGANIZATIONS_SUCCESS = '@@personalArea/MODIFY_ORGANIZATIONS_SUCCESS',
    MODIFY_ORGANIZATIONS_ERROR = '@@personalArea/MODIFY_ORGANIZATIONS_ERROR',
}