import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { LevelEnum } from "../../types/commonTypes";
import { riskColor, seasonTranslator } from "../../utils/utils";
import { stagioneDiRiferimentoBlock } from "../../utils/utils-cmp";

import arrowUpIcon from "../../assets/images/arrow-up.png";
import arrowDownIcon from "../../assets/images/arrow-down.png";
import dotIcon from "../../assets/images/dot.png";
import { convertLevelsToArray, RisksStatsItem, RisksStatsItemObj } from "../../types/personalAreaType";
import { useEffect, useMemo, useState } from "react";

type RiskReportCardProps = {
  risksData: RisksStatsItem | null;
  text?: string;
  additionalTextClass?: string;
  additionalDangerLabel?: string
  subText?: string;
  icon: {
    img: string;
    altText: string;
  };
};

const RiskReportCard = (props: RiskReportCardProps) => {
  const { risksData, icon, additionalTextClass, text, subText } = props
  const totalProducers = +(risksData?.totalProducers || 1)
  const [season, setSeason] = useState('');

  const seasonHandler = (e: any) => {
    setSeason(e?.target?.value);
  };

  const _levels = useMemo(() => {
    const currSeasonLevels = risksData?.elements.find((el: RisksStatsItemObj) => el.label?.toUpperCase() === season?.toUpperCase())
    return convertLevelsToArray(currSeasonLevels?.values || [])

  }, [season])

  const selectOptionsCmp = useMemo(() => {
    if (!risksData?.elements?.length) {
      return []
    }
    return risksData?.elements
      .map(el => el.label)
      .map((period: string, index: number) => (
        <option value={period || ''} key={period + index}>
          {seasonTranslator(period)}
        </option>
      ))
  }, [risksData?.elements?.length])

  useEffect(() => {
    if (!risksData?.elements?.length) {
      return
    }
    setSeason(risksData?.elements[0].label)
  }, [risksData?.elements?.length])
  

  const N_SEGMENTS = totalProducers;
  const SPACE_BETWEEN = N_SEGMENTS < 6 ? 0.015 : 0.013;

  let progressRotation = 0
  const progressCircle = _levels?.map((level, index) => {
    const SEGMENT_SPACE = (+level.value) / totalProducers;
    const coloredValue = SEGMENT_SPACE - SPACE_BETWEEN * 2;

    const rotation = progressRotation;
    progressRotation += SEGMENT_SPACE
    const _riskColor = riskColor(level.label?.toUpperCase())

    return (
      <div className="CircularProgressbar-container" key={level.label + icon.altText + index}>
        <CircularProgressbar
          className={"ada-circular-progress"}
          value={coloredValue}
          maxValue={1}
          strokeWidth={6}
          styles={buildStyles({
            rotation: rotation,
            trailColor: "transparent",
            pathColor: _riskColor,
          })}
        />
      </div>
    );
  });

  const levelalDangerLevel = _levels?.map((level) => {
    const riskIconText = {
      icon: "",
      altText: "",
      text: "",
    };
    switch (level.label?.toUpperCase()) {
      case LevelEnum.HIGH:
        riskIconText.icon = arrowUpIcon;
        riskIconText.altText = "Icona freccia verso l'alto";
        riskIconText.text = "Alta";
        break;
      case LevelEnum.MEDIUM:
        riskIconText.icon = dotIcon;
        riskIconText.altText = "Icona punto arancione";
        riskIconText.text = "Media";
        break;
      case LevelEnum.LOW:
        riskIconText.icon = arrowDownIcon;
        riskIconText.altText = "Icona freccia verso il basso";
        riskIconText.text = "Bassa";
        break;
    }
    return (
      <div className="season-level" key={level.label + icon.altText}>
        <div className={"risk-color-box " + riskIconText.text}>
          <img src={riskIconText.icon} alt={riskIconText.altText} />
          <p>{riskIconText.text}</p>
        </div>
        <div className="risks-numbers-item">
          <p className="risks-numbers"><span className="val">{`${level.value}`}</span>
            {` di ${totalProducers}`}</p>
        </div>
      </div>
    );
  });

  return (
    <div className="seasonal-report-card-container">
      {stagioneDiRiferimentoBlock(seasonHandler, 1, 'Periodo di riferimento', selectOptionsCmp)}
      <div className="progress-circle-container">
        {progressCircle}
        <img src={icon.img} alt={icon.altText} />
      </div>
      <h3 className={`${additionalTextClass}`}>{text || ""}</h3>
      <p>{subText || ""}</p>
      <div className="separator-line-gray"></div>
      <div className="upper-footer">
        <h4>{`Pericolosità ${props.additionalDangerLabel || ""}`}</h4>
        <div className="danger-level">{levelalDangerLevel}</div>
      </div>
    </div>
  );
};

export default RiskReportCard;
