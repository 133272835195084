import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Loader from "../../UI/Loader/Loader";
import {
  getBackendErrorCode,
  hasBackendResponseErrors,
  API_ENDPOINT,
} from "../../utils/api";
import axios from "axios";
import { RetrivePswdStep } from "../../utils/utils";
import { getErrorMessage } from "../../utils/errors.utils";
import { UserTypeEnum } from "../../types/commonTypes";

interface PswdStepProps {
  setEmail: Function;
  setCurrestStep: (step: RetrivePswdStep) => void;
  closeModal: Function;
  userType: UserTypeEnum;
}

function PswdStepInsertEmail(props: PswdStepProps) {
  const { setCurrestStep, setEmail, closeModal, userType } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const submitRequest = async (email: string) => {
    try {
      setLoading(true);
      setError(false);

      let requestURL = `${API_ENDPOINT}/api/retrive-pswd?email=${email}&userType=${userType}`;
      const res = await axios.get(requestURL);
      if (hasBackendResponseErrors(res)) {
        const errCode = getBackendErrorCode(res);
        setErrorMsg(getErrorMessage(errCode));
        setError(true);
      } else {
        setCurrestStep(RetrivePswdStep.INSERT_CODE);
      }
    } catch (err) {
      setErrorMsg(getErrorMessage());
      setError(true);
    } finally {
      setEmail(email);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      {error && <p>{errorMsg}</p>}
      <>
        <p className="modal-description">
          Inserisci la mail che hai inserito in fase di registrazione, ti
          invieremo un link per accedere di nuovo al tuo account.
        </p>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email("Formato email non valido")
              .required("Campo obbligatorio"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            submitRequest(values.email);
          }}
        >
          <Form>
            <div className="input-wrapper">
              <label htmlFor="email">Email</label>
              <Field className="text-input-modal" name="email" type="email" />
              <ErrorMessage name="email" />
            </div>

            <button className="ada-btn w-100 margin-y-medium" type="submit">
              Avanti
            </button>

            <button
              className=" ada-btn transparent-btn w-100"
              onClick={() => closeModal()}
              style={{ color: "#667085" }}
            >
              Annulla
            </button>
          </Form>
        </Formik>
      </>
    </>
  );
}

export default PswdStepInsertEmail;
