import { useEffect, useState } from "react";
import "../../pages/Auth.scss";
import FeedbackModal from "./FeedbackModal";
import './FeedbackCmp.scss'
import { startFeedbackMechanism } from "../../utils/utils.feedback";
import { useLocation } from "react-router-dom";
import { increasePageViewedCountForFeedback_inLO } from "../../utils/utils.store";

function FeedbackCmp() {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const location = useLocation();

  useEffect(() => {
    startFeedbackMechanism(setFeedbackModalOpen)
  }, [])

  useEffect(() => {
    increasePageViewedCountForFeedback_inLO()
}, [location]);

  return (
    <>
      <FeedbackModal
          isOpen={feedbackModalOpen}
          setModalOpen={setFeedbackModalOpen}
        />
    </>
  );
}

export default FeedbackCmp;
