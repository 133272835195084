import WhiteHeader from "../../components/WhiteHeader/WhiteHeader";

import { useHistory } from "react-router-dom";
import useScrollToTop from "../../hooks/useCrollToTop";

const InfoRain = () => {
  const history = useHistory();

  const goToDrawAreaHandler = () => {
    history.goBack();
  };
  useScrollToTop();

  return (
    <>
      <div className="info-page">
        <WhiteHeader title="Info precipitazioni" goBackCb={goToDrawAreaHandler} />
        <div className="text-container  max-1480-container">
          <h2>Mappe climatiche: presente (1971-2000)</h2>
          <h3>Sorgente dati:</h3>
          <p>
            I dati utilizzati per produrre le mappe climatiche dei principali indicatori climatici di progetto appartengono all’archivio europeo E-OBS versione 22 (
            <a href="https://www.ecad.eu/download/ensembles/download.php" target="_blank" rel="noreferrer">
              INFO
            </a>
            ), periodo 1950-2020. Gli indicatori climatici a scala nazionale sono stati calcolati partendo dai dati giornalieri di temperatura minima, massima e quantità di
            precipitazione del data set E-OBS, disposti su una griglia regolare con una risoluzione spaziale di 0.1°x0.1°, e copre l’area Europea (25°N-71.5°N e 25°W-45°E). Da
            questa finestra europea, sono stati selezionati e presi in considerazione solo i punti di griglia che coprono il territorio nazionale.
          </p>
          <h3>Periodo di riferimento:</h3>
          <p>Il periodo climatico di riferimento per le elaborazioni delle mappe climatiche è 1971-2000.</p>
          <h3>Indici climatici:</h3>
          <ul>
            <li>precipitazione stagionale (mm): quantità totale di precipitazione registrata durante l’inverno, la primavera, l’estate e l&#39;autunno.</li>
          </ul>
          <p>
            Le stagioni sono definite in questo modo: inverno include i mesi di dicembre, gennaio, febbraio; primavera include i mesi di marzo, aprile, maggio; estate include i
            mesi di giugno, luglio, agosto; autunno include i mesi di ottobre, novembre e dicembre.
          </p>
          <h2>Mappe di proiezione di cambiamento climatico: futuro (2021-2050, scenario emissivo RCP4.5)</h2>
          <h3>Quadro informativo: dati e metodi</h3>
          <p>
            Le proiezioni di cambiamento climatico per il progetto ADA sono state costruite mediante uno schema di regionalizzazione statistica sviluppata da Arpae-Simc. La
            regionalizzazione statistica è di tipo Perfect-Prog e consiste in una regressione multivariata basata sulla tecnica delle correlazioni canoniche (CCAReg). Questa
            tecnica consente di massimizzare la correlazione tra le serie temporali di grandezze atmosferiche di larga scala e le serie temporali di grandezze a scala
            nazionale/locale (Tomozeiu et al., 2014, 2018).
          </p>
          <p>
            La costruzione e la validazione dello schema di regionalizzazione statistica, primo step procedurale, coinvolge grandezze atmosferiche derivate dalle ri-analisi
            dell&#39;ECMWF (
            <a href="https://apps.ecmwf.int/archive-catalogue/" target="_blank" rel="noreferrer">
              LINK
            </a>
            ), mentre i patterns nazionali/locali sono costruiti con i dati di temperatura minima, massima e precipitazione afferenti all’analisi E-OBS versione22 (
            <a href="https://www.ecad.eu/download/ensembles/download.php" target="_blank" rel="noreferrer">
              LINK
            </a>
            ), sul periodo 1958- 2013.
          </p>
          <p>
            La modellazione delle proiezioni future a livello nazionale/locale, secondo step procedurale, viene effettuata con l’applicazione della regressione multivariata
            selezionata in precedenza ai pattern atmosferici futuri simulati dai modelli climatici globali (GCMs) afferenti al progetto Coupled Model Intercomparison Project 5
            (Cmip5) del World Climate Research Programme (
            <a href="https://esgf-node.llnl.gov/projects/cmip5/" target="_blank" rel="noreferrer">
              LINK
            </a>
            ), ottenute nell’ambito dello scenario emissivo RCP4.5. I seguenti modelli climatici globali (GCMs) sono stati utilizzati come input nella schema di regionalizzazione
            statistica CCAReg: CMCC-CM, MPI-ESM-MR, CNRM-CM5, Can-ESM2, INM-CM4, NOR-ESM1, per il periodo di controllo 1971-2000 e periodo futuro 2021-2050.
          </p>
          <p>
            Per avere una maggiore robustezza statistica, i cambiamenti climatici a scala nazionale ottenuti con lo schema CCAReg per ciascuno dei modelli climatici globali del
            progetto Cmip5 sono stati combinati insieme con la tecnica dell’Ensemble Mean (EM).
          </p>
          <p>I cambiamenti dell’Ensemble Mean per ogni indicatore/stagione sono resi disponibili all’interno del progetto(EM).</p>
          <p>
            Le proiezioni di cambiamento climatico sono a livello stagionale e riguardano gli seguenti indicatori climatici: temperature minima, temperatura massima e la quantità
            di precipitazione.
          </p>
          <h3>Orizzonte temporale e scenario di emissione:</h3>
          <p>
            Le proiezioni di cambiamento climatico sono a livello nazionale, elaborate per il periodo 2021-2050 rispetto al periodo climatico di riferimento 1971-2000, nell’ambito
            dello scenario emissivo RCP4.5. Lo scenario emissivo RCP4.5 è uno scenario intermedio di stabilizzazione, che prevede alcune iniziative di controllo e riduzione delle
            emissioni di gas serra.
          </p>
          <h3>Indicatori:</h3>
          <ul>
            <li>
              cambiamento climatico della precipitazione stagionale (mm): variazione media della quantità di precipitazione stagionale nel periodo 2021-2050 rispetto al periodo di
              riferimento 1971-2000, nell’ambito dello scenario emissivo RCP4.5.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default InfoRain;
