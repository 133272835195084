import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Loader from "../UI/Loader/Loader";
import PrivacyCheckCmp from "../components/Sectors/PrivacyCheckCmp";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import { selectAuth } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getErrorMessage, toastDefaultProperties } from "../utils/errors.utils";
import "./Auth.scss";
import { registerOrgAct } from "../store/auth/actions";

const saveFormDataToLocalStorage = (data: any) => {
    localStorage.setItem("registrationFormData", JSON.stringify(data));
};

const loadFormDataFromLocalStorage = () => {
    const data = localStorage.getItem("registrationFormData");
    return data ? JSON.parse(data) : null;
};

const SaveDataOnExit = () => {
    const formik: any = useFormikContext();
    const history = useHistory();

    useEffect(() => {
        saveFormDataToLocalStorage({
            organizationName: formik.values.organizationName,
            name: formik.values.name,
            surname: formik.values.surname,
            email: formik.values.email,
            message: formik.values.message,
            conditionsChecked: formik.values.conditionsChecked,
        });
    }, [formik, history]);

    return null;
};

const OrgRegistrationPage = () => {
    let history = useHistory();
    const dispatch = useAppDispatch();
    const authState = useAppSelector(selectAuth);
    const { registrationData, registerOrgData } = authState;

    const isLoading = registerOrgData?.metadata?.isLoading;

    const [conditionsChecked, setConditionsChecked] = useState<boolean>(
        registrationData?.conditionsChecked || false
    );

    const clickBackHandler = () => {
        history.goBack();
    };

    useEffect(() => {
        if (registrationData?.metadata.error) {
            toast.error(
                getErrorMessage(registrationData?.metadata.errorCode),
                toastDefaultProperties
            );
        }
    }, [registrationData?.metadata.error]);

    const conditionsClickHandler = (e: any) => {
        e.preventDefault();

        setConditionsChecked(!conditionsChecked);
    };

    return (
        <>
            {isLoading && <Loader isGlobal />}
            <div className="page-cmp register-page">
                <WhiteHeader goBackCb={clickBackHandler} title={"Registrazione organizzazione"} />

                <Formik
                    initialValues={loadFormDataFromLocalStorage() || {
                        organizationName: "",
                        name: "",
                        surname: "",
                        email: "",
                        message: "",
                        conditionsChecked: false,
                    }}
                    validationSchema={Yup.object({
                        organizationName: Yup.string()
                            .max(15, "Deve contenere massimo 15 caratteri")
                            .required("Campo obbligatorio"),
                        name: Yup.string()
                            .required("Campo obbligatorio"),
                        surname: Yup.string()
                            .required("Campo obbligatorio"),
                        email: Yup.string()
                            .email("Indirizzo email non valido")
                            .required("Campo obbligatorio"),
                        message: Yup.string()
                            .required("Campo obbligatorio"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        dispatch(registerOrgAct({ registerData: values, history }))
                        setSubmitting(false)
                    }}
                >
                    <Form>
                        <SaveDataOnExit />
                        <div className="input-wrapper padding-y-medium">
                            <label htmlFor="organizationName">Nome organizzazione</label>
                            <Field className="text-input" name="organizationName" type="text" />
                            <ErrorMessage name="organizationName" />
                        </div>

                        <div className="input-wrapper padding-y-medium">
                            <label htmlFor="name">Nome responsabile</label>
                            <Field className="text-input" name="name" type="text" />
                            <ErrorMessage name="name" />
                        </div>

                        <div className="input-wrapper padding-y-medium">
                            <label htmlFor="surname">Cognome responsabile</label>
                            <Field className="text-input" name="surname" type="text" />
                            <ErrorMessage name="surname" />
                        </div>

                        <div className="input-wrapper padding-y-medium">
                            <label htmlFor="email">Email</label>
                            <Field className="text-input" name="email" type="email" />
                            <ErrorMessage name="email" />
                        </div>

                        <div className="input-wrapper padding-y-medium">
                            <label htmlFor="message">Come mai vuoi iscrivere la tua organizzazione?</label>
                            <Field className="text-input" name="message" as="textarea" />
                            <ErrorMessage name="message" />
                        </div>

                        <PrivacyCheckCmp
                            privacyChecked={conditionsChecked}
                            setPrivacyCheckedHandler={(e) => conditionsClickHandler(e)}
                            labelTitle="Presa visione informativa privacy e disclaimer"
                            className="registration-conditions"
                        />

                        <ErrorMessage name="conditionsChecked" />

                        <button
                            className="ada-btn w-100"
                            type="submit"
                            disabled={!conditionsChecked}
                        >
                            Registrati
                        </button>
                    </Form>
                </Formik>
            </div>
        </>
    );
};

export default OrgRegistrationPage;
