import {
  faArrowRightFromBracket,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import OrganizationsCmp from "../components/Organizations/OrganizationsCmp";
import Submenu from "../components/Submenu/Submenu";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import { selectAuth, selectPersonalArea } from "../store";
import { logoutAct } from "../store/auth/actions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { isAuthenticated } from "../utils/api";
import "./PersonalArea.scss";
import "./SettingsPage.scss";
import { modifyOrganizationsAct } from "../store/personalArea/actions";
import Loader from "../UI/Loader/Loader";
import { isOrganization } from "../utils/utils";

const SettingsPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authState = useAppSelector(selectAuth);
  const personalArea = useAppSelector(selectPersonalArea)
  const { userAuthData } = authState;
  const isOrg = isOrganization(userAuthData)
  const isLoading = personalArea?.modifyOrganizationsMetadata?.isLoading

  const goToHome = () => {
    history.goBack();
  };

  const [localOrganizations, setLocalOrganizations] = useState<string[]>(
    userAuthData?.organizations || []
  );

  const handleOrganizations = (orgs: Array<string>) => {
    dispatch(modifyOrganizationsAct({ data: { organizations: orgs }, history }))
    setLocalOrganizations(orgs)
  }

  const submenuItems = [
    {
      title: "Cambio password",
      url: "/cambio-password",
    },
    {
      title: "Contatta assistenza",
      url: "/contatta-assistenza",
    },
  ];

  const exitHandler = () => {
    dispatch(logoutAct())
    history.push("/");
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="page-cmp white-page-layout">
        <WhiteHeader goBackCb={goToHome} title="Impostazioni" />

        {!isOrg && (
          <>
            <div className="input-wrapper padding-y-medium">
              <label>Organizzazioni</label>
              <OrganizationsCmp
                classList="text-input"
                organizations={localOrganizations}
                setOrganizations={handleOrganizations}
              />
            </div>
            <p className="paragraph no-padding">
              Seleziona/deseleziona le organizzazioni di cui fai parte per modificarle
            </p>
          </>
        )}

        <Submenu classList="submenu" items={submenuItems} />

        {isAuthenticated() && <div className="exit-container" onClick={exitHandler}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} /> Esci
        </div>
        }
      </div>
    </>
  );
};

export default SettingsPage;
