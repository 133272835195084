import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectE3ci } from "../../store";
import { E3ciItemType } from "../../types/e3ciType";
import { setCurrentE3ciAct } from "../../store/e3ci/actions";

const E3CiStresses = () => {
  const e3ciState = useAppSelector(selectE3ci);
  const { currentE3ci, graphData } = e3ciState;
  const dispatch = useAppDispatch();

  const setCurrentE3ciHandler = (selectedItem: E3ciItemType) => {
    dispatch(setCurrentE3ciAct(selectedItem));
  };

  return (
    <>
      {
        <div className="e3ci-stress-list-section">
          <p className="title">Seleziona l'indice che vuoi visualizzare</p>
          {graphData && (
            <ul className="e3ci-stress-list">
              {graphData.map((gd: E3ciItemType) => {
                const selectedClass = currentE3ci?.code === gd.code ? "selected" : "";
                return (
                  <li key={gd.code} className={`e3ci-stress-list-item ${selectedClass}`} onClick={() => setCurrentE3ciHandler(gd)}>
                    {gd.label}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      }
    </>
  );
};

export default E3CiStresses;
