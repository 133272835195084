import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";
import { connectRouter } from "connected-react-router";
import personalAreaSaga from "./personalArea/sagas";
import { PersonalAreaReducer } from "./personalArea/reducer";
import { RegionalHazardReducer } from "./regionalHazardArea/reducer";
import { RootState } from "../configureStore";
import regionalHazardSaga from "./regionalHazardArea/sagas";
import aoiHazardSaga from "./regionalHazardArea/aoi-sagas";
import { AllRisksReducer } from "./allRisks/reducer";
import allRisksSaga from "./allRisks/sagas";
import { E3ciReducer } from "./e3ci/reducer";
import e3ciSaga from "./e3ci/sagas";
import { AuthReducer } from "./auth/reducer";
import authSaga from "./auth/sagas";

export const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    personalArea: PersonalAreaReducer,
    regionalHazard: RegionalHazardReducer,
    allRisks: AllRisksReducer,
    e3ci: E3ciReducer
  });

export function* rootSaga() {
  yield all([fork(personalAreaSaga)]);
  yield all([fork(regionalHazardSaga)]);
  yield all([fork(aoiHazardSaga)]);
  yield all([fork(allRisksSaga)]);
  yield all([fork(e3ciSaga)]);
  yield all([fork(authSaga)]);
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPersonalArea = (state: RootState) => {
  return state[`personalArea`];
};

export const selectAuth = (state: RootState) => {
  return state[`auth`];
};

export const selectRegionalHazard = (state: RootState) => {
  return state[`regionalHazard`];
};

export const selectAllRisks = (state: RootState) => {
  return state[`allRisks`];
};

export const selectE3ci = (state: RootState) => {
  return state[`e3ci`];
};

