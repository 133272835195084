import React, { useState } from 'react'
import * as XLSX from 'xlsx/xlsx.mjs';
import { UserDataType } from '../../types/authType';
import ModalSlide from '../../UI/ModalSlide/ModalSlide';
import './ExportExcel.scss'
import { statusName } from '../../utils/utils';

interface ExportExcelProps {
    actions: Array<any>,
    authData: UserDataType
}

const ExportExcel = ({ actions, authData }: ExportExcelProps) => {
    const [openModalSavePlan, setOpenModalSavePlan] = useState(false)
    const [companyName, setCompanyName] = useState("")

    const handleDownload = () => {
        let worksheet = XLSX.utils.aoa_to_sheet([["Nome", companyName]]);

        let d = new Date();
        let ye = new Intl.DateTimeFormat('it', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('it', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('it', { day: '2-digit' }).format(d);
        const dateStr = `${da}-${mo}-${ye}`;

        XLSX.utils.sheet_add_aoa(worksheet, [["Data", dateStr]], { origin: "A2" });
        XLSX.utils.sheet_add_aoa(worksheet, [["Soluzione", "URL", "Stato"]], { origin: "A4" });

        actions.forEach((el, index) => {
            console.log(el)
            const _index = index + 5;
            XLSX.utils.sheet_add_aoa(worksheet, [[el.title, `${window.location.origin}${el.url}`, `${statusName[el.state]}`]], { origin: `A${_index}` });
        })

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
        XLSX.writeFile(workbook, `ada_plan_${dateStr}.xlsx`);
    }

    const downloadPlanModal = (
        <ModalSlide
            modalClass="save-plan-modal"
            title="Salva il tuo piano d'adattamento"
            confirmModal={handleDownload}
            closeModal={() => setOpenModalSavePlan(false)}
            cancelModal={() => setOpenModalSavePlan(false)}
            confirmModalText="Scarica"
            confirmButtonDisabled={!authData.companyName}
        >
            <>
                <p className="sub-title">Inserisci il nome della tua azienda e potrai scaricare un excel con la lista delle azioni che hai aggiunto al tuo piano d'adattamento.</p>

                <div className="form-container">
                    <label htmlFor="companyName">Nome azienda</label>
                    <input
                        type="text"
                        name='companyName'
                        className='company-name'
                        onChange={(e) => setCompanyName(e?.target?.value)} />
                </div>
            </>
        </ModalSlide>
    );

    return (
        <>
            <button onClick={() => setOpenModalSavePlan(true)} className='ada-btn'>Esporta in excel</button>
            {openModalSavePlan && downloadPlanModal}
        </>
    )
}

export default ExportExcel