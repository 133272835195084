import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

import MiniNavCard from "../UI/Cards/MiniNavCard";

import leftArrow from "../assets/images/leftArrow.png";
import "./TermsAndConditions.scss";
import { SSL_OP_TLS_BLOCK_PADDING_BUG } from "constants";

const TermsAndCondition = () => {
  let history = useHistory();
  return (
    <div className="terms-condition-container">
      <button className="ada-btn transparent-btn" onClick={() => history.goBack()}>
        <img src={leftArrow} alt="Torna indietro" />
      </button>
      <Switch>
        <Route path="/terms-conditions" exact>
          <h2>Termini e Condizioni</h2>
          <div className="page-body terms">
            <MiniNavCard title="Informativa privacy" linkTo={"/terms-conditions/privacy-disclaimer"} />
            <MiniNavCard title="Disclaimer area legale" linkTo={"/terms-conditions/legal-disclaimer"} />
          </div>
        </Route>
        <Route path="/terms-conditions/privacy-disclaimer">
          <h2>Informativa privacy</h2>
          <div className="page-body">
            <div className="text-container  max-1480-container">
              <strong>PREMESSA</strong>
              <br /><br />
              Gentile Utente,
              <br /><br />
              la presente Privacy Policy ti viene fornita ai sensi dell’art. 13 del Regolamento 2016/679/UE - <i>relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati</i> (di seguito, anche, “il Regolamento” o “GDPR”).
              <br />
              All’interno della presente Privacy Policy troverai le informazioni relative al trattamento dei tuoi dati personali, conseguenti alla navigazione all’interno degli spazi web ed alla fruizione dei servizi messi a tua disposizione per il tramite del sito internet.
              Ti verranno fornite informative specifiche e/o integrative sul trattamento dei tuoi dati personali in ogni occasione in cui li raccoglieremo, nella tua interazione con il sito o in virtù di rapporti contrattuali instaurati con la nostra Società; puoi consultarle tutte in ogni momento cliccando sui link presenti nell’apposita sezione “Informative” in fondo a questa pagina.
              <br /><br />
              <i>Attenzione: la presente Privacy Policy non riguarda i servizi web forniti da terze parti, eventualmente da te fruiti o consultati e raggiunti tramite collegamento ipertestuale (“link”). Al riguardo, ti invitiamo a consultare le informative privacy e le privacy policy fornite dalle suddette terze parti nelle apposite sedi.</i>
              <br /><br /><br />
              <strong>DEFINIZIONI</strong>
              <br /><br />
              <strong>Normativa Privacy</strong>: Il GDPR, il Codice Privacy, i Provvedimenti del Garante e in generale tutta la normativa in materia di protezione delle persone fisiche con riguardo al trattamento di Dati Personali.
              <br /><br />
              <strong>GDPR o Regolamento</strong>: Regolamento dell’Unione Europea 2016/679 del 27 aprile 2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali (Regolamento Generale sulla Protezione dei Dati)
              <br /><br />
              <strong>Dato Personale</strong>: Qualsiasi informazione riguardante una persona fisica identificata o identificabile. Sono da intendere tali, oltre i dati forniti dall’Utente per il tramite di eventuali moduli o form all’interno delle singole aree dei Servizi Web, anche i dati relativi alla sua navigazione
              <br /><br />
              <strong>Interessato o soggetto interessato</strong>: La persona fisica identificata o identificabile cui si riferiscono i Dati Personali.
              <br /><br />
              <strong>Dati di navigazione</strong>: I sistemi informatici e le procedure software preposte al funzionamento dei Servizi Web acquisiscono, nel corso del loro normale funzionamento, alcuni dati la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero permettere, attraverso elaborazioni ed associazioni con dati detenuti da terzi, di identificare gli utenti. Tuttavia, se la sessione di navigazione avviene previo accesso all’Area Riservata (c.d. log in), i dati raccolti sono associati all’account personale dell’Utente.
              <br /><br />
              I dati di navigazione comprendono:
              <ul className="list-margin">
                <li>gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito;</li>
                <li>gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste;</li>
                <li>l'orario della richiesta;</li>
                <li>il metodo utilizzato nel sottoporre la richiesta al server;</li>
                <li>la dimensione del file ottenuto in risposta;</li>
                <li>il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.);</li>
                <li>altri parametri relativi al sistema operativo e all'ambiente informatico dell'Utente.</li>
              </ul>
              <br />
              <strong>Dati forniti dall’Utente</strong>: Sono i dati che l’Utente trasmette volontariamente e consapevolmente tramite l’invio di comunicazioni (ad es., attraverso la posta elettronica, agli indirizzi presenti all’interno del dominio web) o attraverso la compilazione di appositi form, qualora presenti all’interno degli spazi forniti dai Servizi.
              <br /><br />
              I Dati forniti dall’Utente sono solo quelli strettamente necessari alle finalità di volta in volta perseguite dai Servizi (per indicazioni puntuali riguardanti le categorie di dati di volta in volta raccolti, si rinvia alle singole informative privacy di riferimento). In via esemplificativa, possono essere tali i dati:
              <br />
              <ul className="list-margin">
                <li>anagrafici;</li>
                <li>riguardanti gli estremi di contatto (ad es., indirizzo di posta elettronica);</li>
                <li>riferibili alla posizione contrattuale dell’Utente-Cliente;</li>
                <li>di geolocalizzazione (qualora l’Utente abbia espresso un consenso alla raccolta dei dati relativi alla sua ubicazione);</li>
                <li>riguardanti la fruizione dei singoli Servizi messi a disposizione dell’Utente;</li>
                <li>riguardanti fatti e vicende esposte dall’Utente all’interno dei propri messaggi (al riguardo, e per una sua maggior tutela, si invita l’Utente a non fornire informazioni non strettamente pertinenti con l’oggetto della richiesta e la natura dei Servizi forniti dalla Società).</li>
              </ul>
              <br />
              <strong>Titolare del trattamento o il Titolare</strong>: Il soggetto che decide in ordine a finalità e modalità del trattamento dei Dati Personali. In riferimento ai Servizi Web, è la Società del Gruppo Unipol a cui si riferisce questo sito e della quale trovi i riferimenti in calce ad ogni pagina.
              <br /><br />
              <strong>Servizi o Servizi Web</strong>: I servizi erogati tramite la rete internet, fruiti tramite il sito web e/o eventuali APP.
              <br /><br />
              <strong>Utente</strong>: Il soggetto interessato (persona fisica) che naviga, consulta, accede o fruisce dei Servizi Web.
              <br /><br />
              <strong>DPO</strong>: Il Data Protection Officer o Responsabile per la protezione dei dati. L’Utente interessato può richiedere chiarimenti in merito al trattamento dei Dati Personali o esercitare i propri diritti rivolgendosi al DPO, nei modi e nelle forme indicate nella sezione “Come esercitare i diritti e/o richiedere informazioni sul trattamento”.
              <br /><br />
              <strong>Garante Privacy</strong>: Il Garante per la protezione dei dati personali, ovvero l’Autorità di Controllo nazionale italiana in materia di protezione dei dati personali. Consulta il sito web del Garante Privacy.
              <br /><br />
              <strong>Cookies</strong>: I cookie sono informazioni registrate sul tuo dispositivo (ad es., all’interno della memoria del tuo browser) quando visiti un sito web o utilizzi un’applicazione web.
              <br /><br />
              Ogni cookie può contenere diversi dati, come, ad esempio, il nome del server da cui proviene, un identificatore numerico, ecc.
              <br /><br />
              Consulta la Cookie Policy per maggiori informazioni.
              <br /><br /><br />
              <strong>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI DELL’UTENTE</strong>
              <br /><br />
              Ti forniamo di seguito le informazioni utili riguardanti i trattamenti dei Dati Personali svolti per il tramite dei Servizi Web.
              In particolare, vogliamo informarti:
              <ul className="list-margin">
                <li>degli estremi di identificazione e di contatto del Titolare del trattamento;</li>
                <li>degli estremi di contatto del Responsabile per la protezione dei dati (DPO);</li>
                <li>delle categorie di Dati Personali trattati attraverso i Servizi Web;</li>
                <li>delle finalità per cui tali Dati Personali sono di volta in volta trattati;</li>
                <li>dei presupposti che legittimano il trattamento dei suddetti dati (cosiddette basi giuridiche);</li>
                <li>della durata della loro conservazione, sempre strettamente necessario al perseguimento delle finalità dichiarate;</li>
                <li>delle categorie di destinatari della comunicazione dei dati.</li>
              </ul>
              <br />
              <table className="tableTerms">
                <tr>
                  <td>Titolare del trattamento</td>
                  <td>Sede legale</td>
                </tr>
                <tr>
                  <td>UnipolSai Assicurazioni S.p.A.</td>
                  <td>Via Stalingrado 45, 40128 Bologna (BO)</td>
                </tr>
              </table>
              <br />
              <strong>Categorie di Dati Personali, finalità e basi giuridiche del trattamento e termini di conservazione</strong>
              <br /><br />
              <table className="tableTerms">
                <tr>
                  <td>Categorie di Dati Personali</td>
                  <td>Finalità del trattamento</td>
                  <td>Basi giuridiche</td>
                  <td>Termini di conservazione dei dati</td>
                </tr>
                <tr>
                  <td rowSpan={3}>Dati di navigazione</td>
                  <td>Consentire la navigazione web e l’erogazione dei Servizi</td>
                  <td>Necessità di dare esecuzione ad un contratto di cui l’interessato è parte o per erogare un servizio a richiesta dello stesso </td>
                  <td>Per la durata della navigazione all’interno dei servizi</td>
                </tr>
                <tr>
                  <td>Ricavare informazioni statistiche anonime sull'uso dei Servizi Web, al solo fine di controllarne il corretto funzionamento</td>
                  <td>Legittimo interesse della Società</td>
                  <td>I dati raccolti sono aggregati e resi non più riconducibili alla singola utenza che ha effettuato la navigazione</td>
                </tr>
                <tr>
                  <td>Per garantire la sicurezza ed il corretto funzionamento dei Servizi Web, nonché per l'accertamento di responsabilità, in caso di ipotetici reati, ed al fine di tutelare conseguentemente i nostri diritti</td>
                  <td>Legittimo interesse della Società</td>
                  <td>15 gg e successivamente per il tempo strettamente necessario all’eventuale svolgimento delle indagini, alla definizione degli eventuali contenziosi e, in generale, alla tutela dei nostri diritti</td>
                </tr>
                <tr>
                  <td rowSpan={3}>Dati forniti dall’Utente: erogazione dei Servizi Web</td>
                  <td>Registrazione ad ADA Tool e fruizione dei relativi Servizi</td>
                  <td>Necessità di dare esecuzione ad un contratto di cui l’Interessato è parte</td>
                  <td>Per 2 (due) anni dall’ultimo accesso ad ADA Tool</td>
                </tr>
                <tr>
                  <td>Richiesta di informazioni/assistenza</td>
                  <td>Necessità di dare esecuzione a richieste effettuate dall’interessato (fase precontrattuale) o legittimo interesse</td>
                  <td>Il tempo strettamente necessario alla gestione della richiesta</td>
                </tr>
                <tr>
                  <td>Rilevazione della posizione in tempo reale, tramite dati di geolocalizzazione rilevati tramite sensori GPS o tramite analisi dell’indirizzo di rete</td>
                  <td>Consenso</td>
                  <td>Il tempo strettamente necessario all'utilizzo dei Servizi </td>
                </tr>
              </table>
              <br />
              Il conferimento dei tuoi Dati Personali è libero e facoltativo. Ti ricordiamo, tuttavia, che, per il perseguimento di talune finalità (per fornirti gli opportuni riscontri richiesti, per l’iscrizione all’Area Riservata o per l’erogazione di singoli Servizi) è indispensabile; qualora non conferiti, in tali casi, potrebbe non essere possibile procedere col perseguimento delle suddette finalità.
              <br /><br />
              Ti invitiamo, comunque, a consultare le singole informative sul trattamento dei dati per maggiori dettagli.
              <br /><br /><br />
              <strong>Modalità del trattamento e destinatari della comunicazione dei dati</strong>
              <br /><br />
              I dati di cui sopra non saranno soggetti a diffusione e potranno essere conosciuti da collaboratori della nostra Società specificatamente autorizzati a trattarli. Potranno inoltre essere acquisiti e/o trattati da altre società del Gruppo Unipol. Operazioni di trattamento potranno essere svolte da soggetti esterni cui affidiamo lo svolgimento di attività per nostro conto, e con cui stipuliamo appositi accordi finalizzati a disciplinare il trattamento dei dati.
              <br />
              I dati potranno, infine, essere comunicati dietro espressa richiesta ad autorità pubbliche o forze dell’ordine.
              <br />
              Il trattamento dei Dati Personali avviene sempre previa adozione di misure di sicurezza idonee a garantire la riservatezza, la disponibilità e l’integrità dei dati stessi.
              <br /><br /><br />
              <strong>COOKIES</strong>
              <br /><br />
              I Servizi Web possono utilizzare cookie tecnici, analitici e di profilazione, sia di prima che di terze parti.
              <br />
              I cookie sono indispensabili al miglioramento dei Servizi e per fornire prodotti sempre in linea con le preferenze degli Utenti.
              <br />
              L’eventuale utilizzo di cookie di profilazione e/o di terze parti sarà sempre subordinato al rilascio del tuo preventivo consenso.
              <br />
              Per saperne di più, consulta l’informativa sui cookie all’indirizzo <a href="http://www.adatool.eu" target="_blank" rel="noopener noreferrer">www.adatool.eu</a>.
              <br /><br /><br />
              <strong>DIRITTI DELL’UTENTE (IN QUALITÀ DI INTERESSATO)</strong>
              <br />
              La Normativa Privacy (artt. 15-22 del Regolamento) garantisce all’Utente, in qualità di Interessato, il diritto di accedere ai dati che lo riguardano, nonché di ottenerne la rettifica e/o l’integrazione, la cancellazione o la portabilità. La Normativa Privacy attribuisce altresì all’Utente il diritto di richiedere la limitazione del trattamento dei dati e di opporsi al trattamento, oltre la possibilità di revocare il consenso eventualmente prestato (la revoca non pregiudica la liceità del trattamento svolto sino a quel momento).
              <br /><br />
              <table className="tableTerms">
                <tr>
                  <td>Diritti</td>
                  <td>In cosa consiste?</td>
                  <td>Presupposti per l’esercizio</td>
                </tr>
                <tr>
                  <td>Accesso ai dati</td>
                  <td>L’Utente può richiedere al Titolare del trattamento:
                    la conferma che stia trattando dati che lo riguardano;
                    copia dei dati che lo riguardano;
                    informazioni riguardanti il trattamento dei dati (es., basi giuridiche, termini di conservazione, categorie di destinatari dei dati, ecc.)</td>
                  <td>L’Utente può presentare sempre tale richiesta</td>
                </tr>
                <tr>
                  <td>Rettifica o integrazione dei dati</td>
                  <td>L’Utente può richiedere al Titolare del trattamento di:
                    <ul className="list-margin">
                      <li>rettificare</li>
                      <li>aggiornare</li>
                      <li>modificare</li>
                      <li>i Dati Personali trattati</li>
                    </ul>
                  </td>
                  <td>Qualora i dati trattati risultino inesatti o incompleti</td>
                </tr>
                <tr>
                  <td>Cancellazione dei dati</td>
                  <td>L’Utente può richiedere al Titolare del trattamento di cancellare i Dati Personali che sta trattando</td>
                  <td>
                    <ul className="list-margin">
                      <li>i Dati Personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;</li>
                      <li>l’Utente revoca il consenso su cui si basa il trattamento, e se non sussiste altro fondamento giuridico per il trattamento;</li>
                      <li>l’Utente si oppone al trattamento ai sensi dell'art. 21 e non sussiste alcun motivo legittimo prevalente per procedere al trattamento;</li>
                      <li>i Dati Personali sono stati trattati illecitamente;</li>
                      <li>i Dati Personali devono essere cancellati per adempiere un obbligo legale previsto dal diritto dell'Unione o dello Stato membro cui è soggetto il titolare del trattamento</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Limitazione del trattamento dei Dati Personali</td>
                  <td>L’Utente può richiedere al Titolare che questi non svolga, ad accezione della sola conservazione, alcuna operazione di trattamento sui suoi Dati Personali, se non col consenso dell’Utente o per tutelare i propri diritti</td>
                  <td>
                    <ul className="list-margin">
                      <li>l’Utente contesta l'esattezza dei Dati Personali, per il periodo necessario al titolare del trattamento per verificare l'esattezza di tali Dati Personali; </li>
                      <li>il trattamento è illecito e l'interessato si oppone alla cancellazione dei Dati Personali e chiede invece che ne sia limitato l'utilizzo;</li>
                      <li>benché il titolare del trattamento non ne abbia più bisogno ai fini del trattamento, i Dati Personali sono necessari all'interessato per l'accertamento, l'esercizio o la difesa di un diritto in sede giudiziaria;</li>
                      <li>l’Utente si è opposto al trattamento, in attesa della verifica in merito all'eventuale prevalenza dei motivi legittimi del titolare del trattamento rispetto a quelli dell'interessato</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Opposizione al trattamento dei Dati Personali</td>
                  <td>L’Utente può opporsi a trattamenti basati sul legittimo interesse (compreso l’invio di comunicazione promozionali) o su di un interesse pubblico</td>
                  <td>Occorre sussistano motivi connessi alla situazione particolare dell’Utente, tranne se l’opposizione è al trattamento per finalità di marketing diretto</td>
                </tr>
                <tr>
                  <td>Opposizione ad un processo decisionale automatizzato</td>
                  <td>L’Utente può opporsi a processi decisionali automatizzati. Nel caso tale processo sia necessario alla conclusione di un contratto, si basi su di un consenso esplicito, sia autorizzato da legge o regolamento dello Stato o dell’Unione Europea, l’Utente ha il diritto di ottenere l'intervento umano da parte del titolare del trattamento, di esprimere la propria opinione e di contestare la decisione </td>
                  <td>Sussista una decisione basata unicamente sul trattamento automatizzato, compresa la profilazione, che produca effetti giuridici che lo riguardano o che incida in modo analogo significativamente sulla persona dell’Utente</td>
                </tr>
                <tr>
                  <td>Portabilità dei Dati Personali</td>
                  <td>L’Utente ha il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i Dati personali che lo riguardano</td>
                  <td>
                    Purché sussistano tutti i seguenti presupposti:
                    <br /><br />
                    <ul className="list-margin">
                      <li>i dati siano stati forniti dall’Utente;</li>
                      <li>il trattamento si basi sul consenso o su un contratto; </li>
                      <li>il trattamento sia effettuato con mezzi automatizzati
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>Revoca del consenso</td>
                  <td>L’Utente può revocare il consenso prestato. La revoca non pregiudica la liceità del trattamento svolto sino a quel momento</td>
                  <td>Sempre</td>
                </tr>
              </table>
              <br /><br /><br />
              <strong>COME ESERCITARE I DIRITTI E/O RICHIEDERE INFORMAZIONI SUL TRATTAMENTO</strong>
              <br /><br />
              Il “Responsabile per la protezione dei dati” è a disposizione per ogni eventuale dubbio o chiarimento, per l’esercizio dei diritti degli Interessati e per fornire l’elenco aggiornato delle categorie dei destinatari dei dati.
              <br /><br />
              <table className="tableTerms">
                <tr>
                  <td>Responsabile per la protezione dei dati o DPO</td>
                  <td>privacy@unipolsai.it</td>
                </tr>
              </table>
              <br /><br />
              Resta ferma la tua facoltà di rivolgerti al Garante Privacy, anche tramite un reclamo, ove ritenuto necessario per la tutela dei tuoi Dati Personali e dei tuoi diritti in materia.
              <br /><br /><br />
              <strong>INFORMATIVE</strong>
              <br /><br />
              Di seguito riportiamo l’elenco delle informative:
              <br /><br />
              <a href="https://www.adatool.eu/terms-conditions" target="_blank" rel="noopener noreferrer">https://www.adatool.eu/terms-conditions</a>
            </div>
          </div>
        </Route>
        <Route path="/terms-conditions/legal-disclaimer">
          <h2>Disclaimer</h2>
          <div className="page-body">
            <div className="text-container max-1480-container privacy-page">
              <ol>
                <li>Servizi offerti da ADA Tool</li>
                <br />
                ADA Tool è una piattaforma elaborata nell’ambito del Progetto Life ADA di cui UnipolSai Assicurazioni S.p.A. è capofila ed i partner ARPAE Emilia-Romagna, Cia–Agricoltori Italiani, CREA Politiche e Bioeconomia, Festambiente, Legacoop Agroalimentare Nord Italia, Leithà e Regione Emilia-Romagna , utilizzabile all’interno del sito internet www.adatool.eu (di seguito, <strong>“ADA Tool”</strong>).
                <br /><br />
                ADA Tool è uno strumento di autovalutazione che consente all’utente titolare di una impresa agricola (di seguito, l’<strong>“Utente”</strong> e, insieme a UnipolSai Assicurazioni S.p.A., le <strong>“Parti”</strong>) e di ottenere gratuitamente una panoramica sommaria degli impatti del cambiamento climatico sulla propria attività agricola, accrescendo così la propria consapevolezza sull’esposizione ai rischi derivanti da eventi climatici come, ad esempio, siccità, gelate, grandine, vento, temperature massime estreme, temperature minime estreme, precipitazioni. Inoltre, ADA Tool fornisce indicazioni generali per l’implementazione di soluzioni che riducano o prevengano i danni derivanti dal cambiamento climatico che comporranno il suo piano di adattamento a dette modifiche climatiche(di seguito, complessivamente, la <strong>“Valutazione”</strong> o le <strong>“Valutazioni”</strong>), come di seguito meglio descritte:
                <br /><br /><br />
                <li>Attività preliminari</li>
                <br />
                <strong>Prima di usufruire dei servizi della piattaforma, l’Utente è pregato di leggere con attenzione Termini e Condizioni Generali applicabili al fine di essere informato circa diritti e doveri previsti in capo alle Parti. Accedendo e fruendo dei servizi della piattaforma ADA Tool, l’Utente accetta incondizionatamente tali Termini e Condizioni Generali, che UnipolSai Assicurazioni SpA si riserva di modificare in qualsiasi momento a sua propria discrezione.</strong>
                <br /><br />
                Al fine di rendere le Valutazioni, ADA Tool richiederà all’Utente di inserire nella maschera di cui alla pagina www.adatool.eu alcuni dati identificativi e descrittivi dell’impresa agricola di sua proprietà oggetto delle Valutazioni (i.e.geolocalizzazione dell’azienda, filiera agricola gestita, indirizzo email, partita iva, … ).
                Una volta resa la Valutazione, i dati comunicati dall’Utente rimarranno salvanti all’interno dell’Account dell’Utente stesso.
                <br /><br /><br />
                <li>Valutazioni</li>
                <br />
                L’Utente inserirà i dati della propria azienda agricola per consentire a ADA Tool di produrre le Valutazioni, il cui obiettivo è informare l’Utente circa la propria vulnerabilità ai rischi derivanti da eventi climatici.
                <br /><br />
                I valori suggeriti e le indicazioni rese in esito alle Valutazioni richieste dall’Utente sono calcolati seguendo i seguenti criteri obbligatori:
                <br />
                - geolocalizzazione dell’azienda agricola, o della zona interessata;
                <br />
                - indicazione della selezione della/e filiera/e di appartenenza (lattiero casearia, vitivinicola, ortofrutticola).
                <br /><br />
                La Valutazione resa è un elaborato per scopi meramente informativi al fine di dare la possibilità alle aziende di contribuire ad accrescere la propria consapevolezza sulla loro esposizione ai rischi derivanti da eventi climatici estremi.
                Con la Valutazione non viene, inoltre, rilasciata alcuna dichiarazione e/o garanzia, espressa o implicita, in relazione all’accuratezza, alla completezza, alla correttezza o all’equità delle informazioni e dei dati nonché delle opinioni, delle stime e delle proiezioni eventualmente contenute nella Valutazione stessa. Di conseguenza, non viene assunta alcuna responsabilità, diretta e/o indiretta, espressa o implicita, contrattuale, statuaria o di sorta in merito all’ADA Tool e/o alla Valutazione o in relazione a qualsivoglia errore e/o omissioni ivi incluso – a mero titolo esemplificativo e non esaustivo – con riferimento (i) al livello di pericolosità attribuito a seguito della geolocalizzazione degli Utenti ed (ii) all’indica di rischio emerso dalla compilazione del Tool sulla base del livello di pericolosità attribuito a seguito della geolocalizzazione fornita dagli Utenti stessi.
                <br /><br />
                I contenuti della Valutazione sono elaborati sulla base di informazioni e dati ad oggi disponibili, raccolti ed elaborati mediante la compilazione dell’ADA Tool da parte degli Utenti stessi e potranno subire scostamenti – anche rilevanti – o non rispondere alle reali circostanze di fatto tenuto anche conto dei rischi ed eventi meteo-climatici (condizioni economiche e fattori di mercato) e/o, in generale, di fattori esogeni non noti o non prevedibili.
                <br /><br />
                Le Valutazioni non potranno essere utilizzate a scopi commerciali, di profitto, per la creazione di una banca dati o in altri modi atti ad un riutilizzo a fini pubblicitari.
                <br /><br /><br />
                <li>Limitazioni di responsabilità</li>
                <br />
                Non è garantita la continuità del servizio reso da ADA Tool che, di contro, può essere sospeso, interrotto e/o terminato in via definitiva senza alcun preavviso.
                <br /><br />
                ADA Tool non assume alcuna responsabilità con riferimento alle Valutazioni rese all’Utente, trattandosi di valutazioni generiche e non individualizzate, né risponde di eventuali errori. Le Valutazioni rese all’Utente devono essere considerate solo come una indicazione di massima senza alcuna garanzia circa l’idoneità, pertinenza e correttezza delle Valutazioni stesse.
                <br /><br />
                La compilazione dell’ADA Tool ed il rilascio della relativa Valutazione non potranno essere intesi e/o interpretati come assessment, raccomandazione e/o dichiarazione di ragionevolezza, né potranno essere utilizzati da chiunque, ivi inclusi, advisor, esperti o consulenti incaricati dagli Utenti come base per eventuali analisi ed hanno scopo meramente divulgativo né si sostituiscono a quanto richiesto agli Utenti da disposizioni di legge e/o regolamento e/o per ordine di Autorità.
                <br /><br /><br />
                <li>Diritti di proprietà intellettuale</li>
                <br />
                La piattaforma ADA Tool è e rimane di proprietà esclusiva congiuntamente del partenariato del progetto Life ADA.
                <br /><br />
                La piattaforma ADA Tool riporta denominazioni commerciali, testi, immagini, marchi, loghi e, più in generale, proprietà intellettuale congiunta del paternariato. Avvalendosi dei servizi, l’Utente non sarà in alcun caso legittimato a utilizzare la menzionata proprietà intellettuale.
                <br /><br /><br />
                <li>Informativa sulla Privacy</li>
                <br />
                Ai sensi degli articoli 13 e 14 del Regolamento (UE) 2016/679 - Regolamento europeo sulla protezione dei dati (di seguito, il <strong>“Regolamento”</strong>), UnipolSai Assicurazioni S.p.A. desidera informare l’Utente che, per la fruizione dei servizi e delle funzionalità (di seguito, i <strong>“Servizi”</strong>) forniti tramite l’applicazione ADA Tool, saranno trattati alcuni dati personali riferibili all’Utente stesso.
                Titolare del trattamento dei dati personali è UnipolSai Assicurazioni S.p.A. (di seguito, anche, la <strong>“Titolare”</strong>), con sede in Via Stalingrado n. 45 40128 Bologna.
                Saranno trattati le seguenti categorie di dati personali riferibili all’Utente (di seguito, anche, i <strong>“Dati”</strong>):
                <br /><br />
                a) dati anagrafici (nome e cognome);
                b) estremi di contatto (in specie, indirizzo e-mail);
                c) dati di geolocalizzazione rilevati tramite sensori GPS o tramite analisi dell’indirizzo di rete, relativamente ai dati raccolti dal dispositivo mobile dell’Utente.
                <br /><br />
                I Dati saranno trattati per le seguenti finalità:
                <br /><br />
                <table className="tableTerms">
                  <tr>
                    <td>#</td>
                    <td>Finalità</td>
                    <td>Categorie di dati</td>
                    <td>Basi giuridiche</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Fruizione dei Servizi senza registrazione tramite geolocalizzazione</td>
                    <td>c)</td>
                    <td>La base giuridica del trattamento consiste nella necessità di dare esecuzione agli obblighi, di natura contrattuale, conseguenti all’utilizzo di ADA Tool e alla fornitura dei Servizi.
                      La funzionalità di rilevazione della posizione in tempo reale, tramite dati di geolocalizzazione rilevati tramite sensori GPS o tramite analisi dell’indirizzo di rete, relativamente ai dati raccolti dal dispositivo dell’Utente, verrà attivata solo sulla base di un’azione positiva al riguardo da parte dell’Utente.
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Registrazione ad ADA Tool e fruizione dei relativi Servizi</td>
                    <td>a), b), c)</td>
                    <td>Con riguardo ai dati di cui alle lett. a) e b) la base giuridica del trattamento consiste nella necessità di dare esecuzione agli obblighi, di natura contrattuale, conseguenti all’utilizzo di ADA Tool e alla fornitura dei Servizi.
                      Con riguardo ai dati di cui alla lett. c), la base giuridica del trattamento consiste nel consenso libero e facoltativo dell’Utente.
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Gestione di richieste di assistenza </td>
                    <td>a), b)</td>
                    <td>La base giuridica del trattamento consiste nella necessità di dare esecuzione agli obblighi, di natura contrattuale, conseguenti all’invio da parte dell’Utente di una richiesta di assistenza.</td>
                  </tr>
                </table>
                <br /><br />
                6.1 Natura del conferimento dei Dati e termini di conservazione
                <br /><br />
                <table className="tableTerms">
                  <tr>
                    <td>#Finalità</td>
                    <td>Conferimento dei dati</td>
                    <td>Termini di conservazione</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Facoltativo. Il mancato conferimento non pregiudica la fruizione dei Servizi (l’Utente avrà comunque la possibilità di inserire manualmente l’indirizzo di suo interesse)</td>
                    <td>Il tempo strettamente necessario all'utilizzo dei servizi erogati da Ada Tool.</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Necessario. In mancanza, non sarà possibile la fruizione dei Servizi</td>
                    <td>2 (due) anni dall’ultimo accesso ad ADA Tool.</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Necessario. In mancanza, non sarà possibile gestire la richiesta di assistenza dell’Utente</td>
                    <td>Il tempo strettamente necessario alla gestione della richiesta di assistenza.</td>
                  </tr>
                </table>
                <br /><br />
                6.2 Come vengono trattati i Dati
                <br /><br />
                La Titolare tratterà i Dati con modalità e procedure, anche informatiche e telematiche, strettamente necessarie e/o propedeutiche all’erogazione dei Servizi, nonché al conseguimento delle finalità sopra specificate e sempre previa adozione delle misure di sicurezza idonee a garantire la riservatezza, la disponibilità e l’integrità degli stessi.
                <br /><br />
                6.3 Comunicazione dei Dati
                <br /><br />
                Le operazioni di trattamento saranno eseguite dal personale dipendente, autorizzato ed istruito, della Titolare ovvero comunicati alle società del Gruppo Unipol, solo nel perseguimento delle suesposte finalità, nonché ad eventuali società esterne (quali, eventualmente, e solo in via esemplificativa, fornitori della piattaforma telematica, ecc.), cui la Titolare affida attività per suo conto e con cui stipula appositi accordi volti a disciplinare il trattamento dei dati personali.
                I dati relativi ai rischi delle zone salvate dall’Utente all’interno di ADA Tool, ai piani di adattamento e all’area geografica in cui ha sede l’Utente saranno condivisi con le eventuali organizzazioni del settore agricolo di cui l’Utente dichiara di fare parte.
                I Dati non saranno diffusi.
                <br /><br />
                6.4 Diritti dell’interessato
                <br /><br />
                La normativa sulla privacy (articoli 15-22 del Regolamento) garantisce all’interessato il diritto di accedere in ogni momento ai dati che lo riguardano, di richiedere la loro rettifica e/o integrazione, se inesatti o incompleti, la loro cancellazione, se trattati illecitamente, e la portabilità dei dati che costui ha fornito, ove trattati in modo automatizzato per le prestazioni contrattuali richieste, nei limiti di quanto previsto dal Regolamento (articolo 20). La normativa sulla privacy attribuisce all’interessato, altresì, il diritto di richiedere la limitazione del trattamento dei dati, se ne ricorrono i presupposti, e di opporsi al trattamento per motivi legati alla sua situazione particolare.
                Si ricorda che, ove il trattamento dei Dati sia basato sul consenso da espresso dall’Utente, quest’ultimo ha sempre il diritto di revocarlo; la revoca non pregiudica la liceità del trattamento svolto sino a quel momento.
                <br /><br />
                Il Responsabile per la protezione dei dati è a disposizione dell’interessato, per chiarimenti sul trattamento dei suoi dati e per l’esercizio dei suoi diritti: l’interessarlo può contattarlo all’indirizzo di posta elettronica privacy@unipolsai.it.
                Resta fermo il diritto dell’interessato di rivolgersi al Garante Privacy, anche attraverso la presentazione di un reclamo, ove ritenuto necessario, per la tutela dei suoi dati personali e dei suoi diritti.
                <br /><br />
                Le società facenti parte del Gruppo Unipol sono visibili sul sito di Unipol Gruppo S.p.A. (<a href="www.unipol.it" target="_blank">www.unipol.it</a>)
                <br /><br /><br />
                <li>Legge applicabile</li>
                <br />
                Le presenti Condizioni Generali sono state predisposte, e devono conseguentemente essere lette e interpretate, in base alla legge italiana, che ne regola la disciplina in via esclusiva.
                <br />
                I rapporti tra le parti sono regolati dalla legge italiana e in caso di contestazione sarà competente, in via esclusiva, il Foro di Bologna.
              </ol>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default TermsAndCondition;
