
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import E3ciCmp from "../components/E3ci/E3ciCmp";
import { loadE3ciAct } from "../store/e3ci/actions";
import { useAppDispatch } from "../store/hooks";
import "./E3ciPage.scss";

const E3ciPage = () => {
  let history = useHistory();
  const dispatch = useAppDispatch();

  const clickBackHandler = () => {
    history.goBack();
  }

  useEffect(() => {
    dispatch(loadE3ciAct())
  }, [])

  return (
    <>
      <div className="page-cmp page-with-no-header page-with-detail-header e3ci-detail-page">
      <E3ciCmp
          clickBackHandler={clickBackHandler} />
      </div>
    </>
  );
};

export default E3ciPage;
