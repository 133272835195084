import "react-input-code/dist/index.css";
import ReactInputCode from "react-input-code";
import { RetrivePswdStep } from "../../utils/utils";
import { useState } from "react";
import {
  API_ENDPOINT,
  hasBackendResponseErrors,
} from "../../utils/api";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../UI/Loader/Loader";
import { UserTypeEnum } from "../../types/commonTypes";

interface PswdStepProps {
  setCurrestStep: (step: RetrivePswdStep) => void;
  retrivePswdCode: string;
  setRetrivePswdCode: Function;
  openResendMailModal: Function;
  email: string;
  isOtpInvalid: boolean;
  closeModal: Function;
  userType: UserTypeEnum;
}

function PswdStepInsertCode(props: PswdStepProps) {
  const {
    setCurrestStep,
    email,
    retrivePswdCode,
    setRetrivePswdCode,
    openResendMailModal,
    isOtpInvalid,
    closeModal,
    userType
  } = props;
  const [loading, setLoading] = useState(false);

  const submitRequest = async () => {
    setCurrestStep(RetrivePswdStep.INSERT_NEW_PSWD);
  };

  const resendPswdChangeRequestEmail = async () => {
    setRetrivePswdCode("");
    await resendMailApi(email);
  };

  const openToast = () => {
    toast.error("Errore generico", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  };

  const resendMailApi = async (email: string) => {
    try {
      setLoading(true);
      let requestURL = `${API_ENDPOINT}/api/resend-retrive-pswd?email=${email}&userType=${userType}`;
      const res = await axios.get(requestURL);
      if (hasBackendResponseErrors(res)) {
        openToast();
      }
    } catch (err) {
      openToast();
    } finally {
      setLoading(false);
      openResendMailModal(true);
    }
  };

  const validateSubmit = (): boolean => {
    return !!(retrivePswdCode && retrivePswdCode.length === 6);
  };

  return (
    <>
      {loading && <Loader />}

      <p className="modal-description">
        Inserisci il codice di accesso a 6 cifre che abbiamo inviato a{" "}
        <b>{email}</b>
      </p>

      <ReactInputCode
        className={`input-otp ${isOtpInvalid ? "otp-error" : ""}`}
        type="text"
        nItems={6}
        value={retrivePswdCode}
        onChange={(val: any) => setRetrivePswdCode(val)}
      />

      {isOtpInvalid && (
        <p className="padding-y-medium error-message">
          Il codice che hai inserito non è corretto
        </p>
      )}

      <div className="resend-pswd-request-email padding-y-medium">
        <p>
          Non hai ricevuto la mail?{" "}
          <a
            href="javascript:void(0)"
            onClick={() => resendPswdChangeRequestEmail()}
          >
            Invia di nuovo
          </a>
        </p>
      </div>

      <div className="divisor-line"></div>

      <button
        className="ada-btn w-100"
        type="submit"
        onClick={submitRequest}
        disabled={!validateSubmit()}
      >
        Avanti
      </button>

      <button
        className=" ada-btn transparent-btn w-100"
        type="submit"
        onClick={() => closeModal()}
        style={{ color: "#667085" }}
      >
        Annulla
      </button>
    </>
  );
}

export default PswdStepInsertCode;
