import { E3ciCodeEnum, MetadataType } from "./commonTypes";

export class E3ciType {
    public currentE3ci?: E3ciItemType;
    public referencePeriodDate: Date = new Date;
    public graphData: E3ciItemType[] = [];
    public metadata: MetadataType = new MetadataType()
    constructor(input?: any,
        _metadata?: any) {
        this.referencePeriodDate.setMonth(this.referencePeriodDate.getMonth() - 5);
        if (input) {
            this.currentE3ci = input.currentE3ci && new E3ciItemType(input.currentE3ci);
            this.referencePeriodDate = input.referencePeriodDate && new Date(input.referencePeriodDate);
            this.graphData = ((input && input.graphData) || []).map((obj: any) => new E3ciItemType(obj))
        }
        if (_metadata) {
            this.metadata = new MetadataType(_metadata)
        }
    }
}

export class E3ciItemType {
    public code: E3ciCodeEnum | null = null;
    public label: string = '';
    public data: any[] = [];
    public description: string = '';
    constructor(input?: any) {
        if (input) {
            this.code = input && input.code;
            this.label = (input && input.label) || '';
            this.data = ((input && input.data) || [])
            this.description = (input && input.description) || '';
        }
    }
}

