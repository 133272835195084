import { RouterState } from "connected-react-router";
import { AllRisksType } from "./allRisksType";
import { E3ciType } from "./e3ciType";
import { PersonalAreaType } from "./personalAreaType";
import { RegionalHazardType } from "./regionalHazardType";
import { CurrentFilter } from "./aoiHazardType";
import { AuthType } from "./authType";

export interface ApplicationState {
  router: RouterState;
  auth: AuthType;
  personalArea: PersonalAreaType;
  regionalHazard: RegionalHazardType;
  allRisks: AllRisksType;
}

export interface ApplicationState {
  router: RouterState;
  personalArea: PersonalAreaType;
  regionalHazard: RegionalHazardType;
  allRisks: AllRisksType;
  e3ci: E3ciType;
  auth: AuthType;
}
export interface ActionType {
  type: string;
  payload?: any;
}

export interface DataUserType {
  username: string;
  usercreatedate: string;
}

export interface ValueLabelOption<T> {
  value: T;
  label: string;
  isExclusive?: boolean;
  groupName?: string;
  disabled?: boolean;
}

export interface LabelArrayOption<T> {
  label: string;
  options: ValueLabelOption<T>[];
  type?: any;
  disabled?: boolean;
}

export interface SelectElementsType {
  all?: boolean;
  id?: number;
  value?: boolean;
}

export interface QueryStringState {
  readonly date: string;
  readonly timeframe: string;
  readonly id: String;
  readonly isUsr: Boolean;
}

export class MetadataType {
  public error: boolean = false;
  public errorCode: any = null;
  public isLoading: boolean = false;

  public resetPagination?: boolean = false;

  constructor(meta?: MetadataType) {
    if (meta) {
      this.error = meta.error;
      this.errorCode = meta.errorCode;
      this.isLoading = meta.isLoading;
      this.resetPagination = meta.resetPagination;
    }
  }
}

export class GeoLocation {
  public latitude: number | undefined;
  public longitude: number | undefined;

  constructor(input?: any) {
    if (input) {
      this.latitude = input.latitude;
      this.longitude = input.longitude;
    }
  }
}

export interface ValueMessageType {
  value?: any;
  message?: string;
}

export type keyValueObj = {
  [key: string]: string;
};

export class ValueLabel {
  public value: string = "";
  public label: string = "";

  constructor(input?: any) {
    if (input) {
      this.value = (input && input.value) || "";
      this.label = (input && input.label) || "";
    }
  }
}

export enum SectorTypeEnum {
  MILK = "MILK",
  GRAPE = "GRAPE",
  FRUIT = "FRUIT",
}

export enum HazardCodeEnum {
  FROST = "FROST",
  DROUGHT = "DROUGHT",
  WIND = "WIND",
  HAIL = "HAIL",
  TMIN = "TMIN",
  TMAX = "TMAX",
  RAIN = "RAIN",
  EXCESS = "EXCESS",
  FLOOD = "FLOOD",
  LOSS = "LOSS",
  WEDGE = "WEDGE",
  EROSION = "EROSION",
  DAMAGES = "DAMAGES",
  EXTREMET = "EXTREME TEMPERATURE",
}

export enum E3ciCodeEnum {
  E3CI = "E3CI",
  HEAT_STRESS = "HEAT_STRESS",
  COLD_STRESS = "COLD_STRESS",
  DROUGHT = "DROUGHT",
  EXTREME_PRECIPITATIONS = "EXTREME_PRECIPITATIONS",
  EXTREME_WIND = "EXTREME_WIND",
}

export enum AnalyticsEvents {
  USER_LANDED = 'USER_LANDED'
}

export const aoiHazardList = [
  HazardCodeEnum.FROST,
  HazardCodeEnum.DROUGHT,
  HazardCodeEnum.WIND,
  HazardCodeEnum.HAIL,
  HazardCodeEnum.EXTREMET,
  HazardCodeEnum.RAIN,
  HazardCodeEnum.TMAX,
  HazardCodeEnum.TMIN,
];

export const mapE3ciCodeEnumToLabel = (e3ciEnum: E3ciCodeEnum | null) => {
  switch (e3ciEnum) {
    case E3ciCodeEnum.E3CI:
      return "E3Ci";
    case E3ciCodeEnum.HEAT_STRESS:
      return "Ondate di caldo";
    case E3ciCodeEnum.COLD_STRESS:
      return "Ondate di freddo";
    case E3ciCodeEnum.DROUGHT:
      return "Siccità";
    case E3ciCodeEnum.EXTREME_PRECIPITATIONS:
      return "Precipitazioni estreme";
    case E3ciCodeEnum.EXTREME_WIND:
      return "Vento estremo";

    default:
      return "";
  }
};

export enum SeasonEnum {
  SUMMER = "SUMMER",
  AUTUMN = "AUTUMN",
  WINTER = "WINTER",
  SPRING = "SPRING",
}

export enum ItalianSeasonEnum {
  SUMMER = "Estate",
  AUTUMN = "Autunno",
  WINTER = "Inverno",
  SPRING = "Primavera",
}

export enum LevelEnum {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export enum SolutionScopeEnum {
  "Suolo" = 1,
  "Acqua",
  "Gestione agronomica",
  "Varietà e sistemi colturali",
  "Benessere animale",
  "Trasformazione",
}

export enum LevelEnumLocalIt {
  HIGH = "ALTA",
  MEDIUM = "MEDIA",
  LOW = "BASSA",
}

export type icon = {
  img: string;
  altText: string;
};

export enum SolutionTableEnum {
  EFFICACY = "EFFICACY",
  DIFFICULTY = "DIFFICULTY",
  SECTORS = "SECTORS",
  COSTS_BENEFITS = "COSTS_BENEFITS",
  APPLICABLE_ACTIONS = "APPLICABLE_ACTIONS",
  TIME_EFFICACY = "TIME_EFFICACY",
  MITIGATION = "MITIGATION",
}

export const DefaultFilters = [
  new CurrentFilter({ name: "Suolo", active: false, isScope: true }),
  new CurrentFilter({ name: "Acqua", active: false, isScope: true }),
  new CurrentFilter({ name: "Gestione agronomica", active: false, isScope: true }),
  new CurrentFilter({ name: "Varietà e sistemi colturali", active: false, isScope: true }),
  new CurrentFilter({ name: "Benessere animale", active: false, isScope: true }),
  new CurrentFilter({ name: "Trasformazione", active: false, isScope: true }),
  new CurrentFilter({ name: "MILK", active: false, isScope: false }),
  new CurrentFilter({ name: "GRAPE", active: false, isScope: false }),
  new CurrentFilter({ name: "FRUIT", active: false, isScope: false }),
];

export enum ErrorCodeEnum {
  GENERIC_SERVER_ERROR = "GENERIC_SERVER_ERROR",
  AOI_DATA_MISSING = "AOI_DATA_MISSING",
}

export enum UserTypeEnum {
  PRODUCER = "PRODUCER",
  ORGANIZATION = "ORGANIZATION",
  ADMIN = "ADMIN"
}

export enum PersonalAreaItemTypeEnum {
  ALL = 'ALL',
  AREA = 'AREA',
  ACTION = 'ACTION'
}

export enum FeedbackStateEnum {
  SUBMITTED = 'SUBMITTED',
  REFUSED = 'REFUSED',
  NOT_EDITED = 'NOT_EDITED'
}

export interface FeedbackInfo {
  state: FeedbackStateEnum
  numberPageViewed: number
}


