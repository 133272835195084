import { useState, useEffect } from "react";
import { selectRegionalHazard } from "../../store";
import "./SectorsSelection.scss";
import { useAppSelector } from "../../store/hooks";
import { SectorTypeEnum } from "../../types/commonTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { sectorEnumToLabel, getSectorIcon } from "../../utils/utils";

type SectorsSelectionProps = {
  sectors: SectorTypeEnum[],
  updateLocalSectors: (sectors: SectorTypeEnum[]) => void;
};

const ALL_SECTORS = [SectorTypeEnum.MILK, SectorTypeEnum.GRAPE, SectorTypeEnum.FRUIT];

const SectorsSelection = (props: SectorsSelectionProps) => {
  const { sectors, updateLocalSectors } = props;

  const isItemSelected = (si: string) => sectors.find((ls) => ls === si);

  const toggleSectorHandler = (si: string) => {
    if (isItemSelected(si)) {
      updateLocalSectors(sectors.filter((ls) => ls !== si));
    } else {
      updateLocalSectors([...sectors, si as SectorTypeEnum]);
    }
  };

  return (
    <>
      <div className="sectors-selection-cmp">
        {ALL_SECTORS.map((si) => {
          const isItemSelectedClass = isItemSelected(si) ? "is-selected" : "";
          return (
            <div className={`sector-item ${isItemSelectedClass}`} key={si} onClick={() => toggleSectorHandler(si)}>
              <div className="icon-name">
                <img src={getSectorIcon(sectorEnumToLabel(si)).img} alt={getSectorIcon(sectorEnumToLabel(si)).altText} />
                <div className="item-name">{sectorEnumToLabel(si)}</div>
              </div>
              <button className={`ada-ck ${isItemSelectedClass}`}>
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SectorsSelection;
