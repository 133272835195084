import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Header from "../UI/Header/Header";
import { selectAllRisks } from "../store";

import "./AllRisksPage.scss";
import { loadAllRisksAct } from "../store/allRisks/actions";
import Loader from "../UI/Loader/Loader";
import AllRisksCmp from "../components/Risks/AllRisksCmp";
import { useHistory } from "react-router-dom";
import { toastDefaultProperties, getErrorMessage } from "../utils/errors.utils";
import { toast } from "react-toastify";

const AllRisksPage = () => {
  const history = useHistory();
  const allRisksState = useAppSelector(selectAllRisks);
  const risks = allRisksState.risks;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (risks.length === 0) {
      dispatch(loadAllRisksAct());
    }
  }, []);

  useEffect(() => {
    if (allRisksState.metadata.error) {
      toast.error(getErrorMessage(allRisksState.metadata.errorCode), toastDefaultProperties);
      history.push(`/`);
    }
  }, [allRisksState.metadata.error]);

  return (
    <>
      <Header />
      <div className="page-cmp page-with-header all-risks-page">
        <div className="max-1480-container">
          <p className="title">Elenco rischi</p>
          <p className="sub-msg">Scopri tutti i rischi e le azioni da poter aggiungere al tuo piano di adattamento.</p>

          {allRisksState.metadata?.isLoading && <Loader />}
          {!allRisksState.metadata?.isLoading && <AllRisksCmp />}
        </div>
      </div>
    </>
  );
};

export default AllRisksPage;
