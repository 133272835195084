import React from 'react'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface FeedbackSuccessMsgCmp {
    closeHandler: any
}

function FeedbackSuccessMsgCmp(props: FeedbackSuccessMsgCmp) {
    return (
        <>
            <div className="feedback-success-modal-body">
                <div className="main-side">
                    <div className="ok-check-icon-container">
                        <FontAwesomeIcon icon={faCircleCheck} />
                    </div>
                    <h3 className="main-msg">Grazie per il feedback</h3>
                </div>
                <p className="secondary-msg">
                    Apprezziamo molto il tuo contributo e faremo del nostro meglio per migliorare la tua esperienza.
                </p>
                <button
                    style={{ color: "#667085" }}
                    className="ada-btn transparent-btn w-100"
                    onClick={props.closeHandler}
                >
                    Chiudi
                </button>
            </div>
        </>
    )
}

export default FeedbackSuccessMsgCmp