import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectE3ci } from "../../store";
import { setReferencePeriodDateaAct } from "../../store/e3ci/actions";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import it from "date-fns/locale/it";
import addMonths from "date-fns/addMonths";
import { forwardRef } from "react";
registerLocale("it", it);

const E3CiReferencePeriod = () => {
  const e3ciState = useAppSelector(selectE3ci);
  const { referencePeriodDate } = e3ciState;
  const dispatch = useAppDispatch();

  const onChangeReferencePeriod = (newDate: Date) => {
    dispatch(setReferencePeriodDateaAct(newDate));
  };

  const DateCustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <button className={`ada-input domain-input btn-select with-icon`} onClick={onClick}>
      {value}
      <FontAwesomeIcon icon={faCaretDown} className="date-icon" />
    </button>
  ));

  return (
    <>
      {
        <div className="e3ci-reference-period-section">
          <p className="title">Modifica il periodo di riferimento</p>
          <div className="date-container">
            <DatePicker
              locale="it"
              selected={referencePeriodDate}
              onChange={onChangeReferencePeriod}
              dateFormat={"MMMM yyyy"}
              showMonthYearPicker
              withPortal
              customInput={<DateCustomInput />}
              minDate={new Date("1981-01-01T03:24:00")} // Gennaio 1981
              maxDate={addMonths(new Date(), -1)}
              showDisabledMonthNavigation
              adjustDateOnChange
            />
          </div>
        </div>
      }
    </>
  );
};

export default E3CiReferencePeriod;
