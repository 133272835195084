import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import './CookieBanner.scss'
import { AnalyticsEvents } from '../../types/commonTypes'
import { Mixpanel } from '../../Mixpanel'
import { hasUserAcceptedCookies } from '../../utils/utils'

const CookieBanner = () => {
  const [closed, setClosed] = useState<boolean>(false)

  const closeCookieBanner = () => {
    setClosed(true)
  }

  const cookieClickHandler = (accepted: boolean) => {
    localStorage.setItem('cookieLaw', JSON.stringify({ accepted }))
    Mixpanel.track(AnalyticsEvents.USER_LANDED);
    closeCookieBanner();
  }

  useEffect(() => {
    const areCookiesAccepted = hasUserAcceptedCookies()
    setClosed(areCookiesAccepted)
  }, [])

  return (
    <>
      {!closed && (
        <div className='cookie-banner'>
          {/* <div className='cookie-banner__close' onClick={() => cookieClickHandler(false)}><FontAwesomeIcon icon={faClose} /></div> */}
          <div className='cookie-banner__content'>
            <p className='cookie-banner__description'>Gentile utente, questo sito fa uso di cookie di terze parti al fine di rendere i propri servizi il più possibile efficienti e semplici da utilizzare.
              <br /><br />
              Utilizzando il tool, acconsenti all'uso dei cookie. Se vuoi saperne di più, <a href="/static/cookie_policy.pdf" target='_blank'>clicca qui.</a></p>
            <button onClick={() => cookieClickHandler(true)} className='cookie-banner__accept-btn ada-btn w-100'>Prosegui</button>
          </div>
        </div>
      )
      }
    </>
  )
}

export default CookieBanner