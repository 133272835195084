import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";
import { Link, useHistory } from "react-router-dom";
import "./PrivacyCheckCmp.scss";

interface PrivacyCheckCmpProps {
  privacyChecked: boolean;
  setPrivacyCheckedHandler: MouseEventHandler<HTMLDivElement>;
  className?: string;
  labelTitle?: string;
}

const PrivacyCheckCmp = (props: PrivacyCheckCmpProps) => {
  const { privacyChecked, setPrivacyCheckedHandler, className, labelTitle } =
    props;
  const privacyCheckedClass = privacyChecked ? "is-selected" : "";
  const history = useHistory();

  return (
    <>
      <div
        className={`privacy-check-container ${className || ""}`}
        onClick={setPrivacyCheckedHandler}
      >
        <button className={`ada-ck ${privacyCheckedClass}`}>
          <FontAwesomeIcon icon={faCheck} />
        </button>
        <div className="label-container">
          {labelTitle && <p className="title">{labelTitle}</p>}
          <p className="info-label privacy-check-label">
            Ho preso visione del trattamento dei dati personali come da{" "}
            <a
              onClick={() =>
                history.push("/terms-conditions/privacy-disclaimer")
              }
              className="privacy-link"
            >
              informativa sulla privacy
            </a>{" "}
            e al{" "}
            <a
              onClick={() => history.push("/terms-conditions/legal-disclaimer")}
              className="disclaimer-link"
            >
              disclaimer dell’area legale
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyCheckCmp;
