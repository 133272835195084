import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import WhiteHeader from "../components/WhiteHeader/WhiteHeader";
import "./ContattaAssistenza.scss";

const ContattaAssistenza = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="page-cmp white-page-layout contatta-assistenza">
      <WhiteHeader goBackCb={goBack} title="Contatta assistenza" />

      <div className="body-container">
        <div className="contatta-assistenza__icon-wrapper">
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
        <h1 className="contatta-assistenza__title">
          Inviaci un email
        </h1>
        <p className="contatta-assistenza__text">
          Ci dispiace sentire che stai incontrando problemi e siamo qui per aiutarti.
          <br /><br />
          Ti invitiamo a contattare il nostro team di assistenza clienti inviando un'email a <strong>info@lifeada.eu</strong>
        </p>
      </div>

      {/* <Formik
        initialValues={{
          name: "",
          surname: "",
          email: "",
          desc: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          return Promise.resolve("");
        }}
      >
        <Form>
          <div className="input-wrapper padding-y-medium">
            <label htmlFor="name">Nome</label>
            <Field className="text-input-white" name="name" type="text" />
            <ErrorMessage name="name" />
          </div>
          <div className="input-wrapper padding-y-medium">
            <label htmlFor="surname">Cognome</label>
            <Field className="text-input-white" name="surname" type="text" />
            <ErrorMessage name="surname" />
          </div>
          <div className="input-wrapper padding-y-medium">
            <label htmlFor="email">Email</label>
            <Field className="text-input-white" name="email" type="email" />
            <ErrorMessage name="email" />
          </div>
          <div className="input-wrapper padding-y-medium">
            <label htmlFor="desc">Quale problema riscontri?</label>
            <Field
              as="textarea"
              className="text-input-white"
              name="desc"
              type="text"
            />
            <ErrorMessage name="desc" />
          </div>
          <button className="ada-btn w-100" type="submit">
            Invia messaggio
          </button>
        </Form>
      </Formik> */}
    </div>
  );
};

export default ContattaAssistenza;
