import { LevelEnumLocalIt, SectorTypeEnum, SolutionTableEnum } from "../../types/commonTypes";
import efficacyLowImg from "./../../assets/images/solution-table/EFFICACY_LOW.svg";
import efficacyMediumImg from "./../../assets/images/solution-table/EFFICACY_MEDIUM.svg";
import efficacyHighImg from "./../../assets/images/solution-table/EFFICACY_HIGH.svg";
import difficultyImg from "./../../assets/images/solution-table/DIFFICULTY.svg";
import difficultyMediumImg from "../../assets/images/solution-table/difficulty_medium.svg";
import difficultyHighImg from "../../assets/images/solution-table/difficulty_high.svg";
import sectorsFruitsImg from "./../../assets/images/solution-table/SECTORS_FRUITS.svg";
import sectorsGrapeImg from "./../../assets/images/solution-table/SECTORS_GRAPE.svg";
import sectorsMilkImg from "./../../assets/images/solution-table/SECTORS_MILK.svg";
import costBenefitsImg from "./../../assets/images/solution-table/COSTS_BENEFITS.svg";
import applicableActionsImg from "./../../assets/images/solution-table/APPLICABLE_ACTIONS.svg";
import timeEfficacyImg from "./../../assets/images/solution-table/TIME_EFFICACY.svg";
import { InfoTableItemType } from "../../types/aoiHazardType";

interface InfoTableIconProps {
  it: InfoTableItemType;
}

const InfoTableIcon = (props: InfoTableIconProps) => {
  const { it } = props;

  const getImageFromCode = (evCode: SolutionTableEnum | null) => {
    switch (evCode) {
      case SolutionTableEnum.EFFICACY:
        let efficacyIcon = efficacyMediumImg;
        if (it.value && it.value.toLowerCase() === LevelEnumLocalIt.LOW.toLowerCase()) {
          efficacyIcon = efficacyLowImg;
        } else if (it.value && it.value.toLowerCase() === LevelEnumLocalIt.HIGH.toLowerCase()) {
          efficacyIcon = efficacyHighImg;
        }
        return <img className="ev-image" src={efficacyIcon} alt="Efficacia soluzione" />;
      case SolutionTableEnum.DIFFICULTY:
        let difficultyIcon = difficultyImg;
        if (it.value && it.value.toLowerCase() === LevelEnumLocalIt.MEDIUM.toLowerCase()) {
          difficultyIcon = difficultyMediumImg;
        } else if (it.value && it.value.toLowerCase() === LevelEnumLocalIt.HIGH.toLowerCase()) {
          difficultyIcon = difficultyHighImg;
        }
        return <img className="ev-image" src={difficultyIcon} alt="Difficoltà soluzione" />;
      case SolutionTableEnum.SECTORS:
        const sectors = it.additionalInfo;
        return (
          <div className="icons-list">
            <div className="icon-item-container veg">
              {sectors && !sectors.includes(SectorTypeEnum.FRUIT) && <div className="opacity-layer"></div>}
              <img className="ev-image" src={sectorsFruitsImg} alt="Filiera ortofrutticola soluzione" />
            </div>
            <div className="icon-item-container wine">
              {sectors && !sectors.includes(SectorTypeEnum.GRAPE) && <div className="opacity-layer"></div>}
              <img className="ev-image" src={sectorsGrapeImg} alt="Filiera vitivinicola soluzione" />
            </div>
            <div className="icon-item-container cheese">
              {sectors && !sectors.includes(SectorTypeEnum.MILK) && <div className="opacity-layer"></div>}
              <img className="ev-image" src={sectorsMilkImg} alt="Filiera lattiero casearia soluzione" />
            </div>
          </div>
        );
      case SolutionTableEnum.COSTS_BENEFITS:
        return <img className="ev-image" src={costBenefitsImg} alt="Costi-benefici soluzione" />;
      case SolutionTableEnum.APPLICABLE_ACTIONS:
        return <img className="ev-image" src={applicableActionsImg} alt="Azione applicabile soluzione" />;
      case SolutionTableEnum.TIME_EFFICACY:
        return <img className="ev-image" src={timeEfficacyImg} alt="Orizzonte efficacia soluzione" />;
      case SolutionTableEnum.MITIGATION:
      default:
        return;
    }
  };

  return (
    <>
      <div className="ev-image-container">{getImageFromCode(it.code)}</div>
    </>
  );
};

export default InfoTableIcon;
