import { faCaretDown, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { statusName } from "../../utils/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./StatusSelectionCmp.scss";
import { forwardRef } from "react";

type StatusSelectionProps = {
    statusList: string[];
    status: string;
    updateStatus: Function;
    expireDate: Date | string;
    setExpireDate: Function;
};

const StatusSelection = (props: StatusSelectionProps) => {
    const { statusList, status, updateStatus, expireDate, setExpireDate } = props;

    const toggleSectorHandler = (selectedStatus: string) => {
        updateStatus(selectedStatus)
    };

    const onChangeReferencePeriod = (newDate: Date | string) => {
        if(typeof(newDate) === 'object') {
            // To counter GMT, we add 4 hours
            (newDate as Date).setHours(newDate.getHours() + 4);
        }
        setExpireDate(newDate);
    };

    const DateCustomInput = forwardRef(({ value, onClick }: any, ref) => (
        <button className={`ada-input domain-input btn-select with-icon`} onClick={onClick}>
            {value}
            <FontAwesomeIcon icon={faCaretDown} className="date-icon" />
        </button>
    ));

    const showDate = (status === "ToSchedule") || (status === "ToRenew")
    const showDateClass = showDate ? 'show' : ''
    return (
        <>
            <div className="status-selection-cmp">
                <p className="status-info">
                    Le tue organizzazioni potranno vedere i rischi delle tue zone salvate.
                </p>
                {statusList.map((el: string) => {
                    const isItemSelectedClass = el === status
                        ? "is-selected"
                        : "";
                    return (
                        <div
                            className={`sector-item ${isItemSelectedClass}`}
                            key={el}
                            onClick={() => toggleSectorHandler(el)}
                        >
                            <button className={`ada-ck ${isItemSelectedClass}`}>
                                <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <div className="item-name">{statusName[el]}</div>
                        </div>
                    );
                })}
                {<>
                    <div className={`expire-date-section ${showDateClass}`}>
                        <p className="expire-date-info">Puoi specificare una data in cui prevedi di implementare l'azione. Ci servirà per aiutarti a ricordartelo in prossimità dell'evento.</p>
                        <div className="date-container">
                            <DatePicker
                                locale="it"
                                selected={expireDate}
                                onChange={onChangeReferencePeriod}
                                dateFormat={"MMMM yyyy"}
                                showMonthYearPicker
                                withPortal
                                customInput={<DateCustomInput />}
                                showDisabledMonthNavigation
                                adjustDateOnChange
                            />
                            <button className="clear-icon-btn"
                                onClick={() => onChangeReferencePeriod('')}>
                                <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                    </div>
                </>
                }

            </div>
        </>
    );
};

export default StatusSelection;
