import {
  LevelEnum,
  SeasonEnum,
  SectorTypeEnum,
  HazardCodeEnum,
  icon,
  UserTypeEnum,
} from "../types/commonTypes";

import windIcon from "../assets/images/wind.png";
import noRaindrop from "../assets/images/no-raindrop.png";
import hailAlt from "../assets/images/hail-alt.png";
import snowIcon from "../assets/images/snow.png";
import rainIcon from "../assets/images/showers.png";
import extremeTIcon from "../assets/images/temperature-increase.png";
import cheeseIcon from "../assets/images/cheese.png";
import wineIcon from "../assets/images/wine.png";
import fruitTIcon from "../assets/images/leaf.png";
import { E3ciItemType } from "../types/e3ciType";
import { OrganizationType, UserDataType } from "../types/authType";
import { HazardSolutionItemType, HazardSolutionTableItemType } from "../types/aoiHazardType";
import { RiskItemType } from "../types/allRisksType";

export const noob = () => { };

export const mapboxGeorevertedFirstAddress = (res: any) => {
  const firstFeature =
    res && res.features && res.features.length > 0 && res.features[0];
  if (firstFeature) {
    let _address = firstFeature.place_name;
    if (!_address) {
      _address = firstFeature.properties && firstFeature.properties.address;
    }
    return _address;
  }
  return null;
};

export const isCurrentStep = (currentStep: any, stepIn: any) => {
  return currentStep === stepIn;
};

export const riskColor = (value: string) => {
  switch (value) {
    case LevelEnum.HIGH:
      value = "#F04438";
      break;
    case LevelEnum.MEDIUM:
      value = "#F79009";
      break;
    case LevelEnum.LOW:
      value = "#87B54C";
      break;
    default:
      return "white";
  }
  return value;
};

export const hazardIcon = (value: string) => {
  let iconValue: icon = { img: "", altText: "" };
  switch (value) {
    case HazardCodeEnum.EXTREMET:
      iconValue = { img: extremeTIcon, altText: "Icona temperature estreme" };
      break;
    case HazardCodeEnum.RAIN:
      iconValue = { img: rainIcon, altText: "Icona precipitazioni" };
      break;
    case HazardCodeEnum.DROUGHT:
      iconValue = { img: noRaindrop, altText: "Icona siccità" };
      break;
    case HazardCodeEnum.FROST:
      iconValue = { img: snowIcon, altText: "Icona neve" };
      break;
    case HazardCodeEnum.WIND:
      iconValue = { img: windIcon, altText: "Icona vento" };
      break;
    case HazardCodeEnum.HAIL:
      iconValue = { img: hailAlt, altText: "Icona grandine" };
      break;
  }
  return iconValue;
};

export const getSectorIcon = (value: string) => {
  let iconValue: icon = { img: "", altText: "" };
  switch (value) {
    case sectorEnumToLabel(SectorTypeEnum.MILK):
      iconValue = { img: cheeseIcon, altText: "Icona settore formaggio" };
      break;
    case sectorEnumToLabel(SectorTypeEnum.GRAPE):
      iconValue = { img: wineIcon, altText: "Icona grappolo d'uva" };
      break;
    case sectorEnumToLabel(SectorTypeEnum.FRUIT):
      iconValue = { img: fruitTIcon, altText: "Icona foglia" };
      break;
  }
  return iconValue;
};

export const seasonTranslator = (season: string) => {
  switch (season.toUpperCase()) {
    case SeasonEnum.SUMMER:
      season = "Estate";
      break;
    case SeasonEnum.AUTUMN:
      season = "Autunno";
      break;
    case SeasonEnum.WINTER:
      season = "Inverno";
      break;
    case SeasonEnum.SPRING:
      season = "Primavera";
      break;
  }
  return season;
};

export const hazardTranslator = (hazard: string) => {
  switch (hazard) {
    case HazardCodeEnum.RAIN:
      hazard = "Precipitazioni";
      break;
    case HazardCodeEnum.EXTREMET:
      hazard = "Temperature estreme";
      break;
    case HazardCodeEnum.WIND:
      hazard = "Vento";
      break;
    case HazardCodeEnum.FROST:
      hazard = "Gelate";
      break;
    case HazardCodeEnum.HAIL:
      hazard = "Grandine";
      break;
    case HazardCodeEnum.DROUGHT:
      hazard = "Siccità";
      break;
  }
  return hazard;
};

export const hazardUrlTranslator = (hazard: string) => {
  switch (hazard) {
    case HazardCodeEnum.RAIN:
      hazard = "precipitazioni";
      break;
    case HazardCodeEnum.EXTREMET:
      hazard = "temperature-estreme";
      break;
    case HazardCodeEnum.WIND:
      hazard = "raffiche";
      break;
    case HazardCodeEnum.FROST:
      hazard = "gelate";
      break;
    case HazardCodeEnum.HAIL:
      hazard = "grandine";
      break;
    case HazardCodeEnum.DROUGHT:
      hazard = "siccita";
      break;
  }
  return hazard;
};

export const hazardUrlTranslatorToCOde = (hazard: string) => {
  switch (hazard) {
    case "precipitazioni":
      hazard = HazardCodeEnum.RAIN;
      break;
    case "temperature-estreme":
      hazard = HazardCodeEnum.EXTREMET;
      break;
    case "raffiche":
      hazard = HazardCodeEnum.WIND;
      break;
    case "gelate":
      hazard = HazardCodeEnum.FROST;
      break;
    case "grandine":
      hazard = HazardCodeEnum.HAIL;
      break;
    case "siccita":
      hazard = HazardCodeEnum.DROUGHT;
      break;
  }
  return hazard;
};

export const sectorEnumToLabel = (sectorType: SectorTypeEnum | string) => {
  switch (sectorType) {
    case SectorTypeEnum.MILK:
      return "Lattiero casearia";
    case SectorTypeEnum.GRAPE:
      return "Vitivinicola";
    case SectorTypeEnum.FRUIT:
      return "Ortofrutticola";
    default:
      return "";
  }
};

export const organizationsIdToLabel = (
  id: string,
  organizations: OrganizationType[]
) => {
  const foundOrg =
    organizations &&
    organizations.length &&
    organizations.find((org: OrganizationType) => org.id === id);
  return (foundOrg && foundOrg.name) || id;
};

export const actionAreaToString = (number: number) => {
  switch (number) {
    case 1:
      return "Suolo";
    case 2:
      return "Acqua";
    case 3:
      return "Gestione agronomica";
    case 4:
      return "Varietà e sistemi colturali";
    case 5:
      return "Benessere animale";
    case 6:
      return "Trasformazione";
  }
};

export const getYhFromDateStr = (dateStrIn: string) => {
  if (!dateStrIn) {
    return;
  }
  const yyyyArr = dateStrIn.split("-");

  return yyyyArr && yyyyArr.length > 1 ? yyyyArr[1] : "";
};

export const getMmYhFromDate = (dateIn: Date) => {
  if (!dateIn) {
    return;
  }
  const yyyy = dateIn.getFullYear();
  let mm: number | string = dateIn.getMonth() + 1; // Months start at 0!
  if (mm < 10) {
    mm = "0" + mm;
  }

  return mm + "-" + yyyy;
};

export const getE3ciSubData = (currentE3ci: E3ciItemType, dateIn: Date) => {
  const N_VAL = 80;
  const GRAPH_N_VAL = N_VAL / 2;

  let dataArr = (currentE3ci && currentE3ci.data) || [];

  if (dataArr.length < 10) {
    return dataArr;
  }

  const xEl = getMmYhFromDate(dateIn);
  const _index = dataArr.map((dEl) => dEl.x).indexOf(xEl);

  if (_index < 0) {
    return dataArr;
  }

  const prevArr = dataArr.slice(_index - GRAPH_N_VAL, _index);
  const nextArr = dataArr.slice(_index, _index + GRAPH_N_VAL);

  return [...prevArr, ...nextArr];
};

export enum RetrivePswdStep {
  REQUEST,
  INSERT_CODE,
  INSERT_NEW_PSWD,
  FINAL_STEP,
}

export const isSolutionInPlan = (solution?: HazardSolutionTableItemType, actions?: HazardSolutionTableItemType[]): boolean => {
  if (!solution || !actions || !actions.length) {
    return false;
  }

  const foundSolution = actions.find(act => act._id === solution._id)
  return !!foundSolution
}

export const getCurrSolutionFromRisks = (risks: RiskItemType[], code?: string | null, _id?: string | null): HazardSolutionTableItemType | undefined => {
  if (!risks?.length || !code || !_id) {
    return;
  }

  let retSolution: HazardSolutionTableItemType | undefined;
  risks.forEach((r: RiskItemType) => {
    if (!r?.solutions?.length) {
      return
    }
    r.solutions.forEach((s: HazardSolutionItemType) => {
      if (!s?.items?.length || s.code !== code) {
        return
      }
      s.items.forEach((item: HazardSolutionTableItemType) => {
        if (item._id === _id) {
          retSolution = item;
        }
      })
    })
  })

  return retSolution
}

export const isOrganization = (userData: UserDataType) => {
  return !!(userData?.userType === UserTypeEnum.ORGANIZATION)
}

export const infoText = "Considerando l’analisi stagionale del periodo 1971-2020 e l’analisi predittiva del periodo 2021-2050.";

export const getSubtext = (value: string) => {
  let subText = "";
  switch (value) {
    case HazardCodeEnum.DROUGHT:
      subText = "Considerando l’analisi stagionale del trentennio 1991-2020.";
      break;
    case HazardCodeEnum.FROST:
      subText = "Considerando l’analisi del ventennio 2001-2020.";
      break;
    case HazardCodeEnum.WIND:
      subText = "Considerando l’analisi storica del periodo 1991-2020.";
      break;
    case HazardCodeEnum.HAIL:
      subText = "Considerando l’analisi stagionale del periodo 2015-2020.";
      break;
  }
  return subText;
};

export const hasUserAcceptedCookies = () => {
  const localStorageSettings = localStorage.getItem('cookieLaw');
  const areCookiesAccepted = localStorageSettings && JSON.parse(localStorageSettings).accepted
  return !!areCookiesAccepted
}

export const statusName: Record<string, string> = {
  ToRenew: "Da rinnovare",
  AlreadyDone: "In campo",
  ToSchedule: "Da programmare"
}