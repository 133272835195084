import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./WhiteHeader.scss";

interface WhiteHeaderProps {
  goBackCb: Function | null;
  title: string;
  closeBtn?: boolean;
}

const WhiteHeader = (props: WhiteHeaderProps) => {
  const { goBackCb, title, closeBtn } = props;

  const goBackHandler = () => {
    goBackCb && goBackCb();
  };

  const canBeBigClass = title && title.length > 50 ? "can-be-big" : "";
  return (
    <>
      <div className={`header-cmp white-header ${closeBtn && "closeBtn"}`}>
        {!closeBtn && (
          <button
            className="ada-btn white-btn hd-back-btn"
            onClick={goBackHandler}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
        )}
        <p className={`hd-title ${canBeBigClass}`}>{title}</p>
        {closeBtn && (
          <button
            className="ada-btn white-btn hd-back-btn"
            onClick={goBackHandler}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        )}
      </div>
    </>
  );
};

export default WhiteHeader;
