import { useState } from "react";
import ModalSlide from "../../UI/ModalSlide/ModalSlide";
import { RetrivePswdStep } from "../../utils/utils";
import PswdStepInsertEmail from "./PswdStepInsertEmail";
import PswdStepInsertCode from "./PswdStepInsertCode";
import PswdStepInsertNewPswd from "./PswdStepInsertNewPswd";
import "../../pages/Auth.scss";
import PswdStepFinal from "./PswdStepFinal";
import { UserTypeEnum } from "../../types/commonTypes";

interface RetrivePswdModalProps {
  toggleRetrivePswdModalOpened: Function;
  userType: UserTypeEnum;
}

function RetrivePswdModal(props: RetrivePswdModalProps) {
  const { toggleRetrivePswdModalOpened, userType } = props;
  const [currestStep, setCurrestStep] = useState<RetrivePswdStep>(
    RetrivePswdStep.REQUEST
  );
  const [email, setEmail] = useState("");
  const [retrivePswdCode, setRetrivePswdCode] = useState("");
  const [openResendMailModal, setOpenResendMailModal] = useState(false);
  const [isOtpInvalid, setIsOtpInvalid] = useState(false);

  const getTitle = () => {
    if (currestStep === RetrivePswdStep.REQUEST) return "Recupero password";
    if (currestStep === RetrivePswdStep.INSERT_CODE)
      return "Inserisci codice conferma";
    if (currestStep === RetrivePswdStep.INSERT_NEW_PSWD)
      return "Inserisci nuova password";
  };

  return (
    <>
      {openResendMailModal && (
        <ModalSlide
          shouldBeStandalone={true}
          title=""
          modalClass="resend-mail-modal"
          closeModal={() => setOpenResendMailModal(false)}
          cancelModal={() => setOpenResendMailModal(false)}
        >
          <PswdStepFinal closeModal={() => setOpenResendMailModal(false)} />
        </ModalSlide>
      )}
      <ModalSlide
        title={getTitle()}
        modalClass="retrive-pswd-modal"
        closeModal={() => toggleRetrivePswdModalOpened(false)}
        cancelModal={() => toggleRetrivePswdModalOpened(false)}
      >
        <>
          {currestStep === RetrivePswdStep.REQUEST && (
            <PswdStepInsertEmail
              setEmail={setEmail}
              userType={userType}
              setCurrestStep={setCurrestStep}
              closeModal={() => toggleRetrivePswdModalOpened(false)}
            />
          )}
          {currestStep === RetrivePswdStep.INSERT_CODE && (
            <PswdStepInsertCode
              email={email}
              retrivePswdCode={retrivePswdCode}
              setRetrivePswdCode={setRetrivePswdCode}
              setCurrestStep={setCurrestStep}
              openResendMailModal={setOpenResendMailModal}
              isOtpInvalid={isOtpInvalid}
              closeModal={() => toggleRetrivePswdModalOpened(false)}
              userType={userType}
            />
          )}
          {currestStep === RetrivePswdStep.INSERT_NEW_PSWD && (
            <PswdStepInsertNewPswd
              email={email}
              retrivePswdCode={retrivePswdCode}
              setCurrentStep={setCurrestStep}
              setIsOtpInvalid={setIsOtpInvalid}
              userType={userType}
            />
          )}
          {currestStep === RetrivePswdStep.FINAL_STEP && (
            <PswdStepFinal
              closeModal={() => toggleRetrivePswdModalOpened(false)}
            />
          )}
        </>
      </ModalSlide>
    </>
  );
}

export default RetrivePswdModal;
