import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { store } from "../../configureStore";
import { AuthAction } from "../../types/actions/auth-action";
import { AuthType } from "../../types/authType";
import { PersonalAreaItemTypeEnum } from "../../types/commonTypes";
import {
  API_ENDPOINT,
  getBackendData,
  getBackendErrorCode,
  hasBackendResponseErrors,
  sendGet,
  sendPost,
} from "../../utils/api";
import { getLocalStoragePersPlanItemToAdd } from "../../utils/utils.store";
import { addDeleteActionAct, saveAreaAct } from "../personalArea/actions";
import {
  activateUserErrorAct,
  loadOrganizationsErrorAct,
  loadOrganizationsSuccessAct,
  loginErrorAct,
  loginSuccessAct,
  registerOrgErrorAct,
  registerOrgSuccessAct,
  registerUserErrorAct,
  registerUserSuccessAct,
  resendUserRegisterEmailErrorAct,
  resendUserRegisterEmailSuccessAct,
} from "./actions";

export const getStateAuth = (state: any) => state.auth;

export function* registerUserCall(action: any) {
  try {
    const { registrationData: _body, history } = action.payload;
    let requestURL = `${API_ENDPOINT}/api/register-user`;
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res);
      yield put(registerUserErrorAct(errCode));
    } else {
      const registrationRes = getBackendData(res);
      yield put(registerUserSuccessAct(registrationRes));
      history.push("/resend-registration");
    }
  } catch (err) {
    yield put(registerUserErrorAct());
  }
}

export function* optionallyAddPersonalAreaItem() {
  // actions to be added?
  const { dispatch } = store;
  const actionIdArr = getLocalStoragePersPlanItemToAdd(PersonalAreaItemTypeEnum.ACTION)
  const actionId = actionIdArr?.length && actionIdArr[0]
  if (actionId) {
    dispatch(addDeleteActionAct({ isRemove: false, solutionId: actionId }))
  }

  // areas to be added?
  const areasArr = getLocalStoragePersPlanItemToAdd(PersonalAreaItemTypeEnum.AREA)
  const area = areasArr?.length && areasArr[0]

  if (area) {
    dispatch(saveAreaAct({ area }))
  }
}

export function* loginCall(action: any) {
  try {
    const { loginData: _body, history } = action.payload;
    let requestURL = `${API_ENDPOINT}/api/login`;
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res);
      yield put(loginErrorAct(errCode));
    } else {
      const loginRes = getBackendData(res);
      yield put(loginSuccessAct(loginRes));
      // Check if actions or area pending from LocStorage need to be added?
      yield call(optionallyAddPersonalAreaItem)

      history.push("/personal-area");
    }
  } catch (err) {
    yield put(loginErrorAct());
  }
}

export function* registerOrgCall(action: any) {
  const showToastError = () => {
    toast.error("Errore nell'invio della richiesta!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }
  try {
    let { registerData, history } = action.payload;
    delete registerData.conditionsChecked;
    let requestURL = `${API_ENDPOINT}/api/register-organization`;
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, registerData);

    if (hasBackendResponseErrors(res)) {
      showToastError();
      yield put(registerOrgErrorAct());
    } else {
      yield put(registerOrgSuccessAct());
      history.push('/registrati-org-conferma')
    }
  } catch (err) {
    showToastError();
    yield put(registerOrgErrorAct());
  }
}

export function* loadOrganizationsCall(action: any) {
  try {
    let requestURL = `${API_ENDPOINT}/api/organizations`;
    let res: AxiosResponse<any> = yield call(sendGet, requestURL);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res);
      yield put(loadOrganizationsErrorAct(errCode));
    } else {
      const resData = getBackendData(res);
      yield put(loadOrganizationsSuccessAct(resData));
    }
  } catch (err) {
    yield put(loadOrganizationsErrorAct());
  }
}

export function* resendUserRegisterEmailCall() {
  try {
    let stateAuth: AuthType = yield select(getStateAuth);
    const { registrationData } = stateAuth;

    let requestURL = `${API_ENDPOINT}/api/resend-registration-email?email=${registrationData?.email}`;
    let res: AxiosResponse<any> = yield call(sendGet, requestURL);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res);
      yield put(resendUserRegisterEmailErrorAct(errCode));
    } else {
      const resData = getBackendData(res);
      yield put(resendUserRegisterEmailSuccessAct(resData));
    }
  } catch (err) {
    yield put(resendUserRegisterEmailErrorAct());
  }
}

export function* activateUserCall(action: any) {
  try {
    const { activationData: _body, history } = action.payload;
    let requestURL = `${API_ENDPOINT}/api/activate`;
    let res: AxiosResponse<any> = yield call(sendPost, requestURL, _body);

    if (hasBackendResponseErrors(res)) {
      const errCode = getBackendErrorCode(res);
      yield put(activateUserErrorAct(errCode));
    } else {
      const registrationRes = getBackendData(res);
      toast.success("Utente attivato con successo, effettuare l'accesso.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      yield put(loginSuccessAct(registrationRes));
      history.push("/accedi");
    }
  } catch (err) {
    yield put(activateUserErrorAct());
  }
}

export function* watchResendUserRegisterEmailCall() {
  yield takeEvery(
    [AuthAction.RESEND_USER_REGISTER_EMAIL],
    resendUserRegisterEmailCall
  );
}

export function* watchRegisterUserCall() {
  yield takeEvery([AuthAction.REGISTER_USER], registerUserCall);
}

export function* watchLoginCall() {
  yield takeEvery([AuthAction.LOGIN], loginCall);
}

export function* watchRegisterOrgCall() {
  yield takeEvery([AuthAction.REGISTER_ORG], registerOrgCall);
}

export function* watchLoadOrganizationsCall() {
  yield takeEvery([AuthAction.LOAD_ORGANIZATIONS], loadOrganizationsCall);
}

export function* watchActivateUserCall() {
  yield takeEvery([AuthAction.ACTIVATE_USER], activateUserCall);
}

export function* authSaga() {
  yield all([
    fork(watchResendUserRegisterEmailCall),
    fork(watchRegisterUserCall),
    fork(watchLoginCall),
    fork(watchRegisterOrgCall),
    fork(watchLoadOrganizationsCall),
    fork(watchActivateUserCall),
  ]);
}

export default authSaga;
