import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Store } from "redux";
import { AnalyticsEvents, ApplicationState } from "./types/commonTypes";
import { Switch, Route } from "react-router-dom";
import { history } from "./configureStore";
import SettingsContextProvider from "./context/SettingsContextProvider";
import PersonalAreaPage from "./pages/PersonalArea";
import HomeLandingPage from "./pages/HomeLanding";
import AreaInputPage from "./pages/AreaInputPage";
import TermsAndConditions from "./pages/TermsAndConditions";
import RegionalDataPage from "./pages/RegionalDataPage";
import RegionalReportPage from "./pages/RegionalReportPage";
import AllRisksPage from "./pages/AllRisksPage";
import RiskItemPage from "./pages/RiskItemPage";
import SolutionDetailsPage from "./pages/SolutionDetailsPage";
import AoiHazardSolutionPage from "./pages/AoiHazardSolutionPage";

import RegionalSolutions from "./pages/RegionalSolutions";
import InfoSiccità from "./pages/InfoPages/InfoSiccità";
import InfoGelate from "./pages/InfoPages/InfoGelate";
import InfoVento from "./pages/InfoPages/InfoVento";
import InfoGrandine from "./pages/InfoPages/InfoGrandine";
import InfoExtremeT from "./pages/InfoPages/InfoExtremeT";
import InfoRain from "./pages/InfoPages/InfoRain";
import E3ciPage from "./pages/E3ciPage";
import { ToastContainer } from "react-toastify";
import RegistrationPage from "./pages/RegistrationPage";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { loadOrganizationsAct } from "./store/auth/actions";
import RegistrationEmailSent from "./pages/RegistrationEmailSent";
import ActivateUserPage from "./pages/ActivateUserPage";
import LoginPage from "./pages/LoginPage";
import SettingsPage from "./pages/SettingsPage";
import ChangePassword from "./pages/ChangePassword";
import ContattaAssistenza from "./pages/ContattaAssistenza";
import applyAxiosInterceptor from "./utils/http-interceptor-config";
import { isAuthenticated } from "./utils/api";
import { loadAreasAct, loadPlanActionsAct } from "./store/personalArea/actions";
import { Mixpanel } from "./Mixpanel";
import { selectAuth } from "./store";
import OrgRegistrationPage from "./pages/OrgRegistrationPage";
import OrgRegistrationMailSent from "./pages/OrgRegistrationMailSent";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import FeedbackCmp from "./components/Feedback/FeedbackCmp";
declare global {
  interface Window {
    FRONTEND_ROUTING_BASEPATH: string;
    BACKEND_BASEURL: string;
  }
}

interface MainProps {
  store: Store<ApplicationState>;
}

const AppCmpWithStore = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuth);

  useEffect(() => {
    let baseMixpanelData: any = {
      env: process.env.REACT_APP_ENV,
    }
    const authData = authState?.userAuthData
    if (isAuthenticated()) {
      // companyName and email hashed to be added
      baseMixpanelData = {
        ...baseMixpanelData,
        userType: authData?.userType,
        sectors: authData?.sectors
      }
    }
    Mixpanel.register(baseMixpanelData)
    Mixpanel.track(AnalyticsEvents.USER_LANDED);
  }, [])

  useEffect(() => {
    dispatch(loadOrganizationsAct());
    if (isAuthenticated()) {
      // Load areas list
      dispatch(loadAreasAct())
      // Load plan actions
      dispatch(loadPlanActionsAct())
    }
  }, []);

  return (
    <div className="app">
      <FeedbackCmp />
      <div className="route-wrapper">
        <>
          <Switch>
            <Route path="/terms-conditions" component={TermsAndConditions} />
            <Route path="/personal-area" component={PersonalAreaPage} />
            <Route path="/activate" component={ActivateUserPage} />
            <Route path="/area-input-page" component={AreaInputPage} />
            <Route path="/regional-data-page" component={RegionalDataPage} />
            <Route path="/regional-report" component={RegionalReportPage} />
            <Route path="/all-risks" component={AllRisksPage} />
            <Route path="/risk-item" component={RiskItemPage} />
            <Route path="/solution-details" component={SolutionDetailsPage} />
            <Route
              path="/regional-solutions"
              component={RegionalSolutions}
              exact
            />
            <Route
              path="/regional-solutions/:hazardCode"
              component={RegionalSolutions}
            />
            <Route
              path="/aoi-hazard-solution/:readOnly"
              component={AoiHazardSolutionPage}
            />
            <Route
              path="/aoi-hazard-solution"
              component={AoiHazardSolutionPage}
            />
            <Route path="/info-siccita" component={InfoSiccità} />
            <Route path="/info-gelate" component={InfoGelate} />
            <Route path="/info-raffiche" component={InfoVento} />
            <Route path="/info-grandine" component={InfoGrandine} />
            <Route path="/info-temperature-estreme" component={InfoExtremeT} />
            <Route path="/info-precipitazioni" component={InfoRain} />
            <Route path="/e3ci" component={E3ciPage} />
            <Route path="/registrati" component={RegistrationPage} />
            <Route path="/registrati-org" component={OrgRegistrationPage} />
            <Route path="/registrati-org-conferma" component={OrgRegistrationMailSent} />
            <Route path="/accedi" component={LoginPage} />
            <Route
              path="/resend-registration"
              component={RegistrationEmailSent}
            />
            <Route path="/impostazioni" component={SettingsPage} />
            <Route path="/cambio-password" component={ChangePassword} />
            <Route path="/contatta-assistenza" component={ContattaAssistenza} />
            <Route path="/" component={HomeLandingPage} />
          </Switch>
        </>
      </div>
      <CookieBanner />
    </div>
  );
};

const App: React.FC<MainProps> = ({ store }) => {
  applyAxiosInterceptor();

  return (
    <>
      <SettingsContextProvider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppCmpWithStore />
          </ConnectedRouter>
        </Provider>
      </SettingsContextProvider>

      <ToastContainer />
    </>
  );
};

export default App;
